.Proposals {
  &-List {
    .ColumnDirectionWithGap(16px);
    padding-top: 32px;
    min-height: 60vh;
  }

  &-Empty {
    margin-top: 128px;
    text-align: center;

    p {
      .H3();
    }
  }

  &-Cards {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &-Card {
    .ColumnDirectionWithGap(16px);
    width: 336px;
    min-height: 230px;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid @dark100;

    h3 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .Card {
      &-Top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        border-bottom: 2px solid @dark60;
      }

      &-Tally {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 20px;

        &-Item {
          display: flex;
          align-items: center;
          gap: 4px;

          &:before {
            font-family: "icon-font";
            font-size: 18px;
          }
        }

        &-YES {
          color: @green80;

          &:before {
            content: "\e911";
          }
        }

        &-NO {
          color: @red80;

          &:before {
            content: "\e910";
          }
        }

        &-ABSTAIN {
          color: @dark80;

          &:before {
            content: "\e913";
          }
        }
      }
    }

    h4 {
      .Bold();
      color: @dark80;
    }

    button {
      align-self: flex-start;
    }

    &.Closed {
      border: 1px solid @dark60;
      background-color: @dark30;
      cursor: pointer;
    }

    &:not(.Closed) {
      &:focus-visible,
      &:hover {
        box-shadow: 0px 0px 20px -12px @turquoise50;
        transform: scale(100.5%);
        outline: none;
        cursor: pointer;
        .Transition(all);
      }
    }
  }
}

.Proposal {
  &-New {
    min-height: 50vh;

    .ant-form {
      max-width: 600px;
    }
  }

  &-View {
    min-height: 50vh;
  }

  &-About {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 24px;
  }

  &-Info {
    .ColumnDirectionWithGap(16px);
    width: 600px;

    .StatusLabel,
    button {
      align-self: flex-start;
    }

    & > p {
      color: @dark80;
    }

    button {
      .Button-Secondary();
    }
  }

  &-DescriptionBlock {
    .ColumnDirectionWithGap(8px);

    p:first-child {
      .Bold();
    }
  }

  &-Voting {
    .ColumnDirectionWithGap(16px);
    width: 400px;
    padding: 32px 32px 56px;
    background-color: @dark80;
    border-radius: 16px;
    opacity: 0;
    transform: translateX(60px);
    animation: @durationLong appearance @easeOutBack forwards;

    h4,
    label {
      color: @white100;
    }

    h4 {
      .Bold();
    }

    && .ant-radio-inner {
      border-color: @white100;
    }

    textarea.ant-input {
      border-color: @dark90;
      background-color: @dark90;
      color: @white100;
    }

    .ant-input-textarea-show-count::after {
      color: @white100;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      background-color: @dark90;
    }
  }

  &-VoteResultBlock {
    .DottedDivider(top);
    .ColumnDirectionWithGap(16px);
    padding-top: 16px;

    .ant-btn {
      .Button-Text();
      align-self: flex-end;
    }

    .Results {
      display: flex;
      align-items: flex-start;
      gap: 48px;

      .YES {
        color: @green80;
      }

      .NO {
        color: @red80;
      }

      .ABSTAIN {
        color: @dark80;
      }

      .Votes {
        .ColumnDirectionWithGap(16px);
        flex-direction: column-reverse;
        opacity: 0;
        transform: translateX(-60px);
        animation: @durationLong appearance @easeOutBack forwards;

        .Tally-Item {
          .ColumnDirectionWithGap(2px);
          align-items: center;
          padding: 8px 10px;
          width: 98px;
          height: 64px;
          border-radius: 16px;
          border: 1px solid @dark60;

          &[role="button"] {
            cursor: pointer;

            &:hover,
            &:focus {
              box-shadow: 0px 0px 20px -12px @turquoise50;
              transform: scale(101%);
              .Transition(all);
            }
          }

          &-Active {
            background-color: @turquoise90;
            border-color: @turquoise90;
            color: @white100;

            .YES,
            .NO,
            .ABSTAIN {
              color: @white100;
            }
          }
        }

        &-Item {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          gap: 4px;

          &:before {
            font-family: "icon-font";
            font-size: 18px;
          }
        }

        .YES:before {
          content: "\e911";
        }

        .NO:before {
          content: "\e910";
        }

        .ABSTAIN:before {
          content: "\e913";
        }
      }

      .VotesDetails {
        .ColumnDirectionWithGap(16px);
        flex-direction: column-reverse;
        width: 100%;

        .Vote-Comment {
          display: flex;
          align-items: center;
          gap: 64px;
          padding: 24px;
          border-radius: 16px;
          background-color: @dark40;
          opacity: 0;
          transform: translateY(60px);
          animation: @durationLong appearance @easeOutBack forwards;

          & > div:first-child {
            display: flex;
            align-items: center;
            gap: 32px;
          }

          .Vote-Avatar {
            display: flex;

            .icon-user {
              font-size: 36px;
            }

            &.YES {
              .path2:before,
              .path3:before {
                color: @green80;
              }
            }

            &.NO {
              .path2:before,
              .path3:before {
                color: @red80;
              }
            }

            &.ABSTAIN {
              .path2:before,
              .path3:before {
                color: @dark80;
              }
            }
          }

          .Vote-Info {
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.Vote-Modal {
  .ModalWrapper();

  h4 {
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 2px solid @green40;
    text-align: center;
    color: @dark80;
  }

  .Vote-Info {
    width: 100%;
    align-self: start;

    p {
      margin-bottom: 4px;
      text-align: left;
    }

    .Vote {
      width: 100%;
      margin-bottom: 16px;
      padding: 4px 16px;
      border-radius: 16px;
      background-color: @dark40;
    }
  }
}
