.ConnectWalletPage {
  .ColumnDirectionWithGap(24px);
  align-items: center;
  margin: auto;

  p,
  h3,
  label {
    color: @white100;
  }

  .Signin-Stepper {
    position: absolute;
    top: 16vh;
    width: 400px;
    opacity: 0;
    transform: translateY(30px);
    animation: @durationLong appearance @durationLong @easeOutBack forwards;

    &.ant-steps {
      .ant-steps-item-container {
        .ant-steps-item-content {
          min-height: 32px;
        }

        .ant-steps-item-title {
          color: @white100;
        }
      }

      .ant-steps-item-active {
        .ant-steps-icon-dot {
          background-color: @green40;
        }
        .ant-steps-item-title {
          color: @green40;
        }
      }

      .ant-steps-item-wait .ant-steps-icon-dot {
        width: 10px;
        height: 10px;
        background-color: @white100;
      }
    }
  }

  .Connectors,
  .Login {
    .ColumnDirectionWithGap(40px);
    position: absolute;
    top: 24vh;
    width: 360px;
    padding: 48px 32px 56px;
    border-radius: 32px;
    background-color: @white20;
    transform: perspective(550px) rotateY(90deg);
    animation: @durationLong rotation 0.5s @easeOutBack forwards;
  }

  .Connectors {
    p {
      color: @dark60;
    }

    .Buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      p {
        width: 100%;
        margin-bottom: 16px;
        text-align: center;
        color: @dark50;
      }

      button {
        width: 47%;
      }
    }

    label {
      color: @dark50;
      text-align: center;
    }

    .wallet {
      p {
        color: @dark50;
        margin-bottom: 16px;
        text-align: center;
      }
    }

    button {
      width: 100%;

      .ant-image {
        display: flex;
        width: 18px;
      }
    }

    & > button:last-child,
    .Buttons button:last-child {
      .Button();
      .DarkBackground();
    }

    .ant-form {
      .ant-form-item {
        margin-bottom: 16px;

        .ant-form-item-explain-error {
          margin-bottom: 4px;
        }

        input.ant-input {
          border-color: @dark90;
          background-color: @dark90;
          color: @white100;

          &:focus,
          &:hover {
            border-color: @turquoise50;
          }
        }
      }
    }
  }

  .SwitchNetwork {
    .ColumnDirectionWithGap(16px);
    align-items: center;
    margin-top: 32px;
    opacity: 0;
    animation: @durationLong appearance 1s @easeOutBack forwards;
  }
}
