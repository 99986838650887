.FastsDropdown {
  &-Button {
    .Button-Transparent();
    height: 24px;
    gap: 12px;

    .icon-arrow-down {
      .TransitionInOutBack(transform);
    }

    .ant-image,
    span,
    p {
      transition: all @durationLong ease-in-out;
    }

    &:focus-visible,
    &:hover,
    &:active {
      .ant-image {
        filter: drop-shadow(0px 1px 6px @white100);
      }

      span {
        transform: translateX(3px);
      }

      p {
        transform: translateX(1px);
      }

      .icon-arrow-down:before {
        color: @white100;
      }
    }

    &.ant-popover-open {
      .icon-arrow-down {
        transform: rotate(180deg);
      }
    }
  }

  &-Popover {
    position: fixed;
    padding-top: 10px;

    .ant-popover-inner {
      padding: 0;
      border: none;
      border-radius: 16px;
    }

    .Content {
      display: flex;
      flex-direction: column;
      min-width: 416px;

      .Option,
      .DefaultOption {
        display: flex;
        padding: 8px 32px 8px 24px;
        font-weight: normal;
        color: @dark100;

        &:hover {
          background-color: @dark30;
        }

        &:first-of-type {
          padding-top: 24px;
          border-radius: 16px 16px 0 0;
        }

        &:last-of-type,
        &:nth-last-of-type(2) {
          padding-bottom: 16px;
        }

        &.Active {
          background-color: @dark40;
        }

        strong {
          width: 42px;
        }
      }

      .Divider {
        .DottedDivider(top);
        height: 1px;
        margin: 0 24px;
      }

      .DefaultOption {
        padding-top: 16px;
        border-radius: 0 0 16px 16px;

        strong {
          width: auto;
          margin-right: 8px;
        }
      }
    }
  }

  &-Option,
  .TokenSphereOption {
    display: flex;
    gap: 12px;
    color: @white100;
  }
}

.TokenSphereOption {
  align-items: center;
  height: 24px;
  user-select: none;

  .ant-image {
    height: 20px;

    img {
      vertical-align: baseline;
    }
  }

  p {
    color: @white100;
  }
}
