.FilterButton {
  &,
  &:focus {
    background-color: @dark40;
    border-color: @dark40;
  }

  &.withMarginRight {
    margin-right: 12px;
  }

  &:focus-visible,
  &:hover {
    background-color: @dark40;
    border-color: @turquoise100;
  }

  & > span {
    .P();
    color: @dark100;
  }

  &.active {
    background-color: @turquoise20;
    border-color: @turquoise100;

    & .ant-image-img {
      width: 22px;
      height: 18px;
      margin-right: 8px;
    }

    & > span {
      font-weight: 700;
      color: @turquoise100;
    }
  }
}
