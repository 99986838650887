.IssuerDashboard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .PageContent {
    margin-top: 18px;
    padding: 0 120px 64px;
  }

  .IssuerHeader {
    &-Top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-Divider {
      margin: 8px 0 32px;
      border: 2px solid @green40;
    }
  }

  .IssuerTabsNavigator {
    &-Link {
      .H2();
      color: @dark60;
      .Transition(color);

      &:hover,
      &:focus-visible {
        color: @grey40;
      }

      &.active {
        color: @white100;
      }
    }
  }

  .Applications {
    .CenteredSpinner();

    .ant-table-thead > tr > th:last-child,
    .ant-table-tbody > tr > td:last-child:not(:has(.ant-empty)) {
      padding-right: 0;
      text-align: end;
    }

    .OnfidoColumn {
      display: flex;
      align-items: center;
      gap: 8px;

      .ant-image {
        display: flex;
      }
    }

    .StatusColumn {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &-SubmenuButton {
        .Button-Secondary();
        position: relative;
        width: 36px;

        span {
          position: absolute;
          top: 8px;
          left: 15px;
          letter-spacing: 1px;
          transform: rotate(90deg);
        }
      }
    }
  }
}

.StatusColumn-Submenu {
  .ant-popover-content,
  .ant-popover-inner {
    border-radius: 16px;
  }

  .ant-popover-inner {
    padding: 0;
    border: 1px solid @dark60;
  }

  .ant-popover-inner-content {
    padding: 8px 0;

    button {
      width: 100%;
      min-width: 180px;
      justify-content: flex-start;
      border: none;
      border-radius: 0px;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        background-color: @turquoise30;
      }

      &:focus-visible {
        background-color: @turquoise20;
      }

      &:active {
        background-color: @turquoise50;
      }
    }
  }
}
