.MyWallet {
  &-Transfer {
    width: 100%;

    & label {
      font-weight: 500;
    }

    && .ant-form-item {
      margin-bottom: 8px;
      .AddressResolverWithLabel();
    }

    .ant-row {
      margin-bottom: 8px;
    }
  }
}
