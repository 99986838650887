.FastAbout {
  background-color: @white100;
  border-radius: 16px;

  &-Top {
    background: @dark40;
    padding: 24px 40px;
    border-radius: 16px 16px 0 0;
  }

  &-ElaborateInfo {
    display: flex;
    align-items: center;
    gap: 8px;

    i {
      color: @blue50;
    }
  }

  &-WarnIcon {
    color: @white100;
    background: @blue50;
    padding: 2px;
    border-radius: 100%;
  }

  &-Border-Solid {
    border-top: 2px solid @green40;
    margin: 0;
    margin: 16px 0 24px 0;
  }

  &-Logo {
    border-radius: 10px;
    width: 150px;
    height: 150px;
  }

  &-Info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-Name {
      .ColumnDirectionWithGap(8px);
    }

    .ant-btn {
      .Button-Secondary();
    }
  }

  &-Index {
    display: flex;
    align-items: center;
    gap: 30px;

    h2 {
      color: @dark80;
    }
  }

  &-Middle {
    padding: 24px 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
  }

  &-Left {
    .ColumnDirectionWithGap(24px);
  }

  &-DataBoxes {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-Tags {
    display: flex;
    gap: 24px;
  }

  &-ColumDiection,
  &-TagsWrapper {
    .ColumnDirectionWithGap(4px);
  }

  &-TagsWrapper {
    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    p {
      padding: 5px 10px;
      border-radius: 25px;
      background-color: @dark40;
    }
  }

  &-CommunityTag {
    width: fit-content;
    margin: 0;
    padding: 5px 12px;
    border-radius: 25px;
    background-color: @white100;
  }

  &-DocumentWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      .Button-Secondary();
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: 16px;
    }
  }

  &-Divider {
    padding: 0px 40px;

    hr {
      border-top: 2px solid @green40;
      width: 100%;
    }
  }

  &-TeamMembers {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  h4 {
    font-weight: 800;
  }

  &-Empty {
    padding: 30px 0;
  }

  &-Copy {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 7px 55px 7px 15px;
    border-radius: 24px;
    background: @turquoise20;

    i {
      color: @dark80;
    }

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    button {
      position: absolute;
      right: 15px;
    }
  }

  &-ShareModal {
    .ColumnDirectionWithGap(16px);
    align-items: flex-start;

    p {
      color: @dark80;
    }

    .ant-row {
      gap: 24px;
    }
  }

  &-InviteModal {
    .ColumnDirectionWithGap(24px);

    .FastPlaque {
      display: flex;
      gap: 16px;

      img {
        width: 64px;
        height: 64px;
        border-radius: 16px;
      }
    }
  }
}
