.Distributions {
  .ColumnDirectionWithGap(8px);

  table {
    .ant-table-cell:last-child .ant-btn {
      margin: 0 -16px 0 auto;
    }

    .icon-new-window::before {
      font-size: 14px;
      margin-left: 3%;
    }
  }

  &-Deploy {
    position: relative;
    overflow-x: clip;

    .ant-form {
      .ColumnDirectionWithGap(24px);

      .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-explain-error,
        .ant-form-item-extra {
          position: absolute;
        }
      }

      button {
        align-self: flex-start;
      }
    }

    input {
      width: 440px;
    }

    p {
      color: @dark80;
    }

    .SetToken-Button {
      .Button-Text();
      display: inline-flex;

      &[disabled] {
        color: @dark80;
        cursor: not-allowed;
      }
    }

    .ant-form-item {
      display: flex;
    }

    .TokenInfo {
      margin-top: 16px;
    }

    .Progress {
      margin-top: 24px;

      .ant-steps {
        position: absolute;
        top: 30px;
        right: 0;
        width: auto;
        opacity: 0;
        transform: translateX(200%);
        animation: 1s appearance ease forwards;

        .ant-steps-item-wait {
          .ant-steps-item-title {
            color: @dark60;
          }

          .ant-steps-icon-dot {
            background-color: @dark60;
          }
        }

        .ant-steps-item-finish {
          .ant-steps-item-tail:after,
          .ant-steps-icon-dot {
            background-color: @green50;
          }
        }
      }

      .ant-collapse {
        .ant-collapse-header {
          width: fit-content;

          .ant-collapse-arrow svg {
            .TransitionInOutBack(transform);
            width: 14px;
            height: 14px;
          }
        }

        .ant-collapse-content-box {
          padding: 4px 32px;
        }
      }

      pre {
        margin-top: 8px;
      }
    }
  }

  &-SubmitModal {
    ul {
      margin: 16px 0;
    }

    .ButtonPairWrapper {
      justify-content: center;
      margin-top: 24px;
    }
  }

  &-CurrentHolders {
    .TableWithTitle();
    margin-top: 48px;
  }

  &-Phase {
    position: relative;
    left: 24px;

    &:before {
      content: "";
      position: absolute;
      left: -24px;
      top: 3px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: @green50;
    }

    &-Requested {
      &:before {
        background-color: @yellow;
      }

      div {
        position: absolute;
        left: -24px;
        top: 18px;
        color: @orange50;
      }
    }

    &-Aborted {
      &:before {
        background-color: @red80;
      }
    }

    &-Distributed {
      &:before {
        background-color: @blue50;
      }
    }
  }

  &-Overview {
    display: flex;
    justify-content: space-between;
    margin-bottom: 56px;
    border-radius: 16px;
    border: 1px solid @dark60;
    overflow: hidden;

    .FirstColumn {
      display: flex;
    }

    .InfoColumn {
      padding: 28px 72px;

      .Distributions-Phase {
        margin-bottom: 16px;
      }

      .AddressDisplayer {
        margin: 28px 0 4px;
      }

      span {
        color: @dark60;
      }
    }

    .ActionColumn {
      padding: 66px 72px;

      div {
        display: flex;
      }

      button {
        margin-top: 28px;
      }
    }

    .FeeColumn {
      width: 40%;
      padding: 32px 72px;
      background-color: @dark80;

      .ant-form-item {
        label {
          color: @white100;
        }

        .ant-form-item-control-input {
          width: 172px;
          margin-bottom: 4px;
        }

        input {
          padding-right: 32px;
          border-color: @dark90;
          background-color: @dark90;
          color: @white100;
        }

        p {
          color: @dark50;
        }
      }
    }
  }

  &-Beneficiaries {
    .TableWithTitle();

    .StatusColumn {
      display: flex;
      align-items: center;
      gap: 8px;

      .Status {
        .CircleStatus();
      }
    }

    .ant-table-tbody > tr > td:last-child {
      padding-right: 0;

      button {
        margin-left: auto;
      }
    }
  }

  &-PersonBenefit {
    .ColumnDirectionWithGap(24px);

    .PersonBenefit {
      &-Cards {
        display: flex;
        gap: 24px;
      }

      &-Card {
        padding: 16px;
        border-radius: 8px;
        background-color: @dark40;

        .StatusTitle {
          display: flex;
          align-items: center;
          gap: 8px;

          .Status {
            .CircleStatus();
            border: none;
          }
        }
      }
    }

    button {
      align-self: start;
    }
  }
}
