.Table {
  && th {
    .P();
    border: none;
    border-bottom: none;
    background-color: @white100;
    color: @dark80;
    text-transform: uppercase;
  }

  th::before {
    height: 0 !important;
  }

  &-Row {
    && td {
      border-top: 1px dashed @dark100;
      border-bottom: none;
      background-color: @white100;
    }

    &:hover {
      td {
        background-color: transparent !important;
      }
    }
  }
  &-Remove-Button {
    .Button-Text();
    padding: 4px;
  }

  .AddressColumn,
  .UserColumn {
    display: flex;
    align-items: center;
  }

  .AddressColumn:empty::after {
    content: "-";
  }

  .UserColumn {
    align-items: center;
    gap: 8px;

    & > a:first-of-type {
      line-height: 16px;
    }

    &:empty::after {
      content: "N/A";
    }
  }

  .CollapsingColumnTitle {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      .Button-Text();
      .Transition(gap);
      width: 36px;
      gap: 0px;

      &:focus-visible,
      &:hover {
        gap: 4px;
      }

      i {
        .TransitionInOutBack(transform);
      }

      &.Collapsed {
        gap: 4px;

        &:focus-visible,
        &:hover {
          gap: 0px;
        }

        i {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.Table-Error {
  margin-top: 72px;
  color: @red50;
}

.AlternativePagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
    border-radius: 24px;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  button {
    .Button-Text();
    display: flex;
    align-items: center;

    &:not(:disabled) {
      &:focus-visible,
      &:hover {
        i {
          transform: translateX(4px);
        }

        &:first-of-type i {
          transform: translateX(-4px);
        }
      }
    }

    i {
      .Transition(transform);
      font-size: 22px;
    }
  }
}
