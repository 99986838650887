.FastTabsNavigator {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  padding-bottom: 16px;

  .MainNavigatorWrapper {
    position: sticky;
    top: 86px;
    z-index: 3;
  }

  .MainNavigator {
    .ColumnDirectionWithGap(40px);
    min-width: 166px;
    padding: 24px;
    border-radius: 10px;
    border: 2px solid @white20;
    background-color: @fastDark;

    .FastInfo {
      .ColumnDirectionWithGap(16px);
      align-items: flex-end;

      img {
        width: 47px;
        height: 47px;
        border-radius: 8px;
        border: 1px solid @dark60;
      }

      h2 {
        .Bold();
        font-size: 32px;
        line-height: 36px;
        color: @white100;
      }
    }

    nav {
      .ColumnDirectionWithGap(24px);
      align-items: flex-end;
    }
  }

  &-Link {
    .H4();

    &,
    &:focus {
      color: @turquoise30;
    }

    &:hover,
    &:focus-visible {
      color: @white100;
    }

    &:active {
      color: @dark100;
    }

    &.active {
      .Bold();
      color: @turquoise50;
    }
  }

  .ContentWrapper {
    position: relative;
    flex-grow: 1;

    &:before {
      content: "";
      position: sticky;
      width: 100%;
      height: 30px;
      top: 70px;
      left: 0;
      display: block;
      margin-top: -30px;
      background-color: @dark100;
      z-index: 1;
    }

    &:has(> .Proposals-List) {
      &:before {
        height: 16px;
        margin-top: -16px;
      }
    }

    &-Breadcrumbs {
      position: sticky;
      top: 110px;
      margin: -24px 0 0 40px;
      z-index: 3;
    }

    .SecondFastTabNavigator,
    .Content-Header {
      position: sticky;
      top: 86px;
      border-radius: 16px 16px 0 0;
      z-index: 2;

      .ant-breadcrumb {
        position: absolute;
        top: 24px;
      }
    }

    .SecondFastTabNavigator {
      height: 54px;
      display: flex;
      gap: 24px;
      padding: 16px 32px;
      border: 2px solid @white20;
      border-bottom: none;
      background-color: @fastDark;
      box-shadow: 0px 32px 8px -14px rgba(255, 255, 255, 0.7);
    }

    .Content {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background-color: @fastDark;
        border-left: 2px solid @white20;
        border-right: 2px solid @white20;
        z-index: -1;
      }

      &-Header {
        padding: 58px 40px 24px;
        background-color: @dark40;
      }

      &-Body {
        padding: 40px 48px;
        border-radius: 0 0 16px 16px;
        background-color: @white100;
      }
    }
  }
}
