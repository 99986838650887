.FastEdit {
  .ColumnDirectionWithGap(16px);
  padding: 40px 40px 44px 27px;
  border-radius: 16px;
  background-color: @white100;

  &-TitleBlock {
    display: flex;
    justify-content: space-between;
    margin: 0 70px 0 13px;
  }

  .rjsf {
    position: relative;
  }

  &-SubmitButton {
    position: absolute;
    top: -56px;
    right: 0;
  }

  #root > .form-group {
    margin-bottom: 8px;
    padding: 32px 8px 40px 40px;
    border-radius: 16px;
    background: @dark40;

    & > label {
      .H2();
    }
  }

  .array-item-move-up,
  .array-item-move-down {
    display: none;
  }

  legend {
    .H2();
    margin-bottom: 8px;
  }

  p {
    .P();
  }

  .field-description {
    margin-bottom: 24px;
  }

  &-Logo {
    span {
      display: flex;
      align-items: center;
      gap: 32px;
      width: fit-content;

      label {
        .Link();
      }
    }

    .ant-image-mask {
      border-radius: 10px;
    }
  }

  &-Radio {
    & > label {
      display: block;
      margin-bottom: 8px;
    }

    .field-radio-group {
      display: flex;
      gap: 24px;
      margin-top: 24px;
    }
  }

  .form-group {
    .FieldsetInput();
    position: relative;

    input,
    select,
    textarea {
      background-color: @white100;
      border-color: @white100;
      outline: none;
    }

    textarea {
      border-radius: 16px;
    }
  }

  &-Select,
  &-MultipleSelect {
    position: relative;
    width: 100%;

    && .ant-select-selector {
      padding: 4px 16px;
      border: 1px solid @white100;
      border-radius: 24px;

      &:focus,
      &:hover {
        border-color: @turquoise50;
      }

      .ant-select-selection-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-right: 8px;
        padding: 8px 12px;
        border-radius: 24px;
        background-color: @dark40;
      }

      .ant-select-selection-item-remove {
        position: relative;
        font-size: 14px;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: -5px;
          width: 1px;
          height: 16px;
          border: 1px solid @dark80;
        }
      }

      input {
        min-height: 24px;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  &-Select {
    && .ant-select-selector {
      height: 38px;

      .ant-select-selection-item {
        background: none;
      }

      .ant-select-selection-placeholder {
        line-height: 24px;
      }
    }
  }

  &-Textarea {
    position: relative;

    &.ant-input-textarea-show-count {
      &::after {
        position: absolute;
        right: 16px;
        bottom: 8px;
      }
    }
  }

  &-Documents,
  &-Links,
  &-TeamMembers {
    .array-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 32px;

      & > div:first-child {
        width: 100%;
      }

      fieldset {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        margin-right: 48px;

        .form-group {
          width: 100%;
        }

        .form-group:first-child {
          width: 60%;

          span {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: flex-end;
            gap: 24px;

            label {
              .Link();
              margin-bottom: 0;
            }
          }
        }
      }

      .control-label {
        display: none;
      }

      .error-detail {
        position: absolute;
        top: 100%;
        left: 10px;
        margin: 0;
        padding: 0;
      }

      &:first-child fieldset .form-group:first-child span:not(.anticon) {
        margin-top: 24px;
      }
    }

    .array-item:first-child {
      .control-label {
        display: block;
        text-transform: uppercase;
        color: @dark80;
      }
    }
  }

  &-Links {
    .array-item {
      fieldset {
        .form-group {
          width: 60%;

          &:first-child {
            width: 100%;

            span {
              display: inline;
            }
          }
        }
      }
    }
  }

  &-TeamMembers {
    .array-item {
      fieldset {
        .form-group:first-child {
          span {
            flex-direction: row;
            align-items: center;

            .ant-image-img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
            }

            .ant-image-mask {
              border-radius: 50%;

              .anticon {
                margin-inline-end: 0;
              }
            }
          }
        }
      }
    }
  }

  .array-item-add,
  &-RemoveButton,
  &-AddButton {
    border: none;
    background: none;

    &:focus,
    &:hover,
    &:active {
      border: none;
      background: none;
    }
  }

  &-RemoveButton,
  &-AddButton {
    .Link();
  }

  &-AddButton {
    margin-top: 24px;
    padding: 0;
  }

  .ant-image-mask-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
