.InviteToPlatform-Modal {
  & > .ant-modal-content > .ant-modal-body {
    .ColumnDirectionWithGap(24px);
    padding: 40px;

    h1 {
      text-transform: none;

      span {
        .RedText();
      }
    }
  }
}
