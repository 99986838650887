body {
  margin: 0;
  box-sizing: border-box;
  font-family: Lato, sans-serif;
  font-style: normal;
  background-color: @dark100;

  & > #root {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    scroll-behavior: smooth;
  }

  button {
    .Button();
  }

  a {
    .Link();
  }
}
