// Typography
h1,
.H1 {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
}

h2,
.H2 {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

h3,
.H3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

h4,
.H4 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  &.Bold {
    font-weight: 700;
  }
}

p,
.P {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  &.Bold {
    font-weight: 700;
  }
}

.P-Small {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  &.Bold {
    font-weight: 700;
  }
}

p[title] {
  cursor: help;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
  color: @dark100;
}

.Bold {
  font-weight: 700;
}

.Opaque {
  opacity: 0.7;
}

.Transition(@property) {
  transition: @property @durationShort cubic-bezier(0, 0, 0.2, 1);
}

.TransitionInOutBack(@property) {
  transition: @property @durationLong @easeInOutBack;
}

// Animations.
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes appearance {
  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes rotation {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: perspective(550px) rotateY(0deg);
  }
}

// Buttons
.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  .Transition(all);

  &,
  &:focus {
    border: 1px solid @turquoise50;
    background-color: @turquoise50;
    color: @dark100;
  }

  &:focus-visible,
  &:hover {
    border-color: @turquoise100;
    background-color: @turquoise100;
    color: @white100;
  }

  &:active {
    border-color: @dark100;
    background-color: @dark100;
    color: @white100;
  }

  &[disabled] {
    border: none;
    background-color: @dark50;
    color: @dark30;
    cursor: not-allowed;

    &:hover,
    &:active {
      border-color: @dark50;
      background-color: @dark50;
      color: @dark30;
    }
  }

  span {
    .P();
    .Bold();
  }

  &.DarkBackground {
    &,
    &:focus {
      background-color: @white100;
      border-color: @white100;
      color: @dark100;
    }

    &:focus-visible,
    &:hover {
      background-color: @turquoise100;
      border-color: @turquoise100;
      color: @white100;
    }

    &:active {
      background-color: @dark100;
      border-color: @white100;
      color: @white100;
    }
  }
}

.Button-Secondary {
  .Button();
  .Transition(all);

  &,
  &:focus {
    border-color: @turquoise90;
    background-color: @white100;
    color: @turquoise90;
  }

  &:focus-visible,
  &:hover {
    border-color: @dark100;
    background-color: @white100;
    color: @dark100;
  }

  &:active {
    border-color: @dark100;
    background-color: @dark100;
    color: @white100;
  }

  &.Transparent {
    background-color: transparent;

    &:hover,
    &:focus-visible,
    &:active {
      background-color: transparent;
    }
  }

  &[disabled] {
    border-color: @dark60;
    background-color: @white100;
    color: @dark60;
    &:focus-visible,
    &:hover,
    &:active {
      border-color: @dark60;
      background-color: @white100;
      color: @dark60;
    }
  }
}

.Button-Header {
  .Button();
  .Transition(all);

  &,
  &:focus {
    border-color: @turquoise50;
    background-color: transparent;
    color: @turquoise50;
  }

  &:focus-visible,
  &:hover {
    border-color: @turquoise30;
    background-color: transparent;
    color: @turquoise30;
  }

  &:active {
    border-color: @white100;
    background-color: @dark100;
    color: @white100;
  }
}

.Button-Transparent {
  &,
  &:focus {
    padding: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }

  &[disabled],
  &:hover,
  &:active,
  &:focus-visible {
    border: none;
    background-color: transparent;
    &[disabled] {
      background-color: transparent;
    }
  }
}

.Button-Text {
  .Button-Transparent();
  .Link();
  height: unset;

  &[disabled] {
    color: @dark60;
    cursor: auto;
  }
}

.Link {
  .P();
  .Bold();
  cursor: pointer;
  .Transition(color);

  &,
  &:focus {
    color: @turquoise90;
  }

  &:hover,
  &:focus-visible {
    color: @turquoise100;
  }

  &:active {
    color: @dark100;
  }
}

.FieldsetInput {
  label {
    display: block;
    margin-bottom: 8px;
  }
  input,
  select,
  textarea {
    width: 100%;
    min-height: 38px;
    padding: 7px 13px;
    border: 1px solid @turquoise20;
    border-radius: 24px;
    appearance: none;
    background-color: @turquoise20;
    caret-color: @turquoise90;
    .Transition(all);

    &:focus,
    &:hover {
      &:not(:disabled) {
        border-color: @turquoise50;
      }
    }

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      outline: none;
    }

    &[type="number"] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &:-internal-autofill-selected,
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active {
      background-color: @turquoise20 !important;
    }

    &:-webkit-autofill {
      background-color: @turquoise20;
    }
  }

  textarea {
    padding: 8px 16px;
    border-radius: 16px;
    resize: none;
  }
}

input[type="radio"] {
  display: none;

  &:checked {
    & + span {
      font-weight: 700;

      &:after {
        content: "";
        position: absolute;
        left: 5px;
        bottom: 6px;
        width: 14px;
        height: 14px;
        background-color: @turquoise50;
        border-radius: 50%;
      }

      &:before {
        border-color: @turquoise50;
      }
    }
  }

  & + span {
    display: inline-block;
    position: relative;
    padding-left: 32px;
    user-select: none;
    cursor: pointer;
    .Transition(color);

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 1px;
      width: 24px;
      height: 24px;
      border: 1px solid @dark100;
      border-radius: 50%;
      .Transition(border-color);
    }

    &:focus-visible,
    &:hover {
      color: @turquoise50;

      &:before {
        border-color: @turquoise50;
      }
    }
  }
}

.ErrorMessage {
  font-size: 12px;
  color: @red50;
}

.ProgressText {
  display: flex;
  gap: 4px;

  .ant-image {
    width: 24px;
  }
}

// Common wrapper class for "Cancel" and "Action" buttons.
.ButtonPairWrapper {
  display: flex;
  gap: 8px;

  button:first-child {
    .Button-Secondary();
  }
}

// Common wrapper class for "Cancel-Action" modal.
.ModalWrapper {
  .ant-modal-body,
  .ant-spin-container {
    .ColumnDirectionWithGap(16px);
    align-items: center;

    h2,
    p {
      text-align: center;
    }
  }
}

.GreenText {
  color: @green80;
}

.RedText {
  color: @red80;
}

.GreyText {
  color: @dark80;
}

.OrangeText {
  color: @orange50;
}

.DottedDivider(@position) {
  background-image: linear-gradient(to right, @dark100 25%, rgba(255, 255, 255, 0) 0%);
  background-position: @position;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

.CircleStatus {
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid @white100;
  border-radius: 50%;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
  }

  &.Correct::before {
    background-color: @green50;
  }

  &.Wrong::before,
  &.Bad::before {
    background-color: @red50;
  }

  &.Available::before {
    background-color: @blue50;
  }

  &.Wrong::after {
    content: "+";
    position: absolute;
    left: 3px;
    top: -1px;
    font-size: 16px;
    line-height: 16px;
    color: @white100;
    transform: rotate(45deg);
  }

  &.Correct.Confirmed {
    border: none;

    &::after {
      content: "";
      position: absolute;
      top: 5px;
      left: 4px;
      width: 8px;
      height: 5px;
      border-bottom: 2px solid @white100;
      border-left: 2px solid @white100;
      transform: rotate(-45deg);
    }
  }
}

.ColumnDirectionWithGap(@gap) {
  display: flex;
  flex-direction: column;
  gap: @gap;
}

.TableWithTitle {
  & > h2,
  & > h3 {
    margin-bottom: -50px;
  }
}

.CenteredSpinner {
  &:has(.ant-spin):not(:has(.Table)) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.TitleBlockWithButton {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
