.PageContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-top: 70px;
  padding: 16px 96px 42px 48px;
  z-index: 1;
}

@media only screen and (max-width: 1200px) {
  .PageContent {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 575px) {
  .PageContent {
    padding: 0px;
  }
}
