.PageNotFound {
  .ColumnDirectionWithGap(24px);
  padding: 160px min(320px, 15%) 3em;

  &,
  h1,
  h3 {
    color: @white100;
  }

  h1 {
    font-size: 80px;
    line-height: 96px;
  }

  button {
    align-self: flex-start;
    margin-top: 16px;
  }

  &.WhiteLayout {
    padding-bottom: 160px;
    border-radius: 16px;
    background-color: @white100;

    &,
    h1,
    h3 {
      color: @dark100;
    }
  }
}
