.TipModal {
  &-Button {
    .Button-Text();
  }

  .ant-modal-content {
    background-color: @blue50;
    color: @white100;
  }

  .ant-modal-close svg {
    fill: @white100;
  }

  .ant-modal-body {
    padding: 56px 40px;

    .icon-info-circle:before {
      color: @white100;
    }
  }

  .Content {
    p {
      text-align: left;
      font-style: italic;
    }

    ul {
      padding-inline-start: 12px;
    }

    a {
      text-decoration: underline;
      color: @turquoise20;

      &:focus-visible,
      &:hover {
        color: @white100;
      }

      &:active {
        color: @turquoise30;
      }
    }
  }

  h4,
  p {
    color: @white100;
  }
}
