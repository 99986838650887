.CopyPopover {
  &-Button {
    .Button-Text();
    margin-left: 12px;
    padding: 0;
  }

  &-Label {
    margin-left: 8px;
    color: @turquoise90;
    font-weight: 700;
  }
}
