.WalletBadge {
  .Button-Transparent();
  position: fixed;
  top: 16px;
  right: 24px;
  gap: 4px;
  z-index: 3;

  .icon-arrow-down {
    .TransitionInOutBack(transform);
  }

  .ant-image {
    transition: filter @durationAverage ease-in-out;
  }

  &:hover,
  &:focus-visible,
  &:active {
    .ant-image {
      filter: drop-shadow(0px 0px 3px @white100);
    }

    .icon-arrow-down:before {
      color: @white100;
    }
  }

  &.ant-popover-open {
    .icon-arrow-down {
      transform: rotate(180deg);
    }
  }

  && &-Status {
    .CircleStatus();
    position: absolute;
    left: 28px;
    bottom: 0;
  }

  &-Popover {
    position: fixed;

    .ant-popover-inner {
      padding: 0;
      border: none;
      border-radius: 16px;
    }
  }

  &-Content {
    .ColumnDirectionWithGap(16px);
    width: 600px;
    padding: 24px 32px;
    color: @dark100;

    h4 {
      .Bold();
    }

    div {
      display: flex;
      align-items: center;

      .ant-image {
        width: 24px;
        margin-right: 16px;
      }
    }

    button {
      .Button-Text();
    }

    .DisconnectButton {
      &,
      &:focus {
        color: @red50;
      }

      &:active {
        color: @dark100;
      }
    }

    .Divider {
      .DottedDivider(top);
      height: 1px;
    }

    .WalletBalances {
      flex-wrap: wrap;

      &-Balance {
        flex-basis: calc(50% - 20px);
        margin-right: 20px;

        span:last-of-type {
          margin-left: 4px;
          font-weight: bold;
        }
      }
    }
  }

  &-Row {
    justify-content: space-between;

    &:first-of-type {
      flex-direction: row-reverse;
    }
  }
}
