.FastCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 262px;
  height: 246px;
  padding: 32px 24px;
  border-radius: 16px;
  background-color: @dark90;
  cursor: pointer;
  .Transition(all);

  &:hover,
  &:focus-visible {
    filter: (brightness(1.1));
    transform: translateY(-5px);
  }

  h1,
  h4 {
    color: @white100;
  }

  &-Name {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  p {
    color: @white50;
  }

  p:last-of-type {
    margin-top: 16px;

    & > i {
      margin-left: 8px;
    }
  }
}
