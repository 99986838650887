.CurvedBackground {
  position: fixed;

  img {
    position: fixed;
    bottom: 0;
    right: 0;
    user-select: none;
    -webkit-user-drag: none;
    transform-origin: bottom right;
  }

  .Shape1 {
    animation: scale 0.7s @easeInOutBack;
  }

  .Shape2 {
    bottom: 44px;
    animation: scale 0.7s ease 0.3s backwards;
  }
}
