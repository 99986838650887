.Onboarding {
  .ColumnDirectionWithGap(32px);
  align-items: center;
  padding: 48px;

  &-Title {
    display: flex;
    align-items: flex-end;
    letter-spacing: 3px;
    color: @white100;

    .ant-image {
      margin: 0 8px 2px 0;

      img {
        height: 36px;
      }
    }
  }

  &-Body {
    display: grid;
    grid-template-columns: 262px 1fr 262px;
    z-index: 1;
  }

  .Steps {
    width: 262px;
    gap: 16px;
    color: @white100;

    &.ant-steps-vertical {
      .ant-steps-item {
        flex: unset;

        .ant-steps-item-container {
          display: flex;
          align-items: center;

          .ant-steps-item-tail {
            display: none;
          }

          .ant-steps-item-icon {
            width: 16px;
            height: 16px;
            margin-top: 0;
          }

          .ant-steps-icon-dot {
            background-color: @white100;
          }

          .ant-steps-item-content {
            min-height: auto;
          }

          .ant-steps-item-title {
            font-weight: 700;
            color: @white100;
          }
        }

        &.ant-steps-item-finish {
          .ant-steps-icon-dot {
            background-color: @dark60;
          }
          .ant-steps-item-title {
            color: @dark60;
          }
        }

        &.ant-steps-item-active {
          .ant-steps-icon-dot {
            background-color: @green40;
          }
          .ant-steps-item-title {
            color: @green40;
          }
        }
      }
    }
  }

  &-Form {
    width: 760px;
    border-radius: 16px;
    background-color: @white100;
    z-index: 1;

    &:not(.FastOnboarding-Form) {
      align-self: flex-start;
    }

    .Padding {
      padding: 40px 40px 76px;
    }

    legend {
      .H2();
      margin-bottom: 16px;
    }

    .form-group {
      .FieldsetInput();
    }
  }

  &-Info {
    .ColumnDirectionWithGap(16px);
    min-width: 262px;
    width: fit-content;
    margin-left: 40px;

    p {
      width: 262px;
      color: @dark60;
    }
  }

  &-Fasts {
    .ColumnDirectionWithGap(16px);
  }

  &-Block {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
    background-color: @dark90;
    border-radius: 16px;

    img {
      width: 46px;
      height: 46px;
      margin-right: 11px;
      border-radius: 4px;
    }

    h4 {
      color: @white100;

      &:last-child {
        color: @dark60;
      }
    }
  }

  .Details {
    .field-string {
      position: relative;
      display: inline-block;
      width: calc(50% - 4px);
      margin-bottom: 32px;

      &:nth-child(3) {
        margin-right: 8px;
      }
    }

    .error-detail {
      position: absolute;
      top: 100%;
      left: 16px;
      width: max-content;
      margin: 0;
      padding: 0;
    }
  }

  .Introduction {
    .ColumnDirectionWithGap(32px);
    position: relative;
    align-items: center;
    padding: 32px 40px 72px;

    h4 {
      .Bold();
    }

    form,
    p {
      width: 424px;
    }

    .CurveBackgroundMini {
      position: absolute;
      right: 0;
      bottom: -1px;
      user-select: none;
      -webkit-user-drag: none;
    }
  }

  .Introduction,
  .EmailVerification,
  .ConnectWallet {
    form {
      display: flex;
      align-items: flex-end;
      gap: 32px;
      z-index: 1;

      .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-explain-error {
          position: absolute;
          top: calc(100% + 4px);
        }

        input {
          width: 300px;
        }
      }
    }
  }

  .WhyFast {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 72px 16px;
    background-color: @blue50;
    border-radius: 0 0 16px 16px;

    p,
    h2,
    h3,
    h4 {
      text-align: center;
      color: @white100;
    }

    h2 {
      margin-bottom: 16px;
    }

    p {
      max-width: 560px;
    }

    h3 {
      max-width: 480px;
    }

    a {
      margin-bottom: 32px;
      color: @turquoise30;

      &:hover {
        color: @turquoise20;
      }
    }

    &-Areas {
      display: flex;
    }

    &-Area {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 64px;

      h4,
      ul {
        opacity: 0.7;
      }

      ul {
        margin-bottom: 0;
        color: @white100;
      }

      p {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        margin: 20px 0 40px;
        border: 1px solid @white100;
        border-radius: 50%;

        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 18px;
          top: -18px;
          right: calc(50% - 1px);
          background-color: @white100;
        }

        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 30px;
          bottom: -30px;
          right: calc(50% - 1px);
          background-color: @white100;
        }
      }

      span {
        font-weight: 700;
      }
    }
  }

  .Confirmation {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 132px 106px;
    z-index: 1;

    &-Process {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;

      .ant-spin {
        align-self: center;
        margin-bottom: 12px;
      }

      h2 {
        max-width: 260px;
      }

      & > p {
        margin-top: 8px;
      }
    }

    .ant-image {
      align-self: center;
      margin-bottom: 16px;
    }

    h2 {
      align-self: center;
      text-align: center;
    }

    p:first-of-type,
    p:last-of-type {
      margin: 32px 0 8px;
    }

    ul {
      padding-left: 12px;
    }
  }

  .Documentation {
    .ColumnDirectionWithGap(16px);

    .ant-checkbox-group {
      width: 100%;
    }

    p + h3 {
      color: @orange50;
    }

    .OnboardDocument {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      padding: 8px 32px;
      border-radius: 16px;
      background-color: @dark40;

      h3 {
        font-weight: 700;
        color: @dark80;
      }

      &-ContolPanel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        width: 220px;

        a,
        button {
          .Button-Secondary();
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        label {
          align-items: center;
        }
      }
    }

    button {
      align-self: flex-start;
      margin-top: 20px;

      & > span {
        display: flex;
        gap: 8px;
      }

      p {
        color: @dark30;
      }
    }
  }

  .SpecificFastDocumentation {
    align-items: center;

    & > h4 {
      .Bold();
      text-align: center;
    }

    .FastInfo {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      padding: 16px 0;
      border-top: 2px solid @green40;
      border-bottom: 2px solid @green40;

      img {
        width: 73px;
        height: 73px;
        margin-right: 11px;
        border-radius: 16px;
      }

      h2:last-child {
        color: @dark80;
      }
    }

    &-Form {
      .ColumnDirectionWithGap(16px);
      width: 100%;

      & > p:first-of-type {
        font-size: 20px;
        line-height: 32px;
      }

      button {
        margin: 0 0 24px;
        align-self: flex-start;
      }
    }

    &-Divider {
      padding-top: 32px;
      .DottedDivider(top);
    }

    &-IsSigned {
      p {
        font-size: 20px;
        line-height: 32px;
        text-align: center;
      }
    }
  }

  .ExistingEmail {
    position: relative;
    padding: 40px 82px;

    .ant-image {
      position: absolute;
      top: 40px;
      left: 38px;
    }

    h2 {
      margin-bottom: 4px;
      color: @orange50;
    }

    h4 {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 32px;
    }

    button {
      margin-bottom: 108px;
    }
  }

  .EmailVerification {
    h2,
    p {
      margin-bottom: 16px;

      span {
        color: @red50;
      }
    }

    form {
      gap: 8px;
      margin-bottom: 48px;

      .ant-form-item {
        .ant-form-item-explain-error {
          top: 100%;
        }
      }
    }
  }

  .ClientVerification {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 40px 76px;

    .onfido-sdk-ui-Modal-inner {
      height: 75vh;
      border: none;
      background-color: @white100;
    }

    .onfido-sdk-ui-Theme-content {
      height: fit-content;
      overflow-y: visible;
    }

    .onfido-sdk-ui-PageTitle-title {
      .H1();
      color: @dark100;
    }

    .onfido-sdk-ui-PageTitle-subTitle,
    .ods-field-label,
    label,
    input,
    select,
    option {
      .P();
      font-family: Lato, sans-serif;
    }

    .ods-button {
      .Button();
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 14px;

      &:focus {
        box-shadow: none;
      }
    }

    .onfido-sdk-ui-Confirm-retakeAction {
      .Button-Secondary();

      &:active:not(:disabled) {
        background-color: @dark100;
      }
    }

    .ods-field,
    .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer,
    .onfido-sdk-ui-CountrySelector-container {
      .FieldsetInput();

      label {
        padding-bottom: 0;
      }

      .ods-validation {
        .P-Small();
      }

      .-error {
        color: @red50;
      }

      input:focus-visible {
        outline: none;
      }
    }

    .onfido-sdk-ui-NavigationBar-back,
    .onfido-sdk-ui-Button-fallbackButton {
      .Button-Transparent();

      &:hover {
        .onfido-sdk-ui-NavigationBar-iconBack {
          background-color: @turquoise30;
          .Transition(background-color);
        }
        .onfido-sdk-ui-NavigationBar-label {
          .Transition(visibility);
        }
      }

      .onfido-sdk-ui-NavigationBar-label {
        display: none;
      }
    }

    .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option--focused,
    .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option {
      &:hover {
        background-color: @turquoise50;
      }
      &:active {
        background-color: @turquoise90;
      }
    }

    .onfido-sdk-ui-Theme-footer {
      display: none;
    }

    .onfido-sdk-ui-DocumentSelector-DocumentList-option {
      min-height: 82px;
      height: auto;
      color: @dark100;

      &:focus,
      &:hover {
        box-shadow: none;
        background-color: @white100;
        border-color: @turquoise50;

        div {
          color: @dark100;
        }
      }
      &:active {
        box-shadow: none;
      }
    }

    .onfido-sdk-ui-Theme-link,
    .onfido-sdk-ui-Theme-textButton {
      .Button-Transparent();
      .Link();
    }

    .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer {
      width: 100%;
      height: 38px;
      display: flex;
      align-items: center;
      margin-right: 8px;
      border: 1px solid @turquoise20;
      border-radius: 24px;
      appearance: none;
      background-color: @turquoise20;
      box-shadow: none;
      .Transition(all);

      input,
      input:-webkit-autofill,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:active {
        outline: none;
        background-color: @turquoise20 !important;
      }

      &:focus,
      &:hover {
        border-color: @turquoise50;
      }
      &:focus {
        box-shadow: none;
      }
    }

    .onfido-sdk-ui-crossDevice-CrossDeviceLink-linkContainer {
      align-items: center;
    }

    .onfido-sdk-ui-Camera-camera {
      .onfido-sdk-ui-PageTitle-title {
        .H3();
        color: @white100;
      }
    }

    .onfido-sdk-ui-Camera-webcamContainer {
      height: 37.5em;
    }
  }

  .ConnectWallet {
    p:first-of-type {
      margin-bottom: 36px;
      color: @dark100;
    }

    form {
      .ColumnDirectionWithGap(32px);
      align-items: flex-start;
      margin-bottom: 32px;

      input.ant-input {
        width: 508px;
        pointer-events: none;
      }
    }

    p {
      color: @dark80;
    }
  }
}
