.FastsLayout {
  padding: 94px 40px 130px;
  gap: 16px;
  color: @white100;

  &-Header {
    .H1();
    display: flex;
    height: 78px;
  }

  &-Body {
    .ColumnDirectionWithGap(8px);
    width: 1120px;
    margin: 0 auto;
    text-align: center;

    h1,
    p {
      color: @white100;
    }

    & > p {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      font-weight: 800;
    }

    &-ActionButtons {
      display: flex;
      justify-content: center;
      gap: 16px;
      margin: 16px 0 24px;
      padding: 16px 0;

      button {
        .Button();
        .DarkBackground();
      }
    }

    &-Fasts {
      display: grid;
      grid-template-columns: 262px 262px 262px 262px;
      justify-content: center;
      grid-gap: 24px;
      margin-top: 54px;
      width: 100%;
    }
  }
}

.Header {
  position: fixed;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 16px 108px 16px 24px;
  background-color: @header;
  color: @white100;
  z-index: 2;

  .FastsDropdown {
    margin-top: 8px;
  }

  & > button {
    .Button-Header();
  }
}

.FastHeaderButtons {
  position: fixed;
  top: 16px;
  right: 108px;
  display: flex;
  gap: 8px;
  z-index: 2;
}
