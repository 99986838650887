.Community {
  .CenteredSpinner();

  &-Top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;

    button {
      .Button-Secondary();
    }
  }

  table {
    .ant-table-thead > tr > th:last-child,
    .ant-table-tbody > tr > td:last-child:not(:first-child) {
      display: flex;
      justify-content: flex-end;
      padding-right: 0;
      text-align: end;
    }

    td {
      min-height: 71px;
    }

    .GreenText {
      display: flex;
      align-items: center;
    }

    .StatusColumn {
      &-Rejected {
        display: flex;
        align-items: center;
        gap: 16px;

        button {
          .Button-Transparent();
        }
      }
    }
  }

  &-AddAddress {
    .ant-form {
      .ColumnDirectionWithGap(24px);

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .ant-table-empty {
    margin-top: 50px;
  }

  .AlternativePagination {
    margin-top: 16px;
  }
}

.StatusColumn-Overlay {
  max-width: 440px;
}
