.Login {
  .ColumnDirectionWithGap(24px);
  align-items: center;
  margin: auto;

  .Signin-Stepper {
    position: absolute;
    top: 16vh;
    width: 400px;
    opacity: 0;
    transform: translateY(30px);
    animation: @durationLong appearance @durationLong @easeOutBack forwards;

    &.ant-steps {
      .ant-steps-item-container {
        .ant-steps-item-content {
          min-height: 32px;
        }

        .ant-steps-item-title {
          color: @white100;
        }
      }

      .ant-steps-item-active {
        .ant-steps-icon-dot {
          background-color: @green40;
        }
        .ant-steps-item-title {
          color: @green40;
        }
      }

      .ant-steps-item-wait .ant-steps-icon-dot {
        width: 10px;
        height: 10px;
        background-color: @white100;
      }

      .ant-steps-item-finish .ant-steps-item-content {
        width: 200px;
      }
    }
  }

  &-Card {
    .ColumnDirectionWithGap(40px);
    position: absolute;
    top: 24vh;
    width: 360px;
    padding: 48px 32px 56px;
    border-radius: 32px;
    background-color: @white20;
    transform: perspective(550px) rotateY(90deg);
    animation: @durationLong rotation 0.5s @easeOutBack forwards;
  }
}
