.DataBox {
  background: @dark40;
  padding: 8px 14px;
  width: fit-content;
  border-radius: 4px;
}

.DataBox-Top {
  display: flex;
  align-items: center;

  p {
    .H2();
    color: @dark100;
  }
}

.DataBox-Bottom {
  display: flex;
  align-items: center;
  gap: 8px;
}

.DataBox-Description-Locked {
  color: @dark80;
}
