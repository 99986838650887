.FastWelcome {
  min-height: 100vh;
  margin-left: 25%;

  &-Header {
    margin-top: 134px;
    font-size: min(80px, 9vw);
    line-height: min(94px, 9vw);
    color: @white100;
  }

  &-Row {
    color: @white100;
    margin-bottom: 3em;

    &-Title {
      .H2();
      margin: 32px 0 16px;
      color: @white100;
    }
  }
}

@media only screen and (max-width: 575px) {
  .FastWelcome {
    margin: 24px;

    &-Row {
      display: flex;
      flex-direction: row;
    }
    &-Header {
      margin-top: 80px;
    }
  }
}
