.StatusLabel {
  .P-Small();
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 8px 12px;
  border-radius: 24px;
  background-color: @dark30;
  color: @green80;

  &.Closed {
    background-color: @dark50;
    color: @dark100;
  }
}
