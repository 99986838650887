.ant-layout {
  background: none;
}
.ant-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
  box-shadow: none;
}
.ant-btn,
.ant-btn:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.ant-btn:focus-visible,
.ant-btn:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.ant-btn:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.ant-btn[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.ant-btn[disabled]:hover,
.ant-btn[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.ant-btn span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.ant-btn span.Bold {
  font-weight: 700;
}
.ant-btn.DarkBackground,
.ant-btn.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.ant-btn.DarkBackground:focus-visible,
.ant-btn.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.ant-btn.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
a.ant-btn {
  display: inline-block;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  content: "*";
  display: inline-block;
  margin-right: 4px;
  color: #ee3900;
}
button.ant-modal-close:hover,
button.ant-modal-close:focus,
button.ant-modal-close:active {
  border: none;
  background-color: transparent;
}
button.ant-modal-close:focus-visible .ant-modal-close-icon {
  outline: 1px solid #0080a3;
}
button.ant-modal-close[ant-click-animating-without-extra-node]:after {
  all: unset;
}
.ant-pagination {
  align-items: center;
}
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-item-link,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  margin-right: 0px;
}
.ant-pagination .ant-pagination-item a,
.ant-pagination .ant-pagination-item-link a,
.ant-pagination .ant-pagination-prev a,
.ant-pagination .ant-pagination-next a {
  color: #1c1b37;
}
.ant-pagination .ant-pagination-item svg,
.ant-pagination .ant-pagination-item-link svg,
.ant-pagination .ant-pagination-prev svg,
.ant-pagination .ant-pagination-next svg {
  width: 19px;
  height: 19px;
}
.ant-pagination .ant-pagination-item:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-item-link:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-item:focus-visible,
.ant-pagination .ant-pagination-item-link:focus-visible,
.ant-pagination .ant-pagination-prev:focus-visible,
.ant-pagination .ant-pagination-next:focus-visible {
  color: #0080a3;
}
.ant-pagination .ant-pagination-item:active,
.ant-pagination .ant-pagination-item-link:active,
.ant-pagination .ant-pagination-prev:active,
.ant-pagination .ant-pagination-next:active {
  color: #1c1b37;
}
.ant-pagination .ant-pagination-item-active a {
  color: #0080a3;
}
.ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
  color: #9e9eb7;
}
.ant-pagination .ant-pagination-disabled .ant-pagination-item-link:hover,
.ant-pagination .ant-pagination-disabled .ant-pagination-item-link:focus-visible,
.ant-pagination .ant-pagination-disabled .ant-pagination-item-link:active {
  border: none;
  background-color: transparent;
  color: #9e9eb7;
}
.ant-pagination .ant-pagination-options {
  display: flex;
  align-items: center;
}
.ant-pagination .ant-pagination-options .ant-select-selector,
.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
  align-items: center;
  height: 38px;
  border-radius: 24px;
}
.ant-pagination .ant-pagination-options .ant-select-selector:focus,
.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input:focus {
  box-shadow: none;
}
.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  height: 38px;
}
.ant-modal-content {
  border-radius: 16px;
}
.ant-checkbox-wrapper {
  align-items: center;
}
.ant-checkbox-wrapper .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border-color: #1c1b37;
}
.ant-checkbox-wrapper .ant-checkbox-inner::after {
  top: 6px;
  width: 14px;
  height: 8px;
  border-radius: 1px;
  border-left: 4px solid #ffffff;
  border-bottom: 4px solid #ffffff;
  border-right: 0;
  transform: rotate(-45deg);
}
.ant-checkbox-wrapper .ant-checkbox {
  top: 0;
}
.ant-checkbox-wrapper span {
  user-select: none;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):focus-visible span,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover span {
  color: #23b8e1;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):focus-visible:after,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover:after {
  border-color: #23b8e1;
}
.ant-checkbox-wrapper .ant-checkbox-checked:after {
  border-radius: 4px;
}
.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
}
.ant-form-item label {
  display: block;
  margin-bottom: 8px;
}
.ant-form-item input,
.ant-form-item select,
.ant-form-item textarea {
  width: 100%;
  min-height: 38px;
  padding: 7px 13px;
  border: 1px solid #dcf1f8;
  border-radius: 24px;
  appearance: none;
  background-color: #dcf1f8;
  caret-color: #0080a3;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.ant-form-item input:focus:not(:disabled),
.ant-form-item select:focus:not(:disabled),
.ant-form-item textarea:focus:not(:disabled),
.ant-form-item input:hover:not(:disabled),
.ant-form-item select:hover:not(:disabled),
.ant-form-item textarea:hover:not(:disabled) {
  border-color: #23b8e1;
}
.ant-form-item input:focus,
.ant-form-item select:focus,
.ant-form-item textarea:focus {
  box-shadow: none;
}
.ant-form-item input:focus-visible,
.ant-form-item select:focus-visible,
.ant-form-item textarea:focus-visible {
  outline: none;
}
.ant-form-item input[type="number"],
.ant-form-item select[type="number"],
.ant-form-item textarea[type="number"] {
  -moz-appearance: textfield;
}
.ant-form-item input[type="number"]::-webkit-outer-spin-button,
.ant-form-item select[type="number"]::-webkit-outer-spin-button,
.ant-form-item textarea[type="number"]::-webkit-outer-spin-button,
.ant-form-item input[type="number"]::-webkit-inner-spin-button,
.ant-form-item select[type="number"]::-webkit-inner-spin-button,
.ant-form-item textarea[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
.ant-form-item input:-internal-autofill-selected,
.ant-form-item select:-internal-autofill-selected,
.ant-form-item textarea:-internal-autofill-selected,
.ant-form-item input:-webkit-autofill,
.ant-form-item select:-webkit-autofill,
.ant-form-item textarea:-webkit-autofill,
.ant-form-item input:-webkit-autofill:focus,
.ant-form-item select:-webkit-autofill:focus,
.ant-form-item textarea:-webkit-autofill:focus,
.ant-form-item input:-webkit-autofill:hover,
.ant-form-item select:-webkit-autofill:hover,
.ant-form-item textarea:-webkit-autofill:hover,
.ant-form-item input:-webkit-autofill:active,
.ant-form-item select:-webkit-autofill:active,
.ant-form-item textarea:-webkit-autofill:active {
  background-color: #dcf1f8 !important;
}
.ant-form-item input:-webkit-autofill,
.ant-form-item select:-webkit-autofill,
.ant-form-item textarea:-webkit-autofill {
  background-color: #dcf1f8;
}
.ant-form-item textarea {
  padding: 8px 16px;
  border-radius: 16px;
  resize: none;
}
.ant-form-item .ant-form-item-explain-error {
  font-size: 12px;
  color: #ee3900;
}
.ant-form-item .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  box-shadow: none;
  border-color: #23b8e1;
}
.ant-form-item.ant-form-item .ant-input-affix-wrapper,
.ant-form-item.ant-form-item .ant-form-item-control-input-content {
  padding: 0;
  border: none;
  border-radius: 24px;
}
.ant-form-item.ant-form-item .ant-input-affix-wrapper:focus,
.ant-form-item.ant-form-item .ant-form-item-control-input-content:focus,
.ant-form-item.ant-form-item .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error,
.ant-form-item.ant-form-item .ant-form-item-control-input-content.ant-input-affix-wrapper-status-error,
.ant-form-item.ant-form-item .ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.ant-form-item.ant-form-item .ant-form-item-control-input-content.ant-input-affix-wrapper-focused {
  box-shadow: none;
  z-index: 0;
}
.ant-form-item.ant-form-item .ant-input-affix-wrapper input,
.ant-form-item.ant-form-item .ant-form-item-control-input-content input {
  padding: 7px 13px;
  border: 1px solid #dcf1f8;
}
.ant-form-item.ant-form-item .ant-input-affix-wrapper input:focus:not(:disabled),
.ant-form-item.ant-form-item .ant-form-item-control-input-content input:focus:not(:disabled),
.ant-form-item.ant-form-item .ant-input-affix-wrapper input:hover:not(:disabled),
.ant-form-item.ant-form-item .ant-form-item-control-input-content input:hover:not(:disabled) {
  border-color: #23b8e1;
}
.ant-form-item.ant-form-item .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error input,
.ant-form-item.ant-form-item .ant-form-item-control-input-content.ant-input-affix-wrapper-status-error input {
  border-color: #ee3900;
}
.ant-form-item.ant-form-item .ant-input-affix-wrapper .ant-input-suffix,
.ant-form-item.ant-form-item .ant-form-item-control-input-content .ant-input-suffix {
  position: absolute;
  right: 16px;
  bottom: 8px;
}
.ant-form-item.ant-form-item .ant-input-affix-wrapper .ant-input-suffix,
.ant-form-item.ant-form-item .ant-form-item-control-input-content .ant-input-suffix,
.ant-form-item.ant-form-item .ant-input-affix-wrapper .ant-input-show-count-suffix,
.ant-form-item.ant-form-item .ant-form-item-control-input-content .ant-input-show-count-suffix {
  color: #676783;
  font-weight: 700;
}
.ant-form-item .ant-form-item-extra {
  color: #676783;
}
.ant-form-item .ant-select .ant-select-selector {
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.ant-form-item .ant-form-item-label {
  padding: 0;
}
.ant-form-item .ant-form-item-label label {
  padding-bottom: 8px;
}
.ant-form-horizontal .ant-form-item-row {
  align-items: center;
}
.ant-form-horizontal .ant-form-item-row label {
  height: auto;
  margin-bottom: 0;
}
.ant-switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  width: 85px;
  height: 38px;
  padding: 0;
  overflow: hidden;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.ant-switch,
.ant-switch:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.ant-switch:focus-visible,
.ant-switch:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.ant-switch:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.ant-switch[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.ant-switch[disabled]:hover,
.ant-switch[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.ant-switch span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.ant-switch span.Bold {
  font-weight: 700;
}
.ant-switch.DarkBackground,
.ant-switch.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.ant-switch.DarkBackground:focus-visible,
.ant-switch.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.ant-switch.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.ant-switch,
.ant-switch:focus {
  border-color: #0080a3;
  background-color: #ffffff;
  color: #0080a3;
}
.ant-switch:focus-visible,
.ant-switch:hover {
  border-color: #1c1b37;
  background-color: #ffffff;
  color: #1c1b37;
}
.ant-switch:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.ant-switch.Transparent {
  background-color: transparent;
}
.ant-switch.Transparent:hover,
.ant-switch.Transparent:focus-visible,
.ant-switch.Transparent:active {
  background-color: transparent;
}
.ant-switch[disabled] {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.ant-switch[disabled]:focus-visible,
.ant-switch[disabled]:hover,
.ant-switch[disabled]:active {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.ant-switch:hover,
.ant-switch:focus-visible,
.ant-switch:active {
  background-color: transparent;
}
.ant-switch,
.ant-switch:focus {
  border-color: #676783;
  box-shadow: none;
}
.ant-switch .ant-switch-handle {
  color: #676783;
}
.ant-switch .ant-switch-handle:before {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #676783;
}
.ant-switch .ant-switch-handle:after {
  content: "Off";
  position: absolute;
  top: 5px;
  left: 48px;
}
.ant-switch.ant-switch-checked {
  border-color: #23b8e1;
}
.ant-switch.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 32px - 2px);
}
.ant-switch.ant-switch-checked .ant-switch-handle:before {
  background-color: #23b8e1;
}
.ant-switch.ant-switch-checked .ant-switch-handle:after {
  content: "On";
  left: -36px;
  color: #23b8e1;
}
.ant-switch:focus-visible,
.ant-switch:hover {
  border-color: #31304b;
}
.ant-switch:focus-visible .ant-switch-handle:before,
.ant-switch:hover .ant-switch-handle:before {
  background-color: #31304b;
}
.ant-input-number {
  min-width: 160px;
  border: none;
}
.ant-input-number:focus,
.ant-input-number.ant-input-number-status-error:not(.ant-input-number-disabled):not(
      .ant-input-number-borderless
    ).ant-input-number-focused,
.ant-input-number.ant-input-number-focused {
  box-shadow: none;
}
.ant-input-number:focus .ant-input-number-handler-down,
.ant-input-number.ant-input-number-status-error:not(.ant-input-number-disabled):not(
      .ant-input-number-borderless
    ).ant-input-number-focused .ant-input-number-handler-down,
.ant-input-number.ant-input-number-focused .ant-input-number-handler-down {
  border-color: #23b8e1;
}
.ant-input-number .ant-input-number-handler-wrap {
  top: 1px;
  right: 1px;
  width: 40px;
  height: 36px;
  border-radius: 0 24px 24px 0;
}
.ant-input-number .ant-input-number-handler,
.ant-input-number .ant-input-number-handler-down {
  border-color: #23b8e1;
}
.ant-input-number .ant-input-number-handler-up-inner,
.ant-input-number .ant-input-number-handler-down-inner {
  right: 15px;
}
.ant-input-disabled[disabled] {
  padding: 7px 13px;
  border-radius: 24px;
  appearance: none;
  background-color: #eae9f2;
}
.ant-modal-close {
  top: 12px;
}
.ant-modal-close svg {
  width: 24px;
  height: 24px;
  transition: fill 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.ant-modal-close svg,
.ant-modal-close svg:focus {
  fill: #23b8e1;
}
.ant-modal-close svg:hover,
.ant-modal-close svg:focus-visible {
  fill: #125a6e;
}
.ant-modal-close svg:active {
  fill: #1c1b37;
}
.ant-modal-body {
  padding: 40px;
}
.ant-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  padding: 0;
}
.ant-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header.Bold {
  font-weight: 700;
}
.ant-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header,
.ant-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  color: #0080a3;
}
.ant-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover,
.ant-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus-visible {
  color: #125a6e;
}
.ant-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header:active {
  color: #1c1b37;
}
.ant-dropdown-menu {
  padding: 8px 0;
  border-radius: 8px;
}
.ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  padding: 6px 16px;
}
.ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #dcf1f8;
}
.ant-breadcrumb span {
  color: #676783;
}
.ant-breadcrumb .icon-arrow-left-tail {
  font-weight: 700;
  position: relative;
  top: 1px;
  margin-right: 4px;
  font-size: 16px;
  color: inherit;
}
.ant-breadcrumb .ant-breadcrumb-separator {
  color: #0080a3;
}
.ant-breadcrumb.ant-breadcrumb .ant-breadcrumb-link a {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  font-weight: normal;
}
.ant-breadcrumb.ant-breadcrumb .ant-breadcrumb-link a.Bold {
  font-weight: 700;
}
.ant-breadcrumb.ant-breadcrumb .ant-breadcrumb-link a,
.ant-breadcrumb.ant-breadcrumb .ant-breadcrumb-link a:focus {
  color: #0080a3;
}
.ant-breadcrumb.ant-breadcrumb .ant-breadcrumb-link a:hover,
.ant-breadcrumb.ant-breadcrumb .ant-breadcrumb-link a:focus-visible {
  color: #125a6e;
}
.ant-breadcrumb.ant-breadcrumb .ant-breadcrumb-link a:active {
  color: #1c1b37;
}
.ant-select.ant-select-auto-complete {
  width: 100%;
}
.ant-select.ant-select-auto-complete.ant-select.ant-select-auto-complete .ant-select-selector {
  min-height: 38px;
  padding: 3px 13px;
  border: 1px solid #dcf1f8;
  border-radius: 24px;
  background-color: #dcf1f8;
}
.ant-select.ant-select-auto-complete.ant-select.ant-select-auto-complete .ant-select-selector:focus:not(:disabled),
.ant-select.ant-select-auto-complete.ant-select.ant-select-auto-complete .ant-select-selector:hover:not(:disabled) {
  border-color: #23b8e1;
}
.ant-select.ant-select-auto-complete.ant-select.ant-select-auto-complete .ant-select-selector:focus {
  box-shadow: none;
}
.ant-select.ant-select-auto-complete.ant-select.ant-select-auto-complete .ant-select-selector .ant-select-selection-search,
.ant-select.ant-select-auto-complete.ant-select.ant-select-auto-complete .ant-select-selector input.ant-select-selection-search-input {
  min-height: 36px;
}
.ant-select.ant-select-auto-complete .anticon-close-circle svg {
  background-color: #dcf1f8;
  fill: #125a6e;
}
.ant-select.ant-select-auto-complete.ant-select-status-error.ant-select.ant-select-auto-complete.ant-select-status-error .ant-select-selector {
  box-shadow: none;
}
.ant-select.ant-select-auto-complete.ant-select-status-error .anticon-close-circle svg {
  background-color: #ffffff;
}
.ant-select-dropdown {
  padding: 8px 0;
  border-radius: 8px;
  z-index: 1;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ffffff;
}
.ant-select-dropdown .ant-select-item-option.ant-select-item-option-active {
  background-color: #ffffff;
}
.ant-select-dropdown .ant-select-item-option:focus,
.ant-select-dropdown .ant-select-item-option:hover {
  background-color: #dcf1f8;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 1px solid #23b8e1;
}
.ant-popover .ant-popover-arrow {
  bottom: -7px;
  width: 14px;
  height: 14px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  background-color: #ffffff;
  transform: rotate(45deg);
}
.ant-popover .ant-popover-arrow .ant-popover-arrow-content {
  display: none;
}
.ant-popover .ant-popover-inner {
  padding: 6px 12px;
  border-radius: 0;
  border: 1px solid #1c1b37;
  word-wrap: anywhere;
}
.ant-popover .ant-popover-inner-content {
  padding: 0;
  color: #676783;
}
.ant-popover.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 8px;
}
button[ant-click-animating-without-extra-node]:after {
  border: 0 none;
  opacity: 0;
  animation: none 0 ease 0 1 normal;
}
.ant-radio-group {
  display: flex;
}
.ant-radio-group .ant-radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.ant-radio-group .ant-radio-wrapper .ant-radio {
  top: 0;
  height: 24px;
}
.ant-radio-group .ant-radio-wrapper .ant-radio.ant-radio-checked .ant-radio-inner {
  border-color: #23b8e1;
}
.ant-radio-group .ant-radio-wrapper .ant-radio.ant-radio-checked .ant-radio-inner:after {
  top: 4px;
  left: 4px;
  transform: none;
  margin: 0;
}
.ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  width: 24px;
  height: 24px;
  padding-left: 0;
  background-color: transparent;
  border-color: #1c1b37;
}
.ant-radio-group .ant-radio-wrapper .ant-radio-inner:before {
  display: none;
}
.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  position: absolute;
  bottom: 8px;
  right: 12px;
  margin-bottom: 0;
}
@font-face {
  font-family: "icon-font";
  src: url("/assets/fonts/icon-font.eot?jfv785");
  src: url("/assets/fonts/icon-font.eot?jfv785#iefix") format("embedded-opentype"), url("/assets/fonts/icon-font.ttf?jfv785") format("truetype"), url("/assets/fonts/icon-font.woff?jfv785") format("woff"), url("/assets/fonts/icon-font.svg?jfv785#icon-font") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: inherit;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-down-tail:before {
  content: "\e900";
  font-size: 20px;
}
.icon-arrow-down:before {
  content: "\e901";
  font-size: 24px;
  color: #23b8e1;
  transition: color 0.25s ease-in-out;
}
.icon-arrow-left-tail:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-right-tail:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-arrow-up-from-bracket:before {
  content: "\e906";
}
.icon-arrow-up-tail:before {
  content: "\e907";
}
.icon-arrow-up:before {
  content: "\e908";
}
.icon-arrows:before {
  content: "\e909";
}
.icon-attachment-doc:before {
  content: "\e90a";
}
.icon-coins:before {
  content: "\e90b";
}
.icon-comment-feedback:before {
  content: "\e90c";
}
.icon-cross:before {
  content: "\e90d";
}
.icon-eye:before {
  content: "\e90e";
}
.icon-facebook {
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.icon-facebook:before {
  content: "\e90f";
  font-size: 24px;
}
.icon-facebook:hover,
.icon-facebook:focus-visible,
.icon-facebook:active {
  color: #0165e1;
}
.icon-hand-ko:before {
  content: "\e910";
  color: #ee3900;
}
.icon-hand-ok:before {
  content: "\e911";
  color: #3a825c;
}
.icon-hand-pointing:before {
  content: "\e912";
}
.icon-hand:before {
  content: "\e913";
  color: #676783;
}
.icon-info-circle:before {
  content: "\e914";
  font-size: 24px;
  color: #3b73b9;
}
.icon-instagram:before {
  content: "\e915";
  font-size: 24px;
}
.icon-link:before {
  content: "\e916";
  font-size: 24px;
}
.icon-linkedin {
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.icon-linkedin:before {
  content: "\e917";
  font-size: 24px;
}
.icon-linkedin:hover,
.icon-linkedin:focus-visible,
.icon-linkedin:active {
  color: #0a66c2;
}
.icon-lock:before {
  content: "\e918";
  color: #676783;
}
.icon-mattermost:before {
  content: "\e919";
}
.icon-metamask .path1:before {
  content: "\e91a";
  color: #e2761b;
}
.icon-metamask .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: #e4761b;
}
.icon-metamask .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: #e4761b;
}
.icon-metamask .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: #e4761b;
}
.icon-metamask .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: #e4761b;
}
.icon-metamask .path6:before {
  content: "\e91f";
  margin-left: -1em;
  color: #e4761b;
}
.icon-metamask .path7:before {
  content: "\e920";
  margin-left: -1em;
  color: #e4761b;
}
.icon-metamask .path8:before {
  content: "\e921";
  margin-left: -1em;
  color: #e4761b;
}
.icon-metamask .path9:before {
  content: "\e922";
  margin-left: -1em;
  color: #d7c1b3;
}
.icon-metamask .path10:before {
  content: "\e923";
  margin-left: -1em;
  color: #d7c1b3;
}
.icon-metamask .path11:before {
  content: "\e924";
  margin-left: -1em;
  color: #233447;
}
.icon-metamask .path12:before {
  content: "\e925";
  margin-left: -1em;
  color: #233447;
}
.icon-metamask .path13:before {
  content: "\e926";
  margin-left: -1em;
  color: #cd6116;
}
.icon-metamask .path14:before {
  content: "\e927";
  margin-left: -1em;
  color: #cd6116;
}
.icon-metamask .path15:before {
  content: "\e928";
  margin-left: -1em;
  color: #cd6116;
}
.icon-metamask .path16:before {
  content: "\e929";
  margin-left: -1em;
  color: #cd6116;
}
.icon-metamask .path17:before {
  content: "\e92a";
  margin-left: -1em;
  color: #e4751f;
}
.icon-metamask .path18:before {
  content: "\e92b";
  margin-left: -1em;
  color: #e4751f;
}
.icon-metamask .path19:before {
  content: "\e92c";
  margin-left: -1em;
  color: #e4751f;
}
.icon-metamask .path20:before {
  content: "\e92d";
  margin-left: -1em;
  color: #e4751f;
}
.icon-metamask .path21:before {
  content: "\e92e";
  margin-left: -1em;
  color: #f6851b;
}
.icon-metamask .path22:before {
  content: "\e92f";
  margin-left: -1em;
  color: #f6851b;
}
.icon-metamask .path23:before {
  content: "\e930";
  margin-left: -1em;
  color: #c0ad9e;
}
.icon-metamask .path24:before {
  content: "\e931";
  margin-left: -1em;
  color: #161616;
}
.icon-metamask .path25:before {
  content: "\e932";
  margin-left: -1em;
  color: #763d16;
}
.icon-metamask .path26:before {
  content: "\e933";
  margin-left: -1em;
  color: #763d16;
}
.icon-metamask .path27:before {
  content: "\e934";
  margin-left: -1em;
  color: #f6851b;
}
.icon-metamask .path28:before {
  content: "\e935";
  margin-left: -1em;
  color: #f6851b;
}
.icon-metamask .path29:before {
  content: "\e936";
  margin-left: -1em;
  color: #f6851b;
}
.icon-minus:before {
  content: "\e937";
}
.icon-new-window:before {
  content: "\e938";
  font-size: 20px;
}
.icon-plus:before {
  content: "\e939";
}
.icon-points:before {
  content: "\e93a";
}
.icon-reply-left:before {
  content: "\e93b";
}
.icon-reply:before {
  content: "\e93c";
  font-size: 16px;
}
.icon-search:before {
  content: "\e93d";
}
.icon-settings-ok:before {
  content: "\e93e";
}
.icon-sign:before {
  content: "\e93f";
  font-size: 20px;
}
.icon-slack:before {
  content: "\e940";
  font-size: 18px;
}
.icon-star-filled:before {
  content: "\e941";
}
.icon-star:before {
  content: "\e942";
}
.icon-telegram {
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.icon-telegram:before {
  content: "\e943";
  font-size: 24px;
}
.icon-telegram:hover,
.icon-telegram:focus-visible,
.icon-telegram:active {
  color: #27a7e7;
}
.icon-tick:before {
  content: "\e944";
}
.icon-trash:before {
  content: "\e945";
  font-size: 24px;
}
.icon-twitter-x {
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.icon-twitter-x:before {
  content: "\e946";
  font-size: 24px;
}
.icon-twitter-x:hover,
.icon-twitter-x:focus-visible,
.icon-twitter-x:active {
  color: #1d9bf0;
}
.icon-twitter {
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.icon-twitter:before {
  content: "\e947";
  font-size: 24px;
}
.icon-twitter:hover,
.icon-twitter:focus-visible,
.icon-twitter:active {
  color: #1d9bf0;
}
.icon-user-role-expert .path1:before {
  content: "\e948";
  color: #ffffff;
}
.icon-user-role-expert .path2:before {
  content: "\e949";
  margin-left: -1em;
  color: #3b73b9;
}
.icon-user-role-expert .path3:before {
  content: "\e94a";
  margin-left: -1em;
  color: #3b73b9;
}
.icon-user-role-expert .path4:before {
  content: "\e94b";
  margin-left: -1em;
  color: #3b73b9;
}
.icon-user .path1:before {
  content: "\e94c";
  color: #ffffff;
}
.icon-user .path2:before {
  content: "\e94d";
  margin-left: -1em;
  color: #3b73b9;
}
.icon-user .path3:before {
  content: "\e94e";
  margin-left: -1em;
  color: #3b73b9;
}
.icon-whatsapp:before {
  content: "\e94f";
}
.icon-copy:before {
  content: "\e950";
  font-size: 22px;
}
h1,
.H1 {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
}
h2,
.H2 {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
h3,
.H3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}
h4,
.H4 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
h4.Bold,
.H4.Bold {
  font-weight: 700;
}
p,
.P {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
p.Bold,
.P.Bold {
  font-weight: 700;
}
.P-Small {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}
.P-Small.Bold {
  font-weight: 700;
}
p[title] {
  cursor: help;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
  color: #1c1b37;
}
.Bold {
  font-weight: 700;
}
.Opaque {
  opacity: 0.7;
}
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes appearance {
  to {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes rotation {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: perspective(550px) rotateY(0deg);
  }
}
.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Button,
.Button:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.Button:focus-visible,
.Button:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.Button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Button[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.Button[disabled]:hover,
.Button[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.Button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.Button span.Bold {
  font-weight: 700;
}
.Button.DarkBackground,
.Button.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.Button.DarkBackground:focus-visible,
.Button.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.Button.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.Button-Secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Button-Secondary,
.Button-Secondary:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.Button-Secondary:focus-visible,
.Button-Secondary:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.Button-Secondary:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Button-Secondary[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.Button-Secondary[disabled]:hover,
.Button-Secondary[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.Button-Secondary span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.Button-Secondary span.Bold {
  font-weight: 700;
}
.Button-Secondary.DarkBackground,
.Button-Secondary.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.Button-Secondary.DarkBackground:focus-visible,
.Button-Secondary.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.Button-Secondary.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.Button-Secondary,
.Button-Secondary:focus {
  border-color: #0080a3;
  background-color: #ffffff;
  color: #0080a3;
}
.Button-Secondary:focus-visible,
.Button-Secondary:hover {
  border-color: #1c1b37;
  background-color: #ffffff;
  color: #1c1b37;
}
.Button-Secondary:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Button-Secondary.Transparent {
  background-color: transparent;
}
.Button-Secondary.Transparent:hover,
.Button-Secondary.Transparent:focus-visible,
.Button-Secondary.Transparent:active {
  background-color: transparent;
}
.Button-Secondary[disabled] {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.Button-Secondary[disabled]:focus-visible,
.Button-Secondary[disabled]:hover,
.Button-Secondary[disabled]:active {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.Button-Header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Button-Header,
.Button-Header:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.Button-Header:focus-visible,
.Button-Header:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.Button-Header:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Button-Header[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.Button-Header[disabled]:hover,
.Button-Header[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.Button-Header span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.Button-Header span.Bold {
  font-weight: 700;
}
.Button-Header.DarkBackground,
.Button-Header.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.Button-Header.DarkBackground:focus-visible,
.Button-Header.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.Button-Header.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.Button-Header,
.Button-Header:focus {
  border-color: #23b8e1;
  background-color: transparent;
  color: #23b8e1;
}
.Button-Header:focus-visible,
.Button-Header:hover {
  border-color: #9dcddd;
  background-color: transparent;
  color: #9dcddd;
}
.Button-Header:active {
  border-color: #ffffff;
  background-color: #1c1b37;
  color: #ffffff;
}
.Button-Transparent,
.Button-Transparent:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.Button-Transparent[disabled],
.Button-Transparent:hover,
.Button-Transparent:active,
.Button-Transparent:focus-visible {
  border: none;
  background-color: transparent;
}
.Button-Transparent[disabled][disabled],
.Button-Transparent:hover[disabled],
.Button-Transparent:active[disabled],
.Button-Transparent:focus-visible[disabled] {
  background-color: transparent;
}
.Button-Text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  height: unset;
}
.Button-Text,
.Button-Text:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.Button-Text[disabled],
.Button-Text:hover,
.Button-Text:active,
.Button-Text:focus-visible {
  border: none;
  background-color: transparent;
}
.Button-Text[disabled][disabled],
.Button-Text:hover[disabled],
.Button-Text:active[disabled],
.Button-Text:focus-visible[disabled] {
  background-color: transparent;
}
.Button-Text.Bold {
  font-weight: 700;
}
.Button-Text,
.Button-Text:focus {
  color: #0080a3;
}
.Button-Text:hover,
.Button-Text:focus-visible {
  color: #125a6e;
}
.Button-Text:active {
  color: #1c1b37;
}
.Button-Text[disabled] {
  color: #9e9eb7;
  cursor: auto;
}
.Link {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Link.Bold {
  font-weight: 700;
}
.Link,
.Link:focus {
  color: #0080a3;
}
.Link:hover,
.Link:focus-visible {
  color: #125a6e;
}
.Link:active {
  color: #1c1b37;
}
.FieldsetInput label {
  display: block;
  margin-bottom: 8px;
}
.FieldsetInput input,
.FieldsetInput select,
.FieldsetInput textarea {
  width: 100%;
  min-height: 38px;
  padding: 7px 13px;
  border: 1px solid #dcf1f8;
  border-radius: 24px;
  appearance: none;
  background-color: #dcf1f8;
  caret-color: #0080a3;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.FieldsetInput input:focus:not(:disabled),
.FieldsetInput select:focus:not(:disabled),
.FieldsetInput textarea:focus:not(:disabled),
.FieldsetInput input:hover:not(:disabled),
.FieldsetInput select:hover:not(:disabled),
.FieldsetInput textarea:hover:not(:disabled) {
  border-color: #23b8e1;
}
.FieldsetInput input:focus,
.FieldsetInput select:focus,
.FieldsetInput textarea:focus {
  box-shadow: none;
}
.FieldsetInput input:focus-visible,
.FieldsetInput select:focus-visible,
.FieldsetInput textarea:focus-visible {
  outline: none;
}
.FieldsetInput input[type="number"],
.FieldsetInput select[type="number"],
.FieldsetInput textarea[type="number"] {
  -moz-appearance: textfield;
}
.FieldsetInput input[type="number"]::-webkit-outer-spin-button,
.FieldsetInput select[type="number"]::-webkit-outer-spin-button,
.FieldsetInput textarea[type="number"]::-webkit-outer-spin-button,
.FieldsetInput input[type="number"]::-webkit-inner-spin-button,
.FieldsetInput select[type="number"]::-webkit-inner-spin-button,
.FieldsetInput textarea[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
.FieldsetInput input:-internal-autofill-selected,
.FieldsetInput select:-internal-autofill-selected,
.FieldsetInput textarea:-internal-autofill-selected,
.FieldsetInput input:-webkit-autofill,
.FieldsetInput select:-webkit-autofill,
.FieldsetInput textarea:-webkit-autofill,
.FieldsetInput input:-webkit-autofill:focus,
.FieldsetInput select:-webkit-autofill:focus,
.FieldsetInput textarea:-webkit-autofill:focus,
.FieldsetInput input:-webkit-autofill:hover,
.FieldsetInput select:-webkit-autofill:hover,
.FieldsetInput textarea:-webkit-autofill:hover,
.FieldsetInput input:-webkit-autofill:active,
.FieldsetInput select:-webkit-autofill:active,
.FieldsetInput textarea:-webkit-autofill:active {
  background-color: #dcf1f8 !important;
}
.FieldsetInput input:-webkit-autofill,
.FieldsetInput select:-webkit-autofill,
.FieldsetInput textarea:-webkit-autofill {
  background-color: #dcf1f8;
}
.FieldsetInput textarea {
  padding: 8px 16px;
  border-radius: 16px;
  resize: none;
}
input[type="radio"] {
  display: none;
}
input[type="radio"]:checked + span {
  font-weight: 700;
}
input[type="radio"]:checked + span:after {
  content: "";
  position: absolute;
  left: 5px;
  bottom: 6px;
  width: 14px;
  height: 14px;
  background-color: #23b8e1;
  border-radius: 50%;
}
input[type="radio"]:checked + span:before {
  border-color: #23b8e1;
}
input[type="radio"] + span {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  user-select: none;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
input[type="radio"] + span:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 24px;
  height: 24px;
  border: 1px solid #1c1b37;
  border-radius: 50%;
  transition: border-color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
input[type="radio"] + span:focus-visible,
input[type="radio"] + span:hover {
  color: #23b8e1;
}
input[type="radio"] + span:focus-visible:before,
input[type="radio"] + span:hover:before {
  border-color: #23b8e1;
}
.ErrorMessage {
  font-size: 12px;
  color: #ee3900;
}
.ProgressText {
  display: flex;
  gap: 4px;
}
.ProgressText .ant-image {
  width: 24px;
}
.ButtonPairWrapper {
  display: flex;
  gap: 8px;
}
.ButtonPairWrapper button:first-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.ButtonPairWrapper button:first-child,
.ButtonPairWrapper button:first-child:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.ButtonPairWrapper button:first-child:focus-visible,
.ButtonPairWrapper button:first-child:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.ButtonPairWrapper button:first-child:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.ButtonPairWrapper button:first-child[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.ButtonPairWrapper button:first-child[disabled]:hover,
.ButtonPairWrapper button:first-child[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.ButtonPairWrapper button:first-child span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.ButtonPairWrapper button:first-child span.Bold {
  font-weight: 700;
}
.ButtonPairWrapper button:first-child.DarkBackground,
.ButtonPairWrapper button:first-child.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.ButtonPairWrapper button:first-child.DarkBackground:focus-visible,
.ButtonPairWrapper button:first-child.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.ButtonPairWrapper button:first-child.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.ButtonPairWrapper button:first-child,
.ButtonPairWrapper button:first-child:focus {
  border-color: #0080a3;
  background-color: #ffffff;
  color: #0080a3;
}
.ButtonPairWrapper button:first-child:focus-visible,
.ButtonPairWrapper button:first-child:hover {
  border-color: #1c1b37;
  background-color: #ffffff;
  color: #1c1b37;
}
.ButtonPairWrapper button:first-child:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.ButtonPairWrapper button:first-child.Transparent {
  background-color: transparent;
}
.ButtonPairWrapper button:first-child.Transparent:hover,
.ButtonPairWrapper button:first-child.Transparent:focus-visible,
.ButtonPairWrapper button:first-child.Transparent:active {
  background-color: transparent;
}
.ButtonPairWrapper button:first-child[disabled] {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.ButtonPairWrapper button:first-child[disabled]:focus-visible,
.ButtonPairWrapper button:first-child[disabled]:hover,
.ButtonPairWrapper button:first-child[disabled]:active {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.ModalWrapper .ant-modal-body,
.ModalWrapper .ant-spin-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.ModalWrapper .ant-modal-body h2,
.ModalWrapper .ant-spin-container h2,
.ModalWrapper .ant-modal-body p,
.ModalWrapper .ant-spin-container p {
  text-align: center;
}
.GreenText {
  color: #3a825c;
}
.RedText {
  color: #9d2701;
}
.GreyText {
  color: #676783;
}
.OrangeText {
  color: #e87b17;
}
.CircleStatus {
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
}
.CircleStatus::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
}
.CircleStatus.Correct::before {
  background-color: #6dbd8f;
}
.CircleStatus.Wrong::before,
.CircleStatus.Bad::before {
  background-color: #ee3900;
}
.CircleStatus.Available::before {
  background-color: #3b73b9;
}
.CircleStatus.Wrong::after {
  content: "+";
  position: absolute;
  left: 3px;
  top: -1px;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  transform: rotate(45deg);
}
.CircleStatus.Correct.Confirmed {
  border: none;
}
.CircleStatus.Correct.Confirmed::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 8px;
  height: 5px;
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  transform: rotate(-45deg);
}
.TableWithTitle > h2,
.TableWithTitle > h3 {
  margin-bottom: -50px;
}
.CenteredSpinner:has(.ant-spin):not(:has(.Table)) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.TitleBlockWithButton {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
body {
  margin: 0;
  box-sizing: border-box;
  font-family: Lato, sans-serif;
  font-style: normal;
  background-color: #1c1b37;
}
body > #root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
}
body button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
body button,
body button:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
body button:focus-visible,
body button:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
body button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
body button[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
body button[disabled]:hover,
body button[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
body button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
body button span.Bold {
  font-weight: 700;
}
body button.DarkBackground,
body button.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
body button.DarkBackground:focus-visible,
body button.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
body button.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
body a {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
body a.Bold {
  font-weight: 700;
}
body a,
body a:focus {
  color: #0080a3;
}
body a:hover,
body a:focus-visible {
  color: #125a6e;
}
body a:active {
  color: #1c1b37;
}
.PageContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-top: 70px;
  padding: 16px 96px 42px 48px;
  z-index: 1;
}
@media only screen and (max-width: 1200px) {
  .PageContent {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media only screen and (max-width: 575px) {
  .PageContent {
    padding: 0px;
  }
}
.FastTab {
  min-height: 50vh;
  padding: 24px min(40px, 5%) 40px;
  background-color: #ffffff;
  border-radius: 16px;
}
.FastTab > h2,
.FastTab > h3 {
  margin-bottom: -50px;
}
.FastsLayout {
  padding: 94px 40px 130px;
  gap: 16px;
  color: #ffffff;
}
.FastsLayout-Header {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  height: 78px;
}
.FastsLayout-Body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 1120px;
  margin: 0 auto;
  text-align: center;
}
.FastsLayout-Body h1,
.FastsLayout-Body p {
  color: #ffffff;
}
.FastsLayout-Body > p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: 800;
}
.FastsLayout-Body-ActionButtons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 16px 0 24px;
  padding: 16px 0;
}
.FastsLayout-Body-ActionButtons button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.FastsLayout-Body-ActionButtons button,
.FastsLayout-Body-ActionButtons button:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.FastsLayout-Body-ActionButtons button:focus-visible,
.FastsLayout-Body-ActionButtons button:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.FastsLayout-Body-ActionButtons button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.FastsLayout-Body-ActionButtons button[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.FastsLayout-Body-ActionButtons button[disabled]:hover,
.FastsLayout-Body-ActionButtons button[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.FastsLayout-Body-ActionButtons button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.FastsLayout-Body-ActionButtons button span.Bold {
  font-weight: 700;
}
.FastsLayout-Body-ActionButtons button.DarkBackground,
.FastsLayout-Body-ActionButtons button.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.FastsLayout-Body-ActionButtons button.DarkBackground:focus-visible,
.FastsLayout-Body-ActionButtons button.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.FastsLayout-Body-ActionButtons button.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.FastsLayout-Body-ActionButtons button,
.FastsLayout-Body-ActionButtons button:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.FastsLayout-Body-ActionButtons button:focus-visible,
.FastsLayout-Body-ActionButtons button:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.FastsLayout-Body-ActionButtons button:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.FastsLayout-Body-Fasts {
  display: grid;
  grid-template-columns: 262px 262px 262px 262px;
  justify-content: center;
  grid-gap: 24px;
  margin-top: 54px;
  width: 100%;
}
.Header {
  position: fixed;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 16px 108px 16px 24px;
  background-color: #12112f;
  color: #ffffff;
  z-index: 2;
}
.Header .FastsDropdown {
  margin-top: 8px;
}
.Header > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Header > button,
.Header > button:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.Header > button:focus-visible,
.Header > button:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.Header > button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Header > button[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.Header > button[disabled]:hover,
.Header > button[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.Header > button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.Header > button span.Bold {
  font-weight: 700;
}
.Header > button.DarkBackground,
.Header > button.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.Header > button.DarkBackground:focus-visible,
.Header > button.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.Header > button.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.Header > button,
.Header > button:focus {
  border-color: #23b8e1;
  background-color: transparent;
  color: #23b8e1;
}
.Header > button:focus-visible,
.Header > button:hover {
  border-color: #9dcddd;
  background-color: transparent;
  color: #9dcddd;
}
.Header > button:active {
  border-color: #ffffff;
  background-color: #1c1b37;
  color: #ffffff;
}
.FastHeaderButtons {
  position: fixed;
  top: 16px;
  right: 108px;
  display: flex;
  gap: 8px;
  z-index: 2;
}
.FastWelcome {
  min-height: 100vh;
  margin-left: 25%;
}
.FastWelcome-Header {
  margin-top: 134px;
  font-size: min(80px, 9vw);
  line-height: min(94px, 9vw);
  color: #ffffff;
}
.FastWelcome-Row {
  color: #ffffff;
  margin-bottom: 3em;
}
.FastWelcome-Row-Title {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 32px 0 16px;
  color: #ffffff;
}
@media only screen and (max-width: 575px) {
  .FastWelcome {
    margin: 24px;
  }
  .FastWelcome-Row {
    display: flex;
    flex-direction: row;
  }
  .FastWelcome-Header {
    margin-top: 80px;
  }
}
.PageNotFound {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 160px min(320px, 15%) 3em;
}
.PageNotFound,
.PageNotFound h1,
.PageNotFound h3 {
  color: #ffffff;
}
.PageNotFound h1 {
  font-size: 80px;
  line-height: 96px;
}
.PageNotFound button {
  align-self: flex-start;
  margin-top: 16px;
}
.PageNotFound.WhiteLayout {
  padding-bottom: 160px;
  border-radius: 16px;
  background-color: #ffffff;
}
.PageNotFound.WhiteLayout,
.PageNotFound.WhiteLayout h1,
.PageNotFound.WhiteLayout h3 {
  color: #1c1b37;
}
.FastAbout {
  background-color: #ffffff;
  border-radius: 16px;
}
.FastAbout-Top {
  background: #eae9f2;
  padding: 24px 40px;
  border-radius: 16px 16px 0 0;
}
.FastAbout-ElaborateInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}
.FastAbout-ElaborateInfo i {
  color: #3b73b9;
}
.FastAbout-WarnIcon {
  color: #ffffff;
  background: #3b73b9;
  padding: 2px;
  border-radius: 100%;
}
.FastAbout-Border-Solid {
  border-top: 2px solid #7cd4a3;
  margin: 0;
  margin: 16px 0 24px 0;
}
.FastAbout-Logo {
  border-radius: 10px;
  width: 150px;
  height: 150px;
}
.FastAbout-Info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.FastAbout-Info-Name {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.FastAbout-Info .ant-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.FastAbout-Info .ant-btn,
.FastAbout-Info .ant-btn:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.FastAbout-Info .ant-btn:focus-visible,
.FastAbout-Info .ant-btn:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.FastAbout-Info .ant-btn:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.FastAbout-Info .ant-btn[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.FastAbout-Info .ant-btn[disabled]:hover,
.FastAbout-Info .ant-btn[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.FastAbout-Info .ant-btn span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.FastAbout-Info .ant-btn span.Bold {
  font-weight: 700;
}
.FastAbout-Info .ant-btn.DarkBackground,
.FastAbout-Info .ant-btn.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.FastAbout-Info .ant-btn.DarkBackground:focus-visible,
.FastAbout-Info .ant-btn.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.FastAbout-Info .ant-btn.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.FastAbout-Info .ant-btn,
.FastAbout-Info .ant-btn:focus {
  border-color: #0080a3;
  background-color: #ffffff;
  color: #0080a3;
}
.FastAbout-Info .ant-btn:focus-visible,
.FastAbout-Info .ant-btn:hover {
  border-color: #1c1b37;
  background-color: #ffffff;
  color: #1c1b37;
}
.FastAbout-Info .ant-btn:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.FastAbout-Info .ant-btn.Transparent {
  background-color: transparent;
}
.FastAbout-Info .ant-btn.Transparent:hover,
.FastAbout-Info .ant-btn.Transparent:focus-visible,
.FastAbout-Info .ant-btn.Transparent:active {
  background-color: transparent;
}
.FastAbout-Info .ant-btn[disabled] {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.FastAbout-Info .ant-btn[disabled]:focus-visible,
.FastAbout-Info .ant-btn[disabled]:hover,
.FastAbout-Info .ant-btn[disabled]:active {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.FastAbout-Index {
  display: flex;
  align-items: center;
  gap: 30px;
}
.FastAbout-Index h2 {
  color: #676783;
}
.FastAbout-Middle {
  padding: 24px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}
.FastAbout-Left {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.FastAbout-DataBoxes {
  display: flex;
  align-items: center;
  gap: 8px;
}
.FastAbout-Tags {
  display: flex;
  gap: 24px;
}
.FastAbout-ColumDiection,
.FastAbout-TagsWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.FastAbout-TagsWrapper div {
  display: flex;
  align-items: center;
  gap: 8px;
}
.FastAbout-TagsWrapper p {
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #eae9f2;
}
.FastAbout-CommunityTag {
  width: fit-content;
  margin: 0;
  padding: 5px 12px;
  border-radius: 25px;
  background-color: #ffffff;
}
.FastAbout-DocumentWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.FastAbout-DocumentWrapper button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.FastAbout-DocumentWrapper button,
.FastAbout-DocumentWrapper button:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.FastAbout-DocumentWrapper button:focus-visible,
.FastAbout-DocumentWrapper button:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.FastAbout-DocumentWrapper button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.FastAbout-DocumentWrapper button[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.FastAbout-DocumentWrapper button[disabled]:hover,
.FastAbout-DocumentWrapper button[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.FastAbout-DocumentWrapper button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.FastAbout-DocumentWrapper button span.Bold {
  font-weight: 700;
}
.FastAbout-DocumentWrapper button.DarkBackground,
.FastAbout-DocumentWrapper button.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.FastAbout-DocumentWrapper button.DarkBackground:focus-visible,
.FastAbout-DocumentWrapper button.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.FastAbout-DocumentWrapper button.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.FastAbout-DocumentWrapper button,
.FastAbout-DocumentWrapper button:focus {
  border-color: #0080a3;
  background-color: #ffffff;
  color: #0080a3;
}
.FastAbout-DocumentWrapper button:focus-visible,
.FastAbout-DocumentWrapper button:hover {
  border-color: #1c1b37;
  background-color: #ffffff;
  color: #1c1b37;
}
.FastAbout-DocumentWrapper button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.FastAbout-DocumentWrapper button.Transparent {
  background-color: transparent;
}
.FastAbout-DocumentWrapper button.Transparent:hover,
.FastAbout-DocumentWrapper button.Transparent:focus-visible,
.FastAbout-DocumentWrapper button.Transparent:active {
  background-color: transparent;
}
.FastAbout-DocumentWrapper button[disabled] {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.FastAbout-DocumentWrapper button[disabled]:focus-visible,
.FastAbout-DocumentWrapper button[disabled]:hover,
.FastAbout-DocumentWrapper button[disabled]:active {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.FastAbout-DocumentWrapper a {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 16px;
}
.FastAbout-Divider {
  padding: 0px 40px;
}
.FastAbout-Divider hr {
  border-top: 2px solid #7cd4a3;
  width: 100%;
}
.FastAbout-TeamMembers {
  display: flex;
  align-items: center;
  gap: 20px;
}
.FastAbout h4 {
  font-weight: 800;
}
.FastAbout-Empty {
  padding: 30px 0;
}
.FastAbout-Copy {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 7px 55px 7px 15px;
  border-radius: 24px;
  background: #dcf1f8;
}
.FastAbout-Copy i {
  color: #676783;
}
.FastAbout-Copy span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.FastAbout-Copy button {
  position: absolute;
  right: 15px;
}
.FastAbout-ShareModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}
.FastAbout-ShareModal p {
  color: #676783;
}
.FastAbout-ShareModal .ant-row {
  gap: 24px;
}
.FastAbout-InviteModal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.FastAbout-InviteModal .FastPlaque {
  display: flex;
  gap: 16px;
}
.FastAbout-InviteModal .FastPlaque img {
  width: 64px;
  height: 64px;
  border-radius: 16px;
}
.FastEdit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 40px 44px 27px;
  border-radius: 16px;
  background-color: #ffffff;
}
.FastEdit-TitleBlock {
  display: flex;
  justify-content: space-between;
  margin: 0 70px 0 13px;
}
.FastEdit .rjsf {
  position: relative;
}
.FastEdit-SubmitButton {
  position: absolute;
  top: -56px;
  right: 0;
}
.FastEdit #root > .form-group {
  margin-bottom: 8px;
  padding: 32px 8px 40px 40px;
  border-radius: 16px;
  background: #eae9f2;
}
.FastEdit #root > .form-group > label {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.FastEdit .array-item-move-up,
.FastEdit .array-item-move-down {
  display: none;
}
.FastEdit legend {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
}
.FastEdit p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.FastEdit p.Bold {
  font-weight: 700;
}
.FastEdit .field-description {
  margin-bottom: 24px;
}
.FastEdit-Logo span {
  display: flex;
  align-items: center;
  gap: 32px;
  width: fit-content;
}
.FastEdit-Logo span label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.FastEdit-Logo span label.Bold {
  font-weight: 700;
}
.FastEdit-Logo span label,
.FastEdit-Logo span label:focus {
  color: #0080a3;
}
.FastEdit-Logo span label:hover,
.FastEdit-Logo span label:focus-visible {
  color: #125a6e;
}
.FastEdit-Logo span label:active {
  color: #1c1b37;
}
.FastEdit-Logo .ant-image-mask {
  border-radius: 10px;
}
.FastEdit-Radio > label {
  display: block;
  margin-bottom: 8px;
}
.FastEdit-Radio .field-radio-group {
  display: flex;
  gap: 24px;
  margin-top: 24px;
}
.FastEdit .form-group {
  position: relative;
}
.FastEdit .form-group label {
  display: block;
  margin-bottom: 8px;
}
.FastEdit .form-group input,
.FastEdit .form-group select,
.FastEdit .form-group textarea {
  width: 100%;
  min-height: 38px;
  padding: 7px 13px;
  border: 1px solid #dcf1f8;
  border-radius: 24px;
  appearance: none;
  background-color: #dcf1f8;
  caret-color: #0080a3;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.FastEdit .form-group input:focus:not(:disabled),
.FastEdit .form-group select:focus:not(:disabled),
.FastEdit .form-group textarea:focus:not(:disabled),
.FastEdit .form-group input:hover:not(:disabled),
.FastEdit .form-group select:hover:not(:disabled),
.FastEdit .form-group textarea:hover:not(:disabled) {
  border-color: #23b8e1;
}
.FastEdit .form-group input:focus,
.FastEdit .form-group select:focus,
.FastEdit .form-group textarea:focus {
  box-shadow: none;
}
.FastEdit .form-group input:focus-visible,
.FastEdit .form-group select:focus-visible,
.FastEdit .form-group textarea:focus-visible {
  outline: none;
}
.FastEdit .form-group input[type="number"],
.FastEdit .form-group select[type="number"],
.FastEdit .form-group textarea[type="number"] {
  -moz-appearance: textfield;
}
.FastEdit .form-group input[type="number"]::-webkit-outer-spin-button,
.FastEdit .form-group select[type="number"]::-webkit-outer-spin-button,
.FastEdit .form-group textarea[type="number"]::-webkit-outer-spin-button,
.FastEdit .form-group input[type="number"]::-webkit-inner-spin-button,
.FastEdit .form-group select[type="number"]::-webkit-inner-spin-button,
.FastEdit .form-group textarea[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
.FastEdit .form-group input:-internal-autofill-selected,
.FastEdit .form-group select:-internal-autofill-selected,
.FastEdit .form-group textarea:-internal-autofill-selected,
.FastEdit .form-group input:-webkit-autofill,
.FastEdit .form-group select:-webkit-autofill,
.FastEdit .form-group textarea:-webkit-autofill,
.FastEdit .form-group input:-webkit-autofill:focus,
.FastEdit .form-group select:-webkit-autofill:focus,
.FastEdit .form-group textarea:-webkit-autofill:focus,
.FastEdit .form-group input:-webkit-autofill:hover,
.FastEdit .form-group select:-webkit-autofill:hover,
.FastEdit .form-group textarea:-webkit-autofill:hover,
.FastEdit .form-group input:-webkit-autofill:active,
.FastEdit .form-group select:-webkit-autofill:active,
.FastEdit .form-group textarea:-webkit-autofill:active {
  background-color: #dcf1f8 !important;
}
.FastEdit .form-group input:-webkit-autofill,
.FastEdit .form-group select:-webkit-autofill,
.FastEdit .form-group textarea:-webkit-autofill {
  background-color: #dcf1f8;
}
.FastEdit .form-group textarea {
  padding: 8px 16px;
  border-radius: 16px;
  resize: none;
}
.FastEdit .form-group input,
.FastEdit .form-group select,
.FastEdit .form-group textarea {
  background-color: #ffffff;
  border-color: #ffffff;
  outline: none;
}
.FastEdit .form-group textarea {
  border-radius: 16px;
}
.FastEdit-Select,
.FastEdit-MultipleSelect {
  position: relative;
  width: 100%;
}
.FastEdit-Select.FastEdit-Select .ant-select-selector,
.FastEdit-Select.FastEdit-MultipleSelect .ant-select-selector,
.FastEdit-MultipleSelect.FastEdit-Select .ant-select-selector,
.FastEdit-MultipleSelect.FastEdit-MultipleSelect .ant-select-selector {
  padding: 4px 16px;
  border: 1px solid #ffffff;
  border-radius: 24px;
}
.FastEdit-Select.FastEdit-Select .ant-select-selector:focus,
.FastEdit-Select.FastEdit-MultipleSelect .ant-select-selector:focus,
.FastEdit-MultipleSelect.FastEdit-Select .ant-select-selector:focus,
.FastEdit-MultipleSelect.FastEdit-MultipleSelect .ant-select-selector:focus,
.FastEdit-Select.FastEdit-Select .ant-select-selector:hover,
.FastEdit-Select.FastEdit-MultipleSelect .ant-select-selector:hover,
.FastEdit-MultipleSelect.FastEdit-Select .ant-select-selector:hover,
.FastEdit-MultipleSelect.FastEdit-MultipleSelect .ant-select-selector:hover {
  border-color: #23b8e1;
}
.FastEdit-Select.FastEdit-Select .ant-select-selector .ant-select-selection-item,
.FastEdit-Select.FastEdit-MultipleSelect .ant-select-selector .ant-select-selection-item,
.FastEdit-MultipleSelect.FastEdit-Select .ant-select-selector .ant-select-selection-item,
.FastEdit-MultipleSelect.FastEdit-MultipleSelect .ant-select-selector .ant-select-selection-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
  padding: 8px 12px;
  border-radius: 24px;
  background-color: #eae9f2;
}
.FastEdit-Select.FastEdit-Select .ant-select-selector .ant-select-selection-item-remove,
.FastEdit-Select.FastEdit-MultipleSelect .ant-select-selector .ant-select-selection-item-remove,
.FastEdit-MultipleSelect.FastEdit-Select .ant-select-selector .ant-select-selection-item-remove,
.FastEdit-MultipleSelect.FastEdit-MultipleSelect .ant-select-selector .ant-select-selection-item-remove {
  position: relative;
  font-size: 14px;
}
.FastEdit-Select.FastEdit-Select .ant-select-selector .ant-select-selection-item-remove::before,
.FastEdit-Select.FastEdit-MultipleSelect .ant-select-selector .ant-select-selection-item-remove::before,
.FastEdit-MultipleSelect.FastEdit-Select .ant-select-selector .ant-select-selection-item-remove::before,
.FastEdit-MultipleSelect.FastEdit-MultipleSelect .ant-select-selector .ant-select-selection-item-remove::before {
  content: "";
  position: absolute;
  top: 0;
  right: -5px;
  width: 1px;
  height: 16px;
  border: 1px solid #676783;
}
.FastEdit-Select.FastEdit-Select .ant-select-selector input,
.FastEdit-Select.FastEdit-MultipleSelect .ant-select-selector input,
.FastEdit-MultipleSelect.FastEdit-Select .ant-select-selector input,
.FastEdit-MultipleSelect.FastEdit-MultipleSelect .ant-select-selector input {
  min-height: 24px;
}
.FastEdit-Select.FastEdit-Select .ant-select-selector input:focus,
.FastEdit-Select.FastEdit-MultipleSelect .ant-select-selector input:focus,
.FastEdit-MultipleSelect.FastEdit-Select .ant-select-selector input:focus,
.FastEdit-MultipleSelect.FastEdit-MultipleSelect .ant-select-selector input:focus {
  box-shadow: none;
}
.FastEdit-Select.FastEdit-Select .ant-select-selector {
  height: 38px;
}
.FastEdit-Select.FastEdit-Select .ant-select-selector .ant-select-selection-item {
  background: none;
}
.FastEdit-Select.FastEdit-Select .ant-select-selector .ant-select-selection-placeholder {
  line-height: 24px;
}
.FastEdit-Textarea {
  position: relative;
}
.FastEdit-Textarea.ant-input-textarea-show-count::after {
  position: absolute;
  right: 16px;
  bottom: 8px;
}
.FastEdit-Documents .array-item,
.FastEdit-Links .array-item,
.FastEdit-TeamMembers .array-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 32px;
}
.FastEdit-Documents .array-item > div:first-child,
.FastEdit-Links .array-item > div:first-child,
.FastEdit-TeamMembers .array-item > div:first-child {
  width: 100%;
}
.FastEdit-Documents .array-item fieldset,
.FastEdit-Links .array-item fieldset,
.FastEdit-TeamMembers .array-item fieldset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-right: 48px;
}
.FastEdit-Documents .array-item fieldset .form-group,
.FastEdit-Links .array-item fieldset .form-group,
.FastEdit-TeamMembers .array-item fieldset .form-group {
  width: 100%;
}
.FastEdit-Documents .array-item fieldset .form-group:first-child,
.FastEdit-Links .array-item fieldset .form-group:first-child,
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child {
  width: 60%;
}
.FastEdit-Documents .array-item fieldset .form-group:first-child span,
.FastEdit-Links .array-item fieldset .form-group:first-child span,
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 24px;
}
.FastEdit-Documents .array-item fieldset .form-group:first-child span label,
.FastEdit-Links .array-item fieldset .form-group:first-child span label,
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  margin-bottom: 0;
}
.FastEdit-Documents .array-item fieldset .form-group:first-child span label.Bold,
.FastEdit-Links .array-item fieldset .form-group:first-child span label.Bold,
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span label.Bold {
  font-weight: 700;
}
.FastEdit-Documents .array-item fieldset .form-group:first-child span label,
.FastEdit-Links .array-item fieldset .form-group:first-child span label,
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span label,
.FastEdit-Documents .array-item fieldset .form-group:first-child span label:focus,
.FastEdit-Links .array-item fieldset .form-group:first-child span label:focus,
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span label:focus {
  color: #0080a3;
}
.FastEdit-Documents .array-item fieldset .form-group:first-child span label:hover,
.FastEdit-Links .array-item fieldset .form-group:first-child span label:hover,
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span label:hover,
.FastEdit-Documents .array-item fieldset .form-group:first-child span label:focus-visible,
.FastEdit-Links .array-item fieldset .form-group:first-child span label:focus-visible,
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span label:focus-visible {
  color: #125a6e;
}
.FastEdit-Documents .array-item fieldset .form-group:first-child span label:active,
.FastEdit-Links .array-item fieldset .form-group:first-child span label:active,
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span label:active {
  color: #1c1b37;
}
.FastEdit-Documents .array-item .control-label,
.FastEdit-Links .array-item .control-label,
.FastEdit-TeamMembers .array-item .control-label {
  display: none;
}
.FastEdit-Documents .array-item .error-detail,
.FastEdit-Links .array-item .error-detail,
.FastEdit-TeamMembers .array-item .error-detail {
  position: absolute;
  top: 100%;
  left: 10px;
  margin: 0;
  padding: 0;
}
.FastEdit-Documents .array-item:first-child fieldset .form-group:first-child span:not(.anticon),
.FastEdit-Links .array-item:first-child fieldset .form-group:first-child span:not(.anticon),
.FastEdit-TeamMembers .array-item:first-child fieldset .form-group:first-child span:not(.anticon) {
  margin-top: 24px;
}
.FastEdit-Documents .array-item:first-child .control-label,
.FastEdit-Links .array-item:first-child .control-label,
.FastEdit-TeamMembers .array-item:first-child .control-label {
  display: block;
  text-transform: uppercase;
  color: #676783;
}
.FastEdit-Links .array-item fieldset .form-group {
  width: 60%;
}
.FastEdit-Links .array-item fieldset .form-group:first-child {
  width: 100%;
}
.FastEdit-Links .array-item fieldset .form-group:first-child span {
  display: inline;
}
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span {
  flex-direction: row;
  align-items: center;
}
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span .ant-image-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span .ant-image-mask {
  border-radius: 50%;
}
.FastEdit-TeamMembers .array-item fieldset .form-group:first-child span .ant-image-mask .anticon {
  margin-inline-end: 0;
}
.FastEdit .array-item-add,
.FastEdit-RemoveButton,
.FastEdit-AddButton {
  border: none;
  background: none;
}
.FastEdit .array-item-add:focus,
.FastEdit-RemoveButton:focus,
.FastEdit-AddButton:focus,
.FastEdit .array-item-add:hover,
.FastEdit-RemoveButton:hover,
.FastEdit-AddButton:hover,
.FastEdit .array-item-add:active,
.FastEdit-RemoveButton:active,
.FastEdit-AddButton:active {
  border: none;
  background: none;
}
.FastEdit-RemoveButton,
.FastEdit-AddButton {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.FastEdit-RemoveButton.Bold,
.FastEdit-AddButton.Bold {
  font-weight: 700;
}
.FastEdit-RemoveButton,
.FastEdit-AddButton,
.FastEdit-RemoveButton:focus,
.FastEdit-AddButton:focus {
  color: #0080a3;
}
.FastEdit-RemoveButton:hover,
.FastEdit-AddButton:hover,
.FastEdit-RemoveButton:focus-visible,
.FastEdit-AddButton:focus-visible {
  color: #125a6e;
}
.FastEdit-RemoveButton:active,
.FastEdit-AddButton:active {
  color: #1c1b37;
}
.FastEdit-AddButton {
  margin-top: 24px;
  padding: 0;
}
.FastEdit .ant-image-mask-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Community:has(.ant-spin):not(:has(.Table)) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Community-Top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}
.Community-Top button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Community-Top button,
.Community-Top button:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.Community-Top button:focus-visible,
.Community-Top button:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.Community-Top button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Community-Top button[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.Community-Top button[disabled]:hover,
.Community-Top button[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.Community-Top button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.Community-Top button span.Bold {
  font-weight: 700;
}
.Community-Top button.DarkBackground,
.Community-Top button.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.Community-Top button.DarkBackground:focus-visible,
.Community-Top button.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.Community-Top button.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.Community-Top button,
.Community-Top button:focus {
  border-color: #0080a3;
  background-color: #ffffff;
  color: #0080a3;
}
.Community-Top button:focus-visible,
.Community-Top button:hover {
  border-color: #1c1b37;
  background-color: #ffffff;
  color: #1c1b37;
}
.Community-Top button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Community-Top button.Transparent {
  background-color: transparent;
}
.Community-Top button.Transparent:hover,
.Community-Top button.Transparent:focus-visible,
.Community-Top button.Transparent:active {
  background-color: transparent;
}
.Community-Top button[disabled] {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.Community-Top button[disabled]:focus-visible,
.Community-Top button[disabled]:hover,
.Community-Top button[disabled]:active {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.Community table .ant-table-thead > tr > th:last-child,
.Community table .ant-table-tbody > tr > td:last-child:not(:first-child) {
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
  text-align: end;
}
.Community table td {
  min-height: 71px;
}
.Community table .GreenText {
  display: flex;
  align-items: center;
}
.Community table .StatusColumn-Rejected {
  display: flex;
  align-items: center;
  gap: 16px;
}
.Community table .StatusColumn-Rejected button,
.Community table .StatusColumn-Rejected button:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.Community table .StatusColumn-Rejected button[disabled],
.Community table .StatusColumn-Rejected button:hover,
.Community table .StatusColumn-Rejected button:active,
.Community table .StatusColumn-Rejected button:focus-visible {
  border: none;
  background-color: transparent;
}
.Community table .StatusColumn-Rejected button[disabled][disabled],
.Community table .StatusColumn-Rejected button:hover[disabled],
.Community table .StatusColumn-Rejected button:active[disabled],
.Community table .StatusColumn-Rejected button:focus-visible[disabled] {
  background-color: transparent;
}
.Community-AddAddress .ant-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Community-AddAddress .ant-form .ant-form-item {
  margin-bottom: 0;
}
.Community .ant-table-empty {
  margin-top: 50px;
}
.Community .AlternativePagination {
  margin-top: 16px;
}
.StatusColumn-Overlay {
  max-width: 440px;
}
.Wallet section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Wallet p span {
  display: inline-block;
  margin-left: 4px;
}
.Wallet > .ant-row {
  flex-direction: column;
}
.Wallet-Form {
  margin: 8px 0 56px;
}
.Wallet-Form.Wallet-Form .ant-form-item {
  margin-bottom: 16px;
}
.Wallet-Form.Wallet-Form .ant-form-item:hover .ant-select .ant-select-selector,
.Wallet-Form.Wallet-Form .ant-form-item:focus-within .ant-select .ant-select-selector {
  border-color: #23b8e1 !important;
}
.Wallet-Form .ant-form-item-explain-error {
  margin-bottom: 8px;
}
.Onboarding {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  padding: 48px;
}
.Onboarding-Title {
  display: flex;
  align-items: flex-end;
  letter-spacing: 3px;
  color: #ffffff;
}
.Onboarding-Title .ant-image {
  margin: 0 8px 2px 0;
}
.Onboarding-Title .ant-image img {
  height: 36px;
}
.Onboarding-Body {
  display: grid;
  grid-template-columns: 262px 1fr 262px;
  z-index: 1;
}
.Onboarding .Steps {
  width: 262px;
  gap: 16px;
  color: #ffffff;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item {
  flex: unset;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item .ant-steps-item-container {
  display: flex;
  align-items: center;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item .ant-steps-item-container .ant-steps-item-tail {
  display: none;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item .ant-steps-item-container .ant-steps-item-icon {
  width: 16px;
  height: 16px;
  margin-top: 0;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item .ant-steps-item-container .ant-steps-icon-dot {
  background-color: #ffffff;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item .ant-steps-item-container .ant-steps-item-content {
  min-height: auto;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item .ant-steps-item-container .ant-steps-item-title {
  font-weight: 700;
  color: #ffffff;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item.ant-steps-item-finish .ant-steps-icon-dot {
  background-color: #9e9eb7;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item.ant-steps-item-finish .ant-steps-item-title {
  color: #9e9eb7;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item.ant-steps-item-active .ant-steps-icon-dot {
  background-color: #7cd4a3;
}
.Onboarding .Steps.ant-steps-vertical .ant-steps-item.ant-steps-item-active .ant-steps-item-title {
  color: #7cd4a3;
}
.Onboarding-Form {
  width: 760px;
  border-radius: 16px;
  background-color: #ffffff;
  z-index: 1;
}
.Onboarding-Form:not(.FastOnboarding-Form) {
  align-self: flex-start;
}
.Onboarding-Form .Padding {
  padding: 40px 40px 76px;
}
.Onboarding-Form legend {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
}
.Onboarding-Form .form-group label {
  display: block;
  margin-bottom: 8px;
}
.Onboarding-Form .form-group input,
.Onboarding-Form .form-group select,
.Onboarding-Form .form-group textarea {
  width: 100%;
  min-height: 38px;
  padding: 7px 13px;
  border: 1px solid #dcf1f8;
  border-radius: 24px;
  appearance: none;
  background-color: #dcf1f8;
  caret-color: #0080a3;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Onboarding-Form .form-group input:focus:not(:disabled),
.Onboarding-Form .form-group select:focus:not(:disabled),
.Onboarding-Form .form-group textarea:focus:not(:disabled),
.Onboarding-Form .form-group input:hover:not(:disabled),
.Onboarding-Form .form-group select:hover:not(:disabled),
.Onboarding-Form .form-group textarea:hover:not(:disabled) {
  border-color: #23b8e1;
}
.Onboarding-Form .form-group input:focus,
.Onboarding-Form .form-group select:focus,
.Onboarding-Form .form-group textarea:focus {
  box-shadow: none;
}
.Onboarding-Form .form-group input:focus-visible,
.Onboarding-Form .form-group select:focus-visible,
.Onboarding-Form .form-group textarea:focus-visible {
  outline: none;
}
.Onboarding-Form .form-group input[type="number"],
.Onboarding-Form .form-group select[type="number"],
.Onboarding-Form .form-group textarea[type="number"] {
  -moz-appearance: textfield;
}
.Onboarding-Form .form-group input[type="number"]::-webkit-outer-spin-button,
.Onboarding-Form .form-group select[type="number"]::-webkit-outer-spin-button,
.Onboarding-Form .form-group textarea[type="number"]::-webkit-outer-spin-button,
.Onboarding-Form .form-group input[type="number"]::-webkit-inner-spin-button,
.Onboarding-Form .form-group select[type="number"]::-webkit-inner-spin-button,
.Onboarding-Form .form-group textarea[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
.Onboarding-Form .form-group input:-internal-autofill-selected,
.Onboarding-Form .form-group select:-internal-autofill-selected,
.Onboarding-Form .form-group textarea:-internal-autofill-selected,
.Onboarding-Form .form-group input:-webkit-autofill,
.Onboarding-Form .form-group select:-webkit-autofill,
.Onboarding-Form .form-group textarea:-webkit-autofill,
.Onboarding-Form .form-group input:-webkit-autofill:focus,
.Onboarding-Form .form-group select:-webkit-autofill:focus,
.Onboarding-Form .form-group textarea:-webkit-autofill:focus,
.Onboarding-Form .form-group input:-webkit-autofill:hover,
.Onboarding-Form .form-group select:-webkit-autofill:hover,
.Onboarding-Form .form-group textarea:-webkit-autofill:hover,
.Onboarding-Form .form-group input:-webkit-autofill:active,
.Onboarding-Form .form-group select:-webkit-autofill:active,
.Onboarding-Form .form-group textarea:-webkit-autofill:active {
  background-color: #dcf1f8 !important;
}
.Onboarding-Form .form-group input:-webkit-autofill,
.Onboarding-Form .form-group select:-webkit-autofill,
.Onboarding-Form .form-group textarea:-webkit-autofill {
  background-color: #dcf1f8;
}
.Onboarding-Form .form-group textarea {
  padding: 8px 16px;
  border-radius: 16px;
  resize: none;
}
.Onboarding-Info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 262px;
  width: fit-content;
  margin-left: 40px;
}
.Onboarding-Info p {
  width: 262px;
  color: #9e9eb7;
}
.Onboarding-Fasts {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.Onboarding-Block {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  background-color: #31304b;
  border-radius: 16px;
}
.Onboarding-Block img {
  width: 46px;
  height: 46px;
  margin-right: 11px;
  border-radius: 4px;
}
.Onboarding-Block h4 {
  color: #ffffff;
}
.Onboarding-Block h4:last-child {
  color: #9e9eb7;
}
.Onboarding .Details .field-string {
  position: relative;
  display: inline-block;
  width: calc(50% - 4px);
  margin-bottom: 32px;
}
.Onboarding .Details .field-string:nth-child(3) {
  margin-right: 8px;
}
.Onboarding .Details .error-detail {
  position: absolute;
  top: 100%;
  left: 16px;
  width: max-content;
  margin: 0;
  padding: 0;
}
.Onboarding .Introduction {
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  align-items: center;
  padding: 32px 40px 72px;
}
.Onboarding .Introduction h4 {
  font-weight: 700;
}
.Onboarding .Introduction form,
.Onboarding .Introduction p {
  width: 424px;
}
.Onboarding .Introduction .CurveBackgroundMini {
  position: absolute;
  right: 0;
  bottom: -1px;
  user-select: none;
  -webkit-user-drag: none;
}
.Onboarding .Introduction form,
.Onboarding .EmailVerification form,
.Onboarding .ConnectWallet form {
  display: flex;
  align-items: flex-end;
  gap: 32px;
  z-index: 1;
}
.Onboarding .Introduction form .ant-form-item,
.Onboarding .EmailVerification form .ant-form-item,
.Onboarding .ConnectWallet form .ant-form-item {
  margin-bottom: 0;
}
.Onboarding .Introduction form .ant-form-item .ant-form-item-explain-error,
.Onboarding .EmailVerification form .ant-form-item .ant-form-item-explain-error,
.Onboarding .ConnectWallet form .ant-form-item .ant-form-item-explain-error {
  position: absolute;
  top: calc(100% + 4px);
}
.Onboarding .Introduction form .ant-form-item input,
.Onboarding .EmailVerification form .ant-form-item input,
.Onboarding .ConnectWallet form .ant-form-item input {
  width: 300px;
}
.Onboarding .WhyFast {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 72px 16px;
  background-color: #3b73b9;
  border-radius: 0 0 16px 16px;
}
.Onboarding .WhyFast p,
.Onboarding .WhyFast h2,
.Onboarding .WhyFast h3,
.Onboarding .WhyFast h4 {
  text-align: center;
  color: #ffffff;
}
.Onboarding .WhyFast h2 {
  margin-bottom: 16px;
}
.Onboarding .WhyFast p {
  max-width: 560px;
}
.Onboarding .WhyFast h3 {
  max-width: 480px;
}
.Onboarding .WhyFast a {
  margin-bottom: 32px;
  color: #9dcddd;
}
.Onboarding .WhyFast a:hover {
  color: #dcf1f8;
}
.Onboarding .WhyFast-Areas {
  display: flex;
}
.Onboarding .WhyFast-Area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
}
.Onboarding .WhyFast-Area h4,
.Onboarding .WhyFast-Area ul {
  opacity: 0.7;
}
.Onboarding .WhyFast-Area ul {
  margin-bottom: 0;
  color: #ffffff;
}
.Onboarding .WhyFast-Area p {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 20px 0 40px;
  border: 1px solid #ffffff;
  border-radius: 50%;
}
.Onboarding .WhyFast-Area p::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 18px;
  top: -18px;
  right: calc(50% - 1px);
  background-color: #ffffff;
}
.Onboarding .WhyFast-Area p::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 30px;
  bottom: -30px;
  right: calc(50% - 1px);
  background-color: #ffffff;
}
.Onboarding .WhyFast-Area span {
  font-weight: 700;
}
.Onboarding .Confirmation {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 132px 106px;
  z-index: 1;
}
.Onboarding .Confirmation-Process {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.Onboarding .Confirmation-Process .ant-spin {
  align-self: center;
  margin-bottom: 12px;
}
.Onboarding .Confirmation-Process h2 {
  max-width: 260px;
}
.Onboarding .Confirmation-Process > p {
  margin-top: 8px;
}
.Onboarding .Confirmation .ant-image {
  align-self: center;
  margin-bottom: 16px;
}
.Onboarding .Confirmation h2 {
  align-self: center;
  text-align: center;
}
.Onboarding .Confirmation p:first-of-type,
.Onboarding .Confirmation p:last-of-type {
  margin: 32px 0 8px;
}
.Onboarding .Confirmation ul {
  padding-left: 12px;
}
.Onboarding .Documentation {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.Onboarding .Documentation .ant-checkbox-group {
  width: 100%;
}
.Onboarding .Documentation p + h3 {
  color: #e87b17;
}
.Onboarding .Documentation .OnboardDocument {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 32px;
  border-radius: 16px;
  background-color: #eae9f2;
}
.Onboarding .Documentation .OnboardDocument h3 {
  font-weight: 700;
  color: #676783;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 220px;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a,
.Onboarding .Documentation .OnboardDocument-ContolPanel button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a,
.Onboarding .Documentation .OnboardDocument-ContolPanel button,
.Onboarding .Documentation .OnboardDocument-ContolPanel a:focus,
.Onboarding .Documentation .OnboardDocument-ContolPanel button:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a:focus-visible,
.Onboarding .Documentation .OnboardDocument-ContolPanel button:focus-visible,
.Onboarding .Documentation .OnboardDocument-ContolPanel a:hover,
.Onboarding .Documentation .OnboardDocument-ContolPanel button:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a:active,
.Onboarding .Documentation .OnboardDocument-ContolPanel button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a[disabled],
.Onboarding .Documentation .OnboardDocument-ContolPanel button[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a[disabled]:hover,
.Onboarding .Documentation .OnboardDocument-ContolPanel button[disabled]:hover,
.Onboarding .Documentation .OnboardDocument-ContolPanel a[disabled]:active,
.Onboarding .Documentation .OnboardDocument-ContolPanel button[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a span,
.Onboarding .Documentation .OnboardDocument-ContolPanel button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a span.Bold,
.Onboarding .Documentation .OnboardDocument-ContolPanel button span.Bold {
  font-weight: 700;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a.DarkBackground,
.Onboarding .Documentation .OnboardDocument-ContolPanel button.DarkBackground,
.Onboarding .Documentation .OnboardDocument-ContolPanel a.DarkBackground:focus,
.Onboarding .Documentation .OnboardDocument-ContolPanel button.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a.DarkBackground:focus-visible,
.Onboarding .Documentation .OnboardDocument-ContolPanel button.DarkBackground:focus-visible,
.Onboarding .Documentation .OnboardDocument-ContolPanel a.DarkBackground:hover,
.Onboarding .Documentation .OnboardDocument-ContolPanel button.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a.DarkBackground:active,
.Onboarding .Documentation .OnboardDocument-ContolPanel button.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a,
.Onboarding .Documentation .OnboardDocument-ContolPanel button,
.Onboarding .Documentation .OnboardDocument-ContolPanel a:focus,
.Onboarding .Documentation .OnboardDocument-ContolPanel button:focus {
  border-color: #0080a3;
  background-color: #ffffff;
  color: #0080a3;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a:focus-visible,
.Onboarding .Documentation .OnboardDocument-ContolPanel button:focus-visible,
.Onboarding .Documentation .OnboardDocument-ContolPanel a:hover,
.Onboarding .Documentation .OnboardDocument-ContolPanel button:hover {
  border-color: #1c1b37;
  background-color: #ffffff;
  color: #1c1b37;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a:active,
.Onboarding .Documentation .OnboardDocument-ContolPanel button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a.Transparent,
.Onboarding .Documentation .OnboardDocument-ContolPanel button.Transparent {
  background-color: transparent;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a.Transparent:hover,
.Onboarding .Documentation .OnboardDocument-ContolPanel button.Transparent:hover,
.Onboarding .Documentation .OnboardDocument-ContolPanel a.Transparent:focus-visible,
.Onboarding .Documentation .OnboardDocument-ContolPanel button.Transparent:focus-visible,
.Onboarding .Documentation .OnboardDocument-ContolPanel a.Transparent:active,
.Onboarding .Documentation .OnboardDocument-ContolPanel button.Transparent:active {
  background-color: transparent;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a[disabled],
.Onboarding .Documentation .OnboardDocument-ContolPanel button[disabled] {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel a[disabled]:focus-visible,
.Onboarding .Documentation .OnboardDocument-ContolPanel button[disabled]:focus-visible,
.Onboarding .Documentation .OnboardDocument-ContolPanel a[disabled]:hover,
.Onboarding .Documentation .OnboardDocument-ContolPanel button[disabled]:hover,
.Onboarding .Documentation .OnboardDocument-ContolPanel a[disabled]:active,
.Onboarding .Documentation .OnboardDocument-ContolPanel button[disabled]:active {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel .ant-form-item {
  margin-bottom: 0;
}
.Onboarding .Documentation .OnboardDocument-ContolPanel label {
  align-items: center;
}
.Onboarding .Documentation button {
  align-self: flex-start;
  margin-top: 20px;
}
.Onboarding .Documentation button > span {
  display: flex;
  gap: 8px;
}
.Onboarding .Documentation button p {
  color: #f0f0f6;
}
.Onboarding .SpecificFastDocumentation {
  align-items: center;
}
.Onboarding .SpecificFastDocumentation > h4 {
  font-weight: 700;
  text-align: center;
}
.Onboarding .SpecificFastDocumentation .FastInfo {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 16px 0;
  border-top: 2px solid #7cd4a3;
  border-bottom: 2px solid #7cd4a3;
}
.Onboarding .SpecificFastDocumentation .FastInfo img {
  width: 73px;
  height: 73px;
  margin-right: 11px;
  border-radius: 16px;
}
.Onboarding .SpecificFastDocumentation .FastInfo h2:last-child {
  color: #676783;
}
.Onboarding .SpecificFastDocumentation-Form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.Onboarding .SpecificFastDocumentation-Form > p:first-of-type {
  font-size: 20px;
  line-height: 32px;
}
.Onboarding .SpecificFastDocumentation-Form button {
  margin: 0 0 24px;
  align-self: flex-start;
}
.Onboarding .SpecificFastDocumentation-Divider {
  padding-top: 32px;
  background-image: linear-gradient(to right, #1c1b37 25%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}
.Onboarding .SpecificFastDocumentation-IsSigned p {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
}
.Onboarding .ExistingEmail {
  position: relative;
  padding: 40px 82px;
}
.Onboarding .ExistingEmail .ant-image {
  position: absolute;
  top: 40px;
  left: 38px;
}
.Onboarding .ExistingEmail h2 {
  margin-bottom: 4px;
  color: #e87b17;
}
.Onboarding .ExistingEmail h4 {
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 32px;
}
.Onboarding .ExistingEmail button {
  margin-bottom: 108px;
}
.Onboarding .EmailVerification h2,
.Onboarding .EmailVerification p {
  margin-bottom: 16px;
}
.Onboarding .EmailVerification h2 span,
.Onboarding .EmailVerification p span {
  color: #ee3900;
}
.Onboarding .EmailVerification form {
  gap: 8px;
  margin-bottom: 48px;
}
.Onboarding .EmailVerification form .ant-form-item .ant-form-item-explain-error {
  top: 100%;
}
.Onboarding .ClientVerification {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 40px 76px;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Modal-inner {
  height: 75vh;
  border: none;
  background-color: #ffffff;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-content {
  height: fit-content;
  overflow-y: visible;
}
.Onboarding .ClientVerification .onfido-sdk-ui-PageTitle-title {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #1c1b37;
}
.Onboarding .ClientVerification .onfido-sdk-ui-PageTitle-subTitle,
.Onboarding .ClientVerification .ods-field-label,
.Onboarding .ClientVerification label,
.Onboarding .ClientVerification input,
.Onboarding .ClientVerification select,
.Onboarding .ClientVerification option {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: Lato, sans-serif;
}
.Onboarding .ClientVerification .onfido-sdk-ui-PageTitle-subTitle.Bold,
.Onboarding .ClientVerification .ods-field-label.Bold,
.Onboarding .ClientVerification label.Bold,
.Onboarding .ClientVerification input.Bold,
.Onboarding .ClientVerification select.Bold,
.Onboarding .ClientVerification option.Bold {
  font-weight: 700;
}
.Onboarding .ClientVerification .ods-button {
  flex-direction: row;
  justify-content: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 14px;
}
.Onboarding .ClientVerification .ods-button,
.Onboarding .ClientVerification .ods-button:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.Onboarding .ClientVerification .ods-button:focus-visible,
.Onboarding .ClientVerification .ods-button:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.Onboarding .ClientVerification .ods-button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Onboarding .ClientVerification .ods-button[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.Onboarding .ClientVerification .ods-button[disabled]:hover,
.Onboarding .ClientVerification .ods-button[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.Onboarding .ClientVerification .ods-button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.Onboarding .ClientVerification .ods-button span.Bold {
  font-weight: 700;
}
.Onboarding .ClientVerification .ods-button.DarkBackground,
.Onboarding .ClientVerification .ods-button.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.Onboarding .ClientVerification .ods-button.DarkBackground:focus-visible,
.Onboarding .ClientVerification .ods-button.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.Onboarding .ClientVerification .ods-button.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.Onboarding .ClientVerification .ods-button:focus {
  box-shadow: none;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction[disabled]:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction span.Bold {
  font-weight: 700;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction.DarkBackground,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction.DarkBackground:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction:focus {
  border-color: #0080a3;
  background-color: #ffffff;
  color: #0080a3;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction:hover {
  border-color: #1c1b37;
  background-color: #ffffff;
  color: #1c1b37;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction.Transparent {
  background-color: transparent;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction.Transparent:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction.Transparent:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction.Transparent:active {
  background-color: transparent;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction[disabled] {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction[disabled]:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction[disabled]:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction[disabled]:active {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Confirm-retakeAction:active:not(:disabled) {
  background-color: #1c1b37;
}
.Onboarding .ClientVerification .ods-field label,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer label,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container label {
  display: block;
  margin-bottom: 8px;
}
.Onboarding .ClientVerification .ods-field input,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input,
.Onboarding .ClientVerification .ods-field select,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select,
.Onboarding .ClientVerification .ods-field textarea,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea {
  width: 100%;
  min-height: 38px;
  padding: 7px 13px;
  border: 1px solid #dcf1f8;
  border-radius: 24px;
  appearance: none;
  background-color: #dcf1f8;
  caret-color: #0080a3;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Onboarding .ClientVerification .ods-field input:focus:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:focus:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:focus:not(:disabled),
.Onboarding .ClientVerification .ods-field select:focus:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select:focus:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select:focus:not(:disabled),
.Onboarding .ClientVerification .ods-field textarea:focus:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea:focus:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea:focus:not(:disabled),
.Onboarding .ClientVerification .ods-field input:hover:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:hover:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:hover:not(:disabled),
.Onboarding .ClientVerification .ods-field select:hover:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select:hover:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select:hover:not(:disabled),
.Onboarding .ClientVerification .ods-field textarea:hover:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea:hover:not(:disabled),
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea:hover:not(:disabled) {
  border-color: #23b8e1;
}
.Onboarding .ClientVerification .ods-field input:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:focus,
.Onboarding .ClientVerification .ods-field select:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select:focus,
.Onboarding .ClientVerification .ods-field textarea:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea:focus {
  box-shadow: none;
}
.Onboarding .ClientVerification .ods-field input:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:focus-visible,
.Onboarding .ClientVerification .ods-field select:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select:focus-visible,
.Onboarding .ClientVerification .ods-field textarea:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea:focus-visible {
  outline: none;
}
.Onboarding .ClientVerification .ods-field input[type="number"],
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input[type="number"],
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input[type="number"],
.Onboarding .ClientVerification .ods-field select[type="number"],
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select[type="number"],
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select[type="number"],
.Onboarding .ClientVerification .ods-field textarea[type="number"],
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea[type="number"],
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea[type="number"] {
  -moz-appearance: textfield;
}
.Onboarding .ClientVerification .ods-field input[type="number"]::-webkit-outer-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input[type="number"]::-webkit-outer-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input[type="number"]::-webkit-outer-spin-button,
.Onboarding .ClientVerification .ods-field select[type="number"]::-webkit-outer-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select[type="number"]::-webkit-outer-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select[type="number"]::-webkit-outer-spin-button,
.Onboarding .ClientVerification .ods-field textarea[type="number"]::-webkit-outer-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea[type="number"]::-webkit-outer-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea[type="number"]::-webkit-outer-spin-button,
.Onboarding .ClientVerification .ods-field input[type="number"]::-webkit-inner-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input[type="number"]::-webkit-inner-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input[type="number"]::-webkit-inner-spin-button,
.Onboarding .ClientVerification .ods-field select[type="number"]::-webkit-inner-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select[type="number"]::-webkit-inner-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select[type="number"]::-webkit-inner-spin-button,
.Onboarding .ClientVerification .ods-field textarea[type="number"]::-webkit-inner-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea[type="number"]::-webkit-inner-spin-button,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}
.Onboarding .ClientVerification .ods-field input:-internal-autofill-selected,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:-internal-autofill-selected,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:-internal-autofill-selected,
.Onboarding .ClientVerification .ods-field select:-internal-autofill-selected,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select:-internal-autofill-selected,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select:-internal-autofill-selected,
.Onboarding .ClientVerification .ods-field textarea:-internal-autofill-selected,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea:-internal-autofill-selected,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea:-internal-autofill-selected,
.Onboarding .ClientVerification .ods-field input:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:-webkit-autofill,
.Onboarding .ClientVerification .ods-field select:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select:-webkit-autofill,
.Onboarding .ClientVerification .ods-field textarea:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea:-webkit-autofill,
.Onboarding .ClientVerification .ods-field input:-webkit-autofill:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:-webkit-autofill:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:-webkit-autofill:focus,
.Onboarding .ClientVerification .ods-field select:-webkit-autofill:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select:-webkit-autofill:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select:-webkit-autofill:focus,
.Onboarding .ClientVerification .ods-field textarea:-webkit-autofill:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea:-webkit-autofill:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea:-webkit-autofill:focus,
.Onboarding .ClientVerification .ods-field input:-webkit-autofill:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:-webkit-autofill:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:-webkit-autofill:hover,
.Onboarding .ClientVerification .ods-field select:-webkit-autofill:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select:-webkit-autofill:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select:-webkit-autofill:hover,
.Onboarding .ClientVerification .ods-field textarea:-webkit-autofill:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea:-webkit-autofill:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea:-webkit-autofill:hover,
.Onboarding .ClientVerification .ods-field input:-webkit-autofill:active,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:-webkit-autofill:active,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:-webkit-autofill:active,
.Onboarding .ClientVerification .ods-field select:-webkit-autofill:active,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select:-webkit-autofill:active,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select:-webkit-autofill:active,
.Onboarding .ClientVerification .ods-field textarea:-webkit-autofill:active,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea:-webkit-autofill:active,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea:-webkit-autofill:active {
  background-color: #dcf1f8 !important;
}
.Onboarding .ClientVerification .ods-field input:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:-webkit-autofill,
.Onboarding .ClientVerification .ods-field select:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer select:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container select:-webkit-autofill,
.Onboarding .ClientVerification .ods-field textarea:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea:-webkit-autofill {
  background-color: #dcf1f8;
}
.Onboarding .ClientVerification .ods-field textarea,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer textarea,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container textarea {
  padding: 8px 16px;
  border-radius: 16px;
  resize: none;
}
.Onboarding .ClientVerification .ods-field label,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer label,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container label {
  padding-bottom: 0;
}
.Onboarding .ClientVerification .ods-field .ods-validation,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer .ods-validation,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container .ods-validation {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}
.Onboarding .ClientVerification .ods-field .ods-validation.Bold,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer .ods-validation.Bold,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container .ods-validation.Bold {
  font-weight: 700;
}
.Onboarding .ClientVerification .ods-field .-error,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer .-error,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container .-error {
  color: #ee3900;
}
.Onboarding .ClientVerification .ods-field input:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-RestrictedDocumentSelection-selectionContainer input:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-container input:focus-visible {
  outline: none;
}
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back,
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton,
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back:active,
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton:active,
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton:focus-visible {
  border: none;
  background-color: transparent;
}
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back[disabled][disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton[disabled][disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back:hover[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton:hover[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back:active[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton:active[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back:focus-visible[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton:focus-visible[disabled] {
  background-color: transparent;
}
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back:hover .onfido-sdk-ui-NavigationBar-iconBack,
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton:hover .onfido-sdk-ui-NavigationBar-iconBack {
  background-color: #9dcddd;
  transition: background-color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back:hover .onfido-sdk-ui-NavigationBar-label,
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton:hover .onfido-sdk-ui-NavigationBar-label {
  transition: visibility 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Onboarding .ClientVerification .onfido-sdk-ui-NavigationBar-back .onfido-sdk-ui-NavigationBar-label,
.Onboarding .ClientVerification .onfido-sdk-ui-Button-fallbackButton .onfido-sdk-ui-NavigationBar-label {
  display: none;
}
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option--focused:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option:hover {
  background-color: #23b8e1;
}
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option--focused:active,
.Onboarding .ClientVerification .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__option:active {
  background-color: #0080a3;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-footer {
  display: none;
}
.Onboarding .ClientVerification .onfido-sdk-ui-DocumentSelector-DocumentList-option {
  min-height: 82px;
  height: auto;
  color: #1c1b37;
}
.Onboarding .ClientVerification .onfido-sdk-ui-DocumentSelector-DocumentList-option:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-DocumentSelector-DocumentList-option:hover {
  box-shadow: none;
  background-color: #ffffff;
  border-color: #23b8e1;
}
.Onboarding .ClientVerification .onfido-sdk-ui-DocumentSelector-DocumentList-option:focus div,
.Onboarding .ClientVerification .onfido-sdk-ui-DocumentSelector-DocumentList-option:hover div {
  color: #1c1b37;
}
.Onboarding .ClientVerification .onfido-sdk-ui-DocumentSelector-DocumentList-option:active {
  box-shadow: none;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:active,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:active,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:focus-visible {
  border: none;
  background-color: transparent;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link[disabled][disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton[disabled][disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:hover[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:hover[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:active[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:active[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:focus-visible[disabled],
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:focus-visible[disabled] {
  background-color: transparent;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link.Bold,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton.Bold {
  font-weight: 700;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:focus {
  color: #0080a3;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:focus-visible,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:focus-visible {
  color: #125a6e;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-link:active,
.Onboarding .ClientVerification .onfido-sdk-ui-Theme-textButton:active {
  color: #1c1b37;
}
.Onboarding .ClientVerification .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  border: 1px solid #dcf1f8;
  border-radius: 24px;
  appearance: none;
  background-color: #dcf1f8;
  box-shadow: none;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Onboarding .ClientVerification .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer input,
.Onboarding .ClientVerification .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer input:-webkit-autofill,
.Onboarding .ClientVerification .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer input:-webkit-autofill:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer input:-webkit-autofill:hover,
.Onboarding .ClientVerification .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer input:-webkit-autofill:active {
  outline: none;
  background-color: #dcf1f8 !important;
}
.Onboarding .ClientVerification .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer:focus,
.Onboarding .ClientVerification .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer:hover {
  border-color: #23b8e1;
}
.Onboarding .ClientVerification .onfido-sdk-ui-crossDevice-CrossDeviceLink-inputContainer:focus {
  box-shadow: none;
}
.Onboarding .ClientVerification .onfido-sdk-ui-crossDevice-CrossDeviceLink-linkContainer {
  align-items: center;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Camera-camera .onfido-sdk-ui-PageTitle-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
}
.Onboarding .ClientVerification .onfido-sdk-ui-Camera-webcamContainer {
  height: 37.5em;
}
.Onboarding .ConnectWallet p:first-of-type {
  margin-bottom: 36px;
  color: #1c1b37;
}
.Onboarding .ConnectWallet form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  margin-bottom: 32px;
}
.Onboarding .ConnectWallet form input.ant-input {
  width: 508px;
  pointer-events: none;
}
.Onboarding .ConnectWallet p {
  color: #676783;
}
.InvestmentDrawdowns {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.InvestmentDrawdowns table .ant-table-cell:last-child .ant-btn {
  margin: 0 -16px 0 auto;
}
.InvestmentDrawdowns table .icon-new-window::before {
  font-size: 14px;
  margin-left: 3%;
}
.InvestmentDrawdowns-Phase {
  position: relative;
  left: 24px;
}
.InvestmentDrawdowns-Phase:before {
  content: "";
  position: absolute;
  left: -24px;
  top: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #6dbd8f;
}
.InvestmentDrawdowns-Phase-Requested:before {
  background-color: #f4cf0d;
}
.InvestmentDrawdowns-Phase-Requested div {
  position: absolute;
  left: -24px;
  top: 18px;
  color: #e87b17;
}
.InvestmentDrawdowns-Phase-Closed:before {
  background-color: #3b73b9;
}
.InvestmentDrawdowns-Phase-Aborted:before {
  background-color: #9d2701;
}
.InvestmentDrawdowns-New {
  display: flex;
  flex-direction: column;
  gap: 36px;
  min-height: 50vh;
}
.InvestmentDrawdowns-New .ant-col-12 {
  position: relative;
}
.InvestmentDrawdowns-New.InvestmentDrawdowns-New .ant-form-item:hover .ant-select .ant-select-selector,
.InvestmentDrawdowns-New.InvestmentDrawdowns-New .ant-form-item:focus-within .ant-select .ant-select-selector {
  border-color: #23b8e1 !important;
}
.InvestmentDrawdowns-New .SetCurrency {
  position: relative;
}
.InvestmentDrawdowns-New .SetCurrency label {
  display: block;
  margin-bottom: 8px;
}
.InvestmentDrawdowns-New .SetCurrency-Button {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  position: absolute;
  bottom: 0;
  right: 18px;
}
.InvestmentDrawdowns-New .SetCurrency-Button,
.InvestmentDrawdowns-New .SetCurrency-Button:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.InvestmentDrawdowns-New .SetCurrency-Button[disabled],
.InvestmentDrawdowns-New .SetCurrency-Button:hover,
.InvestmentDrawdowns-New .SetCurrency-Button:active,
.InvestmentDrawdowns-New .SetCurrency-Button:focus-visible {
  border: none;
  background-color: transparent;
}
.InvestmentDrawdowns-New .SetCurrency-Button[disabled][disabled],
.InvestmentDrawdowns-New .SetCurrency-Button:hover[disabled],
.InvestmentDrawdowns-New .SetCurrency-Button:active[disabled],
.InvestmentDrawdowns-New .SetCurrency-Button:focus-visible[disabled] {
  background-color: transparent;
}
.InvestmentDrawdowns-New .SetCurrency-Button.Bold {
  font-weight: 700;
}
.InvestmentDrawdowns-New .SetCurrency-Button,
.InvestmentDrawdowns-New .SetCurrency-Button:focus {
  color: #0080a3;
}
.InvestmentDrawdowns-New .SetCurrency-Button:hover,
.InvestmentDrawdowns-New .SetCurrency-Button:focus-visible {
  color: #125a6e;
}
.InvestmentDrawdowns-New .SetCurrency-Button:active {
  color: #1c1b37;
}
.InvestmentDrawdowns-New .SetCurrency-Extra {
  position: absolute;
  bottom: -24px;
  left: 0;
  margin-bottom: 0;
  color: #676783;
}
.InvestmentDrawdowns-New button {
  align-self: start;
}
.InvestmentDrawdowns-View {
  display: flex;
  flex-direction: column;
  gap: 48px;
  min-height: 50vh;
}
.InvestmentDrawdowns-View .Content {
  display: flex;
  border: 1px solid #9e9eb7;
  border-radius: 16px;
  overflow: hidden;
}
.InvestmentDrawdowns-View .Setup {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.InvestmentDrawdowns-View .RequestedStep {
  display: flex;
  align-items: flex-end;
  gap: 136px;
  padding: 0 40px 32px;
}
.InvestmentDrawdowns-View .RequestedStep > div:first-child {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.InvestmentDrawdowns-View .RequestedStep > div:first-child div {
  display: flex;
}
.InvestmentDrawdowns-View .RequestedStep .ant-input-number {
  width: 240px;
}
.InvestmentDrawdowns-Overview {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 45%;
  padding: 32px 40px;
}
.InvestmentDrawdowns-Overview div {
  display: flex;
}
.InvestmentDrawdowns-Overview p {
  color: #3b73b9;
}
.InvestmentDrawdowns-Overview .ButtonPairWrapper {
  margin-top: 8px;
}
.InvestmentDrawdowns-Modal .ant-modal-body,
.InvestmentDrawdowns-Modal .ant-spin-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.InvestmentDrawdowns-Modal .ant-modal-body h2,
.InvestmentDrawdowns-Modal .ant-spin-container h2,
.InvestmentDrawdowns-Modal .ant-modal-body p,
.InvestmentDrawdowns-Modal .ant-spin-container p {
  text-align: center;
}
.InvestmentDrawdowns-Modal p {
  max-width: 352px;
  text-align: center;
  color: #676783;
}
.InvestmentDrawdowns-Modal form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.InvestmentDrawdowns-Modal form p {
  max-width: fit-content;
}
.InvestmentDrawdowns-Modal form .ant-form-item {
  margin-bottom: 0;
}
.InvestmentDrawdowns-Modal form .ButtonPairWrapper {
  justify-content: center;
}
.InvestmentDrawdowns-Step {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 55%;
  padding: 32px 72px;
  background-color: #676783;
}
.InvestmentDrawdowns-Step h4,
.InvestmentDrawdowns-Step p,
.InvestmentDrawdowns-Step div,
.InvestmentDrawdowns-Step label {
  color: #ffffff;
}
.InvestmentDrawdowns-Step h4 {
  font-weight: 700;
}
.InvestmentDrawdowns-Step .ant-spin-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.InvestmentDrawdowns-Step button {
  width: fit-content;
}
.InvestmentDrawdowns-Step .ant-form {
  margin-top: 24px;
}
.InvestmentDrawdowns-Step .ant-form input.ant-input {
  border-color: #31304b;
  background-color: #31304b;
  color: #ffffff;
}
.InvestmentDrawdowns-Step .ant-form .ant-form-item-extra {
  color: #9e9eb7;
}
.InvestmentDrawdowns-Step .Reference {
  margin-top: 24px;
}
.InvestmentDrawdowns-Step .Reference > div {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #cdcdde;
}
.InvestmentDrawdowns-Step .Reference > div .icon-info-circle:before {
  color: #cdcdde;
}
.InvestmentDrawdowns-Step .Reference i {
  cursor: pointer;
}
.InvestmentDrawdowns .Processing {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.InvestmentDrawdowns .Processing h4,
.InvestmentDrawdowns .Processing p {
  text-align: center;
}
.InvestmentDrawdowns .ant-form-item .ant-form-item-explain-error {
  margin-bottom: 8px;
}
.InvestmentDrawdowns .ant-collapse {
  margin-bottom: 36px;
}
.InvestmentDrawdowns-TotalInvestments {
  padding-bottom: 40px;
}
.InvestmentDrawdowns-TotalInvestments > h2,
.InvestmentDrawdowns-TotalInvestments > h3 {
  margin-bottom: -50px;
}
.Funding-Popover {
  max-width: 450px;
}
.Funding-Popover p {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Funding-Popover a {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #23b8e1;
}
.IssuerDashboard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.IssuerDashboard .PageContent {
  margin-top: 18px;
  padding: 0 120px 64px;
}
.IssuerDashboard .IssuerHeader-Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.IssuerDashboard .IssuerHeader-Divider {
  margin: 8px 0 32px;
  border: 2px solid #7cd4a3;
}
.IssuerDashboard .IssuerTabsNavigator-Link {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #9e9eb7;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.IssuerDashboard .IssuerTabsNavigator-Link:hover,
.IssuerDashboard .IssuerTabsNavigator-Link:focus-visible {
  color: #c4c4ca;
}
.IssuerDashboard .IssuerTabsNavigator-Link.active {
  color: #ffffff;
}
.IssuerDashboard .Applications:has(.ant-spin):not(:has(.Table)) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.IssuerDashboard .Applications .ant-table-thead > tr > th:last-child,
.IssuerDashboard .Applications .ant-table-tbody > tr > td:last-child:not(:has(.ant-empty)) {
  padding-right: 0;
  text-align: end;
}
.IssuerDashboard .Applications .OnfidoColumn {
  display: flex;
  align-items: center;
  gap: 8px;
}
.IssuerDashboard .Applications .OnfidoColumn .ant-image {
  display: flex;
}
.IssuerDashboard .Applications .StatusColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
  position: relative;
  width: 36px;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton:focus-visible,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton[disabled]:hover,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton span.Bold {
  font-weight: 700;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton.DarkBackground,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton.DarkBackground:focus-visible,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton:focus {
  border-color: #0080a3;
  background-color: #ffffff;
  color: #0080a3;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton:focus-visible,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton:hover {
  border-color: #1c1b37;
  background-color: #ffffff;
  color: #1c1b37;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton.Transparent {
  background-color: transparent;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton.Transparent:hover,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton.Transparent:focus-visible,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton.Transparent:active {
  background-color: transparent;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton[disabled] {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton[disabled]:focus-visible,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton[disabled]:hover,
.IssuerDashboard .Applications .StatusColumn-SubmenuButton[disabled]:active {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.IssuerDashboard .Applications .StatusColumn-SubmenuButton span {
  position: absolute;
  top: 8px;
  left: 15px;
  letter-spacing: 1px;
  transform: rotate(90deg);
}
.StatusColumn-Submenu .ant-popover-content,
.StatusColumn-Submenu .ant-popover-inner {
  border-radius: 16px;
}
.StatusColumn-Submenu .ant-popover-inner {
  padding: 0;
  border: 1px solid #9e9eb7;
}
.StatusColumn-Submenu .ant-popover-inner-content {
  padding: 8px 0;
}
.StatusColumn-Submenu .ant-popover-inner-content button {
  width: 100%;
  min-width: 180px;
  justify-content: flex-start;
  border: none;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: none;
}
.StatusColumn-Submenu .ant-popover-inner-content button:hover {
  background-color: #9dcddd;
}
.StatusColumn-Submenu .ant-popover-inner-content button:focus-visible {
  background-color: #dcf1f8;
}
.StatusColumn-Submenu .ant-popover-inner-content button:active {
  background-color: #23b8e1;
}
.Distributions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Distributions table .ant-table-cell:last-child .ant-btn {
  margin: 0 -16px 0 auto;
}
.Distributions table .icon-new-window::before {
  font-size: 14px;
  margin-left: 3%;
}
.Distributions-Deploy {
  position: relative;
  overflow-x: clip;
}
.Distributions-Deploy .ant-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Distributions-Deploy .ant-form .ant-form-item {
  margin-bottom: 0;
}
.Distributions-Deploy .ant-form .ant-form-item .ant-form-item-explain-error,
.Distributions-Deploy .ant-form .ant-form-item .ant-form-item-extra {
  position: absolute;
}
.Distributions-Deploy .ant-form button {
  align-self: flex-start;
}
.Distributions-Deploy input {
  width: 440px;
}
.Distributions-Deploy p {
  color: #676783;
}
.Distributions-Deploy .SetToken-Button {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  height: unset;
  display: inline-flex;
}
.Distributions-Deploy .SetToken-Button,
.Distributions-Deploy .SetToken-Button:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.Distributions-Deploy .SetToken-Button[disabled],
.Distributions-Deploy .SetToken-Button:hover,
.Distributions-Deploy .SetToken-Button:active,
.Distributions-Deploy .SetToken-Button:focus-visible {
  border: none;
  background-color: transparent;
}
.Distributions-Deploy .SetToken-Button[disabled][disabled],
.Distributions-Deploy .SetToken-Button:hover[disabled],
.Distributions-Deploy .SetToken-Button:active[disabled],
.Distributions-Deploy .SetToken-Button:focus-visible[disabled] {
  background-color: transparent;
}
.Distributions-Deploy .SetToken-Button.Bold {
  font-weight: 700;
}
.Distributions-Deploy .SetToken-Button,
.Distributions-Deploy .SetToken-Button:focus {
  color: #0080a3;
}
.Distributions-Deploy .SetToken-Button:hover,
.Distributions-Deploy .SetToken-Button:focus-visible {
  color: #125a6e;
}
.Distributions-Deploy .SetToken-Button:active {
  color: #1c1b37;
}
.Distributions-Deploy .SetToken-Button[disabled] {
  color: #9e9eb7;
  cursor: auto;
}
.Distributions-Deploy .SetToken-Button[disabled] {
  color: #676783;
  cursor: not-allowed;
}
.Distributions-Deploy .ant-form-item {
  display: flex;
}
.Distributions-Deploy .TokenInfo {
  margin-top: 16px;
}
.Distributions-Deploy .Progress {
  margin-top: 24px;
}
.Distributions-Deploy .Progress .ant-steps {
  position: absolute;
  top: 30px;
  right: 0;
  width: auto;
  opacity: 0;
  transform: translateX(200%);
  animation: 1s appearance ease forwards;
}
.Distributions-Deploy .Progress .ant-steps .ant-steps-item-wait .ant-steps-item-title {
  color: #9e9eb7;
}
.Distributions-Deploy .Progress .ant-steps .ant-steps-item-wait .ant-steps-icon-dot {
  background-color: #9e9eb7;
}
.Distributions-Deploy .Progress .ant-steps .ant-steps-item-finish .ant-steps-item-tail:after,
.Distributions-Deploy .Progress .ant-steps .ant-steps-item-finish .ant-steps-icon-dot {
  background-color: #6dbd8f;
}
.Distributions-Deploy .Progress .ant-collapse .ant-collapse-header {
  width: fit-content;
}
.Distributions-Deploy .Progress .ant-collapse .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.35s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  width: 14px;
  height: 14px;
}
.Distributions-Deploy .Progress .ant-collapse .ant-collapse-content-box {
  padding: 4px 32px;
}
.Distributions-Deploy .Progress pre {
  margin-top: 8px;
}
.Distributions-SubmitModal ul {
  margin: 16px 0;
}
.Distributions-SubmitModal .ButtonPairWrapper {
  justify-content: center;
  margin-top: 24px;
}
.Distributions-CurrentHolders {
  margin-top: 48px;
}
.Distributions-CurrentHolders > h2,
.Distributions-CurrentHolders > h3 {
  margin-bottom: -50px;
}
.Distributions-Phase {
  position: relative;
  left: 24px;
}
.Distributions-Phase:before {
  content: "";
  position: absolute;
  left: -24px;
  top: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #6dbd8f;
}
.Distributions-Phase-Requested:before {
  background-color: #f4cf0d;
}
.Distributions-Phase-Requested div {
  position: absolute;
  left: -24px;
  top: 18px;
  color: #e87b17;
}
.Distributions-Phase-Aborted:before {
  background-color: #9d2701;
}
.Distributions-Phase-Distributed:before {
  background-color: #3b73b9;
}
.Distributions-Overview {
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  border-radius: 16px;
  border: 1px solid #9e9eb7;
  overflow: hidden;
}
.Distributions-Overview .FirstColumn {
  display: flex;
}
.Distributions-Overview .InfoColumn {
  padding: 28px 72px;
}
.Distributions-Overview .InfoColumn .Distributions-Phase {
  margin-bottom: 16px;
}
.Distributions-Overview .InfoColumn .AddressDisplayer {
  margin: 28px 0 4px;
}
.Distributions-Overview .InfoColumn span {
  color: #9e9eb7;
}
.Distributions-Overview .ActionColumn {
  padding: 66px 72px;
}
.Distributions-Overview .ActionColumn div {
  display: flex;
}
.Distributions-Overview .ActionColumn button {
  margin-top: 28px;
}
.Distributions-Overview .FeeColumn {
  width: 40%;
  padding: 32px 72px;
  background-color: #676783;
}
.Distributions-Overview .FeeColumn .ant-form-item label {
  color: #ffffff;
}
.Distributions-Overview .FeeColumn .ant-form-item .ant-form-item-control-input {
  width: 172px;
  margin-bottom: 4px;
}
.Distributions-Overview .FeeColumn .ant-form-item input {
  padding-right: 32px;
  border-color: #31304b;
  background-color: #31304b;
  color: #ffffff;
}
.Distributions-Overview .FeeColumn .ant-form-item p {
  color: #cdcdde;
}
.Distributions-Beneficiaries > h2,
.Distributions-Beneficiaries > h3 {
  margin-bottom: -50px;
}
.Distributions-Beneficiaries .StatusColumn {
  display: flex;
  align-items: center;
  gap: 8px;
}
.Distributions-Beneficiaries .StatusColumn .Status {
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
}
.Distributions-Beneficiaries .StatusColumn .Status::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
}
.Distributions-Beneficiaries .StatusColumn .Status.Correct::before {
  background-color: #6dbd8f;
}
.Distributions-Beneficiaries .StatusColumn .Status.Wrong::before,
.Distributions-Beneficiaries .StatusColumn .Status.Bad::before {
  background-color: #ee3900;
}
.Distributions-Beneficiaries .StatusColumn .Status.Available::before {
  background-color: #3b73b9;
}
.Distributions-Beneficiaries .StatusColumn .Status.Wrong::after {
  content: "+";
  position: absolute;
  left: 3px;
  top: -1px;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  transform: rotate(45deg);
}
.Distributions-Beneficiaries .StatusColumn .Status.Correct.Confirmed {
  border: none;
}
.Distributions-Beneficiaries .StatusColumn .Status.Correct.Confirmed::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 8px;
  height: 5px;
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  transform: rotate(-45deg);
}
.Distributions-Beneficiaries .ant-table-tbody > tr > td:last-child {
  padding-right: 0;
}
.Distributions-Beneficiaries .ant-table-tbody > tr > td:last-child button {
  margin-left: auto;
}
.Distributions-PersonBenefit {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Distributions-PersonBenefit .PersonBenefit-Cards {
  display: flex;
  gap: 24px;
}
.Distributions-PersonBenefit .PersonBenefit-Card {
  padding: 16px;
  border-radius: 8px;
  background-color: #eae9f2;
}
.Distributions-PersonBenefit .PersonBenefit-Card .StatusTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.Distributions-PersonBenefit .PersonBenefit-Card .StatusTitle .Status {
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
  border: none;
}
.Distributions-PersonBenefit .PersonBenefit-Card .StatusTitle .Status::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
}
.Distributions-PersonBenefit .PersonBenefit-Card .StatusTitle .Status.Correct::before {
  background-color: #6dbd8f;
}
.Distributions-PersonBenefit .PersonBenefit-Card .StatusTitle .Status.Wrong::before,
.Distributions-PersonBenefit .PersonBenefit-Card .StatusTitle .Status.Bad::before {
  background-color: #ee3900;
}
.Distributions-PersonBenefit .PersonBenefit-Card .StatusTitle .Status.Available::before {
  background-color: #3b73b9;
}
.Distributions-PersonBenefit .PersonBenefit-Card .StatusTitle .Status.Wrong::after {
  content: "+";
  position: absolute;
  left: 3px;
  top: -1px;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  transform: rotate(45deg);
}
.Distributions-PersonBenefit .PersonBenefit-Card .StatusTitle .Status.Correct.Confirmed {
  border: none;
}
.Distributions-PersonBenefit .PersonBenefit-Card .StatusTitle .Status.Correct.Confirmed::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 8px;
  height: 5px;
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  transform: rotate(-45deg);
}
.Distributions-PersonBenefit button {
  align-self: start;
}
.Proposals-List {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 32px;
  min-height: 60vh;
}
.Proposals-Empty {
  margin-top: 128px;
  text-align: center;
}
.Proposals-Empty p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}
.Proposals-Cards {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.Proposals-Card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 336px;
  min-height: 230px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #1c1b37;
}
.Proposals-Card h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Proposals-Card .Card-Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 2px solid #9e9eb7;
}
.Proposals-Card .Card-Tally {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
}
.Proposals-Card .Card-Tally-Item {
  display: flex;
  align-items: center;
  gap: 4px;
}
.Proposals-Card .Card-Tally-Item:before {
  font-family: "icon-font";
  font-size: 18px;
}
.Proposals-Card .Card-Tally-YES {
  color: #3a825c;
}
.Proposals-Card .Card-Tally-YES:before {
  content: "\e911";
}
.Proposals-Card .Card-Tally-NO {
  color: #9d2701;
}
.Proposals-Card .Card-Tally-NO:before {
  content: "\e910";
}
.Proposals-Card .Card-Tally-ABSTAIN {
  color: #676783;
}
.Proposals-Card .Card-Tally-ABSTAIN:before {
  content: "\e913";
}
.Proposals-Card h4 {
  font-weight: 700;
  color: #676783;
}
.Proposals-Card button {
  align-self: flex-start;
}
.Proposals-Card.Closed {
  border: 1px solid #9e9eb7;
  background-color: #f0f0f6;
  cursor: pointer;
}
.Proposals-Card:not(.Closed):focus-visible,
.Proposals-Card:not(.Closed):hover {
  box-shadow: 0px 0px 20px -12px #23b8e1;
  transform: scale(100.5%);
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Proposal-New {
  min-height: 50vh;
}
.Proposal-New .ant-form {
  max-width: 600px;
}
.Proposal-View {
  min-height: 50vh;
}
.Proposal-About {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;
}
.Proposal-Info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 600px;
}
.Proposal-Info .StatusLabel,
.Proposal-Info button {
  align-self: flex-start;
}
.Proposal-Info > p {
  color: #676783;
}
.Proposal-Info button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Proposal-Info button,
.Proposal-Info button:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.Proposal-Info button:focus-visible,
.Proposal-Info button:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.Proposal-Info button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Proposal-Info button[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.Proposal-Info button[disabled]:hover,
.Proposal-Info button[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.Proposal-Info button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.Proposal-Info button span.Bold {
  font-weight: 700;
}
.Proposal-Info button.DarkBackground,
.Proposal-Info button.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.Proposal-Info button.DarkBackground:focus-visible,
.Proposal-Info button.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.Proposal-Info button.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.Proposal-Info button,
.Proposal-Info button:focus {
  border-color: #0080a3;
  background-color: #ffffff;
  color: #0080a3;
}
.Proposal-Info button:focus-visible,
.Proposal-Info button:hover {
  border-color: #1c1b37;
  background-color: #ffffff;
  color: #1c1b37;
}
.Proposal-Info button:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.Proposal-Info button.Transparent {
  background-color: transparent;
}
.Proposal-Info button.Transparent:hover,
.Proposal-Info button.Transparent:focus-visible,
.Proposal-Info button.Transparent:active {
  background-color: transparent;
}
.Proposal-Info button[disabled] {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.Proposal-Info button[disabled]:focus-visible,
.Proposal-Info button[disabled]:hover,
.Proposal-Info button[disabled]:active {
  border-color: #9e9eb7;
  background-color: #ffffff;
  color: #9e9eb7;
}
.Proposal-DescriptionBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Proposal-DescriptionBlock p:first-child {
  font-weight: 700;
}
.Proposal-Voting {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
  padding: 32px 32px 56px;
  background-color: #676783;
  border-radius: 16px;
  opacity: 0;
  transform: translateX(60px);
  animation: 0.35s appearance cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}
.Proposal-Voting h4,
.Proposal-Voting label {
  color: #ffffff;
}
.Proposal-Voting h4 {
  font-weight: 700;
}
.Proposal-Voting.Proposal-Voting .ant-radio-inner {
  border-color: #ffffff;
}
.Proposal-Voting textarea.ant-input {
  border-color: #31304b;
  background-color: #31304b;
  color: #ffffff;
}
.Proposal-Voting .ant-input-textarea-show-count::after {
  color: #ffffff;
}
.Proposal-Voting .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: #31304b;
}
.Proposal-VoteResultBlock {
  background-image: linear-gradient(to right, #1c1b37 25%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
}
.Proposal-VoteResultBlock .ant-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  height: unset;
  align-self: flex-end;
}
.Proposal-VoteResultBlock .ant-btn,
.Proposal-VoteResultBlock .ant-btn:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.Proposal-VoteResultBlock .ant-btn[disabled],
.Proposal-VoteResultBlock .ant-btn:hover,
.Proposal-VoteResultBlock .ant-btn:active,
.Proposal-VoteResultBlock .ant-btn:focus-visible {
  border: none;
  background-color: transparent;
}
.Proposal-VoteResultBlock .ant-btn[disabled][disabled],
.Proposal-VoteResultBlock .ant-btn:hover[disabled],
.Proposal-VoteResultBlock .ant-btn:active[disabled],
.Proposal-VoteResultBlock .ant-btn:focus-visible[disabled] {
  background-color: transparent;
}
.Proposal-VoteResultBlock .ant-btn.Bold {
  font-weight: 700;
}
.Proposal-VoteResultBlock .ant-btn,
.Proposal-VoteResultBlock .ant-btn:focus {
  color: #0080a3;
}
.Proposal-VoteResultBlock .ant-btn:hover,
.Proposal-VoteResultBlock .ant-btn:focus-visible {
  color: #125a6e;
}
.Proposal-VoteResultBlock .ant-btn:active {
  color: #1c1b37;
}
.Proposal-VoteResultBlock .ant-btn[disabled] {
  color: #9e9eb7;
  cursor: auto;
}
.Proposal-VoteResultBlock .Results {
  display: flex;
  align-items: flex-start;
  gap: 48px;
}
.Proposal-VoteResultBlock .Results .YES {
  color: #3a825c;
}
.Proposal-VoteResultBlock .Results .NO {
  color: #9d2701;
}
.Proposal-VoteResultBlock .Results .ABSTAIN {
  color: #676783;
}
.Proposal-VoteResultBlock .Results .Votes {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-direction: column-reverse;
  opacity: 0;
  transform: translateX(-60px);
  animation: 0.35s appearance cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}
.Proposal-VoteResultBlock .Results .Votes .Tally-Item {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  padding: 8px 10px;
  width: 98px;
  height: 64px;
  border-radius: 16px;
  border: 1px solid #9e9eb7;
}
.Proposal-VoteResultBlock .Results .Votes .Tally-Item[role="button"] {
  cursor: pointer;
}
.Proposal-VoteResultBlock .Results .Votes .Tally-Item[role="button"]:hover,
.Proposal-VoteResultBlock .Results .Votes .Tally-Item[role="button"]:focus {
  box-shadow: 0px 0px 20px -12px #23b8e1;
  transform: scale(101%);
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.Proposal-VoteResultBlock .Results .Votes .Tally-Item-Active {
  background-color: #0080a3;
  border-color: #0080a3;
  color: #ffffff;
}
.Proposal-VoteResultBlock .Results .Votes .Tally-Item-Active .YES,
.Proposal-VoteResultBlock .Results .Votes .Tally-Item-Active .NO,
.Proposal-VoteResultBlock .Results .Votes .Tally-Item-Active .ABSTAIN {
  color: #ffffff;
}
.Proposal-VoteResultBlock .Results .Votes-Item {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 4px;
}
.Proposal-VoteResultBlock .Results .Votes-Item:before {
  font-family: "icon-font";
  font-size: 18px;
}
.Proposal-VoteResultBlock .Results .Votes .YES:before {
  content: "\e911";
}
.Proposal-VoteResultBlock .Results .Votes .NO:before {
  content: "\e910";
}
.Proposal-VoteResultBlock .Results .Votes .ABSTAIN:before {
  content: "\e913";
}
.Proposal-VoteResultBlock .Results .VotesDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-direction: column-reverse;
  width: 100%;
}
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment {
  display: flex;
  align-items: center;
  gap: 64px;
  padding: 24px;
  border-radius: 16px;
  background-color: #eae9f2;
  opacity: 0;
  transform: translateY(60px);
  animation: 0.35s appearance cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment > div:first-child {
  display: flex;
  align-items: center;
  gap: 32px;
}
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment .Vote-Avatar {
  display: flex;
}
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment .Vote-Avatar .icon-user {
  font-size: 36px;
}
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment .Vote-Avatar.YES .path2:before,
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment .Vote-Avatar.YES .path3:before {
  color: #3a825c;
}
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment .Vote-Avatar.NO .path2:before,
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment .Vote-Avatar.NO .path3:before {
  color: #9d2701;
}
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment .Vote-Avatar.ABSTAIN .path2:before,
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment .Vote-Avatar.ABSTAIN .path3:before {
  color: #676783;
}
.Proposal-VoteResultBlock .Results .VotesDetails .Vote-Comment .Vote-Info {
  white-space: nowrap;
}
.Vote-Modal .ant-modal-body,
.Vote-Modal .ant-spin-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.Vote-Modal .ant-modal-body h2,
.Vote-Modal .ant-spin-container h2,
.Vote-Modal .ant-modal-body p,
.Vote-Modal .ant-spin-container p {
  text-align: center;
}
.Vote-Modal h4 {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 2px solid #7cd4a3;
  text-align: center;
  color: #676783;
}
.Vote-Modal .Vote-Info {
  width: 100%;
  align-self: start;
}
.Vote-Modal .Vote-Info p {
  margin-bottom: 4px;
  text-align: left;
}
.Vote-Modal .Vote-Info .Vote {
  width: 100%;
  margin-bottom: 16px;
  padding: 4px 16px;
  border-radius: 16px;
  background-color: #eae9f2;
}
.WalletBadge {
  position: fixed;
  top: 16px;
  right: 24px;
  gap: 4px;
  z-index: 3;
}
.WalletBadge,
.WalletBadge:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.WalletBadge[disabled],
.WalletBadge:hover,
.WalletBadge:active,
.WalletBadge:focus-visible {
  border: none;
  background-color: transparent;
}
.WalletBadge[disabled][disabled],
.WalletBadge:hover[disabled],
.WalletBadge:active[disabled],
.WalletBadge:focus-visible[disabled] {
  background-color: transparent;
}
.WalletBadge .icon-arrow-down {
  transition: transform 0.35s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.WalletBadge .ant-image {
  transition: filter 0.25s ease-in-out;
}
.WalletBadge:hover .ant-image,
.WalletBadge:focus-visible .ant-image,
.WalletBadge:active .ant-image {
  filter: drop-shadow(0px 0px 3px #ffffff);
}
.WalletBadge:hover .icon-arrow-down:before,
.WalletBadge:focus-visible .icon-arrow-down:before,
.WalletBadge:active .icon-arrow-down:before {
  color: #ffffff;
}
.WalletBadge.ant-popover-open .icon-arrow-down {
  transform: rotate(180deg);
}
.WalletBadge.WalletBadge .WalletBadge-Status {
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 28px;
  bottom: 0;
}
.WalletBadge.WalletBadge .WalletBadge-Status::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
}
.WalletBadge.WalletBadge .WalletBadge-Status.Correct::before {
  background-color: #6dbd8f;
}
.WalletBadge.WalletBadge .WalletBadge-Status.Wrong::before,
.WalletBadge.WalletBadge .WalletBadge-Status.Bad::before {
  background-color: #ee3900;
}
.WalletBadge.WalletBadge .WalletBadge-Status.Available::before {
  background-color: #3b73b9;
}
.WalletBadge.WalletBadge .WalletBadge-Status.Wrong::after {
  content: "+";
  position: absolute;
  left: 3px;
  top: -1px;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  transform: rotate(45deg);
}
.WalletBadge.WalletBadge .WalletBadge-Status.Correct.Confirmed {
  border: none;
}
.WalletBadge.WalletBadge .WalletBadge-Status.Correct.Confirmed::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 8px;
  height: 5px;
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  transform: rotate(-45deg);
}
.WalletBadge-Popover {
  position: fixed;
}
.WalletBadge-Popover .ant-popover-inner {
  padding: 0;
  border: none;
  border-radius: 16px;
}
.WalletBadge-Content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 600px;
  padding: 24px 32px;
  color: #1c1b37;
}
.WalletBadge-Content h4 {
  font-weight: 700;
}
.WalletBadge-Content div {
  display: flex;
  align-items: center;
}
.WalletBadge-Content div .ant-image {
  width: 24px;
  margin-right: 16px;
}
.WalletBadge-Content button {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  height: unset;
}
.WalletBadge-Content button,
.WalletBadge-Content button:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.WalletBadge-Content button[disabled],
.WalletBadge-Content button:hover,
.WalletBadge-Content button:active,
.WalletBadge-Content button:focus-visible {
  border: none;
  background-color: transparent;
}
.WalletBadge-Content button[disabled][disabled],
.WalletBadge-Content button:hover[disabled],
.WalletBadge-Content button:active[disabled],
.WalletBadge-Content button:focus-visible[disabled] {
  background-color: transparent;
}
.WalletBadge-Content button.Bold {
  font-weight: 700;
}
.WalletBadge-Content button,
.WalletBadge-Content button:focus {
  color: #0080a3;
}
.WalletBadge-Content button:hover,
.WalletBadge-Content button:focus-visible {
  color: #125a6e;
}
.WalletBadge-Content button:active {
  color: #1c1b37;
}
.WalletBadge-Content button[disabled] {
  color: #9e9eb7;
  cursor: auto;
}
.WalletBadge-Content .DisconnectButton,
.WalletBadge-Content .DisconnectButton:focus {
  color: #ee3900;
}
.WalletBadge-Content .DisconnectButton:active {
  color: #1c1b37;
}
.WalletBadge-Content .Divider {
  background-image: linear-gradient(to right, #1c1b37 25%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  height: 1px;
}
.WalletBadge-Content .WalletBalances {
  flex-wrap: wrap;
}
.WalletBadge-Content .WalletBalances-Balance {
  flex-basis: calc(50% - 20px);
  margin-right: 20px;
}
.WalletBadge-Content .WalletBalances-Balance span:last-of-type {
  margin-left: 4px;
  font-weight: bold;
}
.WalletBadge-Row {
  justify-content: space-between;
}
.WalletBadge-Row:first-of-type {
  flex-direction: row-reverse;
}
.FastTabsNavigator {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  padding-bottom: 16px;
}
.FastTabsNavigator .MainNavigatorWrapper {
  position: sticky;
  top: 86px;
  z-index: 3;
}
.FastTabsNavigator .MainNavigator {
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-width: 166px;
  padding: 24px;
  border-radius: 10px;
  border: 2px solid #ffffff33;
  background-color: #000012;
}
.FastTabsNavigator .MainNavigator .FastInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
}
.FastTabsNavigator .MainNavigator .FastInfo img {
  width: 47px;
  height: 47px;
  border-radius: 8px;
  border: 1px solid #9e9eb7;
}
.FastTabsNavigator .MainNavigator .FastInfo h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #ffffff;
}
.FastTabsNavigator .MainNavigator nav {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-end;
}
.FastTabsNavigator-Link {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.FastTabsNavigator-Link.Bold {
  font-weight: 700;
}
.FastTabsNavigator-Link,
.FastTabsNavigator-Link:focus {
  color: #9dcddd;
}
.FastTabsNavigator-Link:hover,
.FastTabsNavigator-Link:focus-visible {
  color: #ffffff;
}
.FastTabsNavigator-Link:active {
  color: #1c1b37;
}
.FastTabsNavigator-Link.active {
  font-weight: 700;
  color: #23b8e1;
}
.FastTabsNavigator .ContentWrapper {
  position: relative;
  flex-grow: 1;
}
.FastTabsNavigator .ContentWrapper:before {
  content: "";
  position: sticky;
  width: 100%;
  height: 30px;
  top: 70px;
  left: 0;
  display: block;
  margin-top: -30px;
  background-color: #1c1b37;
  z-index: 1;
}
.FastTabsNavigator .ContentWrapper:has(> .Proposals-List):before {
  height: 16px;
  margin-top: -16px;
}
.FastTabsNavigator .ContentWrapper-Breadcrumbs {
  position: sticky;
  top: 110px;
  margin: -24px 0 0 40px;
  z-index: 3;
}
.FastTabsNavigator .ContentWrapper .SecondFastTabNavigator,
.FastTabsNavigator .ContentWrapper .Content-Header {
  position: sticky;
  top: 86px;
  border-radius: 16px 16px 0 0;
  z-index: 2;
}
.FastTabsNavigator .ContentWrapper .SecondFastTabNavigator .ant-breadcrumb,
.FastTabsNavigator .ContentWrapper .Content-Header .ant-breadcrumb {
  position: absolute;
  top: 24px;
}
.FastTabsNavigator .ContentWrapper .SecondFastTabNavigator {
  height: 54px;
  display: flex;
  gap: 24px;
  padding: 16px 32px;
  border: 2px solid #ffffff33;
  border-bottom: none;
  background-color: #000012;
  box-shadow: 0px 32px 8px -14px rgba(255, 255, 255, 0.7);
}
.FastTabsNavigator .ContentWrapper .Content {
  position: relative;
}
.FastTabsNavigator .ContentWrapper .Content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #000012;
  border-left: 2px solid #ffffff33;
  border-right: 2px solid #ffffff33;
  z-index: -1;
}
.FastTabsNavigator .ContentWrapper .Content-Header {
  padding: 58px 40px 24px;
  background-color: #eae9f2;
}
.FastTabsNavigator .ContentWrapper .Content-Body {
  padding: 40px 48px;
  border-radius: 0 0 16px 16px;
  background-color: #ffffff;
}
.MyWallet-Transfer {
  width: 100%;
}
.MyWallet-Transfer label {
  font-weight: 500;
}
.MyWallet-Transfer.MyWallet-Transfer .ant-form-item {
  margin-bottom: 8px;
}
.MyWallet-Transfer.MyWallet-Transfer .ant-form-item:hover .ant-select .ant-select-selector,
.MyWallet-Transfer.MyWallet-Transfer .ant-form-item:focus-within .ant-select .ant-select-selector {
  border-color: #23b8e1 !important;
}
.MyWallet-Transfer .ant-row {
  margin-bottom: 8px;
}
.FastManagement {
  display: grid;
  width: 100%;
}
.FastManagement section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.FastManagement .ant-form {
  margin-top: 8px;
}
.FastManagement-FormItem {
  width: 100%;
}
.FastManagement-FormItem .ant-form-item {
  position: relative;
  margin-bottom: 0;
}
.FastManagement-FormItem .ant-form-item .ant-form-item-explain {
  position: absolute;
}
.AddressDisplayer {
  display: flex;
  align-items: center;
}
.AddressDisplayer h3 {
  font-size: 14px;
  line-height: 22px;
}
.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}
.Loader-Label {
  color: #ffffff;
}
.Loader.WhiteLayout {
  position: relative;
  min-height: 40vh;
  border-radius: 16px;
  background-color: #fff;
  color: #1c1b37;
  z-index: 1;
}
.Loader.WhiteLayout .Loader-Label {
  color: #1c1b37;
}
.NetworkName {
  display: flex;
  letter-spacing: 2px;
}
.CurvedBackground {
  position: fixed;
}
.CurvedBackground img {
  position: fixed;
  bottom: 0;
  right: 0;
  user-select: none;
  -webkit-user-drag: none;
  transform-origin: bottom right;
}
.CurvedBackground .Shape1 {
  animation: scale 0.7s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.CurvedBackground .Shape2 {
  bottom: 44px;
  animation: scale 0.7s ease 0.3s backwards;
}
.FilterButton,
.FilterButton:focus {
  background-color: #eae9f2;
  border-color: #eae9f2;
}
.FilterButton.withMarginRight {
  margin-right: 12px;
}
.FilterButton:focus-visible,
.FilterButton:hover {
  background-color: #eae9f2;
  border-color: #125a6e;
}
.FilterButton > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1c1b37;
}
.FilterButton > span.Bold {
  font-weight: 700;
}
.FilterButton.active {
  background-color: #dcf1f8;
  border-color: #125a6e;
}
.FilterButton.active .ant-image-img {
  width: 22px;
  height: 18px;
  margin-right: 8px;
}
.FilterButton.active > span {
  font-weight: 700;
  color: #125a6e;
}
.CopyPopover-Button {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  height: unset;
  margin-left: 12px;
  padding: 0;
}
.CopyPopover-Button,
.CopyPopover-Button:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.CopyPopover-Button[disabled],
.CopyPopover-Button:hover,
.CopyPopover-Button:active,
.CopyPopover-Button:focus-visible {
  border: none;
  background-color: transparent;
}
.CopyPopover-Button[disabled][disabled],
.CopyPopover-Button:hover[disabled],
.CopyPopover-Button:active[disabled],
.CopyPopover-Button:focus-visible[disabled] {
  background-color: transparent;
}
.CopyPopover-Button.Bold {
  font-weight: 700;
}
.CopyPopover-Button,
.CopyPopover-Button:focus {
  color: #0080a3;
}
.CopyPopover-Button:hover,
.CopyPopover-Button:focus-visible {
  color: #125a6e;
}
.CopyPopover-Button:active {
  color: #1c1b37;
}
.CopyPopover-Button[disabled] {
  color: #9e9eb7;
  cursor: auto;
}
.CopyPopover-Label {
  margin-left: 8px;
  color: #0080a3;
  font-weight: 700;
}
.Table.Table th {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  border: none;
  border-bottom: none;
  background-color: #ffffff;
  color: #676783;
  text-transform: uppercase;
}
.Table.Table th.Bold {
  font-weight: 700;
}
.Table th::before {
  height: 0 !important;
}
.Table-Row.Table-Row td {
  border-top: 1px dashed #1c1b37;
  border-bottom: none;
  background-color: #ffffff;
}
.Table-Row:hover td {
  background-color: transparent !important;
}
.Table-Remove-Button {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  height: unset;
  padding: 4px;
}
.Table-Remove-Button,
.Table-Remove-Button:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.Table-Remove-Button[disabled],
.Table-Remove-Button:hover,
.Table-Remove-Button:active,
.Table-Remove-Button:focus-visible {
  border: none;
  background-color: transparent;
}
.Table-Remove-Button[disabled][disabled],
.Table-Remove-Button:hover[disabled],
.Table-Remove-Button:active[disabled],
.Table-Remove-Button:focus-visible[disabled] {
  background-color: transparent;
}
.Table-Remove-Button.Bold {
  font-weight: 700;
}
.Table-Remove-Button,
.Table-Remove-Button:focus {
  color: #0080a3;
}
.Table-Remove-Button:hover,
.Table-Remove-Button:focus-visible {
  color: #125a6e;
}
.Table-Remove-Button:active {
  color: #1c1b37;
}
.Table-Remove-Button[disabled] {
  color: #9e9eb7;
  cursor: auto;
}
.Table .AddressColumn,
.Table .UserColumn {
  display: flex;
  align-items: center;
}
.Table .AddressColumn:empty::after {
  content: "-";
}
.Table .UserColumn {
  align-items: center;
  gap: 8px;
}
.Table .UserColumn > a:first-of-type {
  line-height: 16px;
}
.Table .UserColumn:empty::after {
  content: "N/A";
}
.Table .CollapsingColumnTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.Table .CollapsingColumnTitle button {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  height: unset;
  transition: gap 0.15s cubic-bezier(0, 0, 0.2, 1);
  width: 36px;
  gap: 0px;
}
.Table .CollapsingColumnTitle button,
.Table .CollapsingColumnTitle button:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.Table .CollapsingColumnTitle button[disabled],
.Table .CollapsingColumnTitle button:hover,
.Table .CollapsingColumnTitle button:active,
.Table .CollapsingColumnTitle button:focus-visible {
  border: none;
  background-color: transparent;
}
.Table .CollapsingColumnTitle button[disabled][disabled],
.Table .CollapsingColumnTitle button:hover[disabled],
.Table .CollapsingColumnTitle button:active[disabled],
.Table .CollapsingColumnTitle button:focus-visible[disabled] {
  background-color: transparent;
}
.Table .CollapsingColumnTitle button.Bold {
  font-weight: 700;
}
.Table .CollapsingColumnTitle button,
.Table .CollapsingColumnTitle button:focus {
  color: #0080a3;
}
.Table .CollapsingColumnTitle button:hover,
.Table .CollapsingColumnTitle button:focus-visible {
  color: #125a6e;
}
.Table .CollapsingColumnTitle button:active {
  color: #1c1b37;
}
.Table .CollapsingColumnTitle button[disabled] {
  color: #9e9eb7;
  cursor: auto;
}
.Table .CollapsingColumnTitle button:focus-visible,
.Table .CollapsingColumnTitle button:hover {
  gap: 4px;
}
.Table .CollapsingColumnTitle button i {
  transition: transform 0.35s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.Table .CollapsingColumnTitle button.Collapsed {
  gap: 4px;
}
.Table .CollapsingColumnTitle button.Collapsed:focus-visible,
.Table .CollapsingColumnTitle button.Collapsed:hover {
  gap: 0px;
}
.Table .CollapsingColumnTitle button.Collapsed i {
  transform: rotate(180deg);
}
.Table-Error {
  margin-top: 72px;
  color: #ee3900;
}
.AlternativePagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}
.AlternativePagination .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px;
  border-radius: 24px;
}
.AlternativePagination .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
}
.AlternativePagination button {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  height: unset;
  display: flex;
  align-items: center;
}
.AlternativePagination button,
.AlternativePagination button:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.AlternativePagination button[disabled],
.AlternativePagination button:hover,
.AlternativePagination button:active,
.AlternativePagination button:focus-visible {
  border: none;
  background-color: transparent;
}
.AlternativePagination button[disabled][disabled],
.AlternativePagination button:hover[disabled],
.AlternativePagination button:active[disabled],
.AlternativePagination button:focus-visible[disabled] {
  background-color: transparent;
}
.AlternativePagination button.Bold {
  font-weight: 700;
}
.AlternativePagination button,
.AlternativePagination button:focus {
  color: #0080a3;
}
.AlternativePagination button:hover,
.AlternativePagination button:focus-visible {
  color: #125a6e;
}
.AlternativePagination button:active {
  color: #1c1b37;
}
.AlternativePagination button[disabled] {
  color: #9e9eb7;
  cursor: auto;
}
.AlternativePagination button:not(:disabled):focus-visible i,
.AlternativePagination button:not(:disabled):hover i {
  transform: translateX(4px);
}
.AlternativePagination button:not(:disabled):focus-visible:first-of-type i,
.AlternativePagination button:not(:disabled):hover:first-of-type i {
  transform: translateX(-4px);
}
.AlternativePagination button i {
  transition: transform 0.15s cubic-bezier(0, 0, 0.2, 1);
  font-size: 22px;
}
.Card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 320px;
  padding: 30px;
  text-align: center;
  border: 1px solid #676783;
  border-radius: 24px;
}
.Card h4 {
  font-weight: 700;
}
.DataBox {
  background: #eae9f2;
  padding: 8px 14px;
  width: fit-content;
  border-radius: 4px;
}
.DataBox-Top {
  display: flex;
  align-items: center;
}
.DataBox-Top p {
  font-family: "Comfortaa", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1c1b37;
}
.DataBox-Bottom {
  display: flex;
  align-items: center;
  gap: 8px;
}
.DataBox-Description-Locked {
  color: #676783;
}
.TeamMember {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TeamMember img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}
.AddressResolverInput {
  position: relative;
}
.AddressResolverInput label {
  display: block;
  margin-bottom: 16px;
}
.AddressResolverInput.AddressResolverInput .ant-select-selector input.ant-select-selection-search-input {
  padding-left: 2px;
  box-shadow: none;
}
.AddressResolverInput-Name {
  position: absolute;
  bottom: 9px;
  right: 32px;
  padding-left: 10px;
  background-color: #dcf1f8;
  pointer-events: none;
  cursor: default;
  animation: opacity 0.35s ease 0.15s backwards;
}
.AddressResolverWithLabel:hover .ant-select .ant-select-selector,
.AddressResolverWithLabel:focus-within .ant-select .ant-select-selector {
  border-color: #23b8e1 !important;
}
.FastsDropdown-Button {
  height: 24px;
  gap: 12px;
}
.FastsDropdown-Button,
.FastsDropdown-Button:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.FastsDropdown-Button[disabled],
.FastsDropdown-Button:hover,
.FastsDropdown-Button:active,
.FastsDropdown-Button:focus-visible {
  border: none;
  background-color: transparent;
}
.FastsDropdown-Button[disabled][disabled],
.FastsDropdown-Button:hover[disabled],
.FastsDropdown-Button:active[disabled],
.FastsDropdown-Button:focus-visible[disabled] {
  background-color: transparent;
}
.FastsDropdown-Button .icon-arrow-down {
  transition: transform 0.35s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.FastsDropdown-Button .ant-image,
.FastsDropdown-Button span,
.FastsDropdown-Button p {
  transition: all 0.35s ease-in-out;
}
.FastsDropdown-Button:focus-visible .ant-image,
.FastsDropdown-Button:hover .ant-image,
.FastsDropdown-Button:active .ant-image {
  filter: drop-shadow(0px 1px 6px #ffffff);
}
.FastsDropdown-Button:focus-visible span,
.FastsDropdown-Button:hover span,
.FastsDropdown-Button:active span {
  transform: translateX(3px);
}
.FastsDropdown-Button:focus-visible p,
.FastsDropdown-Button:hover p,
.FastsDropdown-Button:active p {
  transform: translateX(1px);
}
.FastsDropdown-Button:focus-visible .icon-arrow-down:before,
.FastsDropdown-Button:hover .icon-arrow-down:before,
.FastsDropdown-Button:active .icon-arrow-down:before {
  color: #ffffff;
}
.FastsDropdown-Button.ant-popover-open .icon-arrow-down {
  transform: rotate(180deg);
}
.FastsDropdown-Popover {
  position: fixed;
  padding-top: 10px;
}
.FastsDropdown-Popover .ant-popover-inner {
  padding: 0;
  border: none;
  border-radius: 16px;
}
.FastsDropdown-Popover .Content {
  display: flex;
  flex-direction: column;
  min-width: 416px;
}
.FastsDropdown-Popover .Content .Option,
.FastsDropdown-Popover .Content .DefaultOption {
  display: flex;
  padding: 8px 32px 8px 24px;
  font-weight: normal;
  color: #1c1b37;
}
.FastsDropdown-Popover .Content .Option:hover,
.FastsDropdown-Popover .Content .DefaultOption:hover {
  background-color: #f0f0f6;
}
.FastsDropdown-Popover .Content .Option:first-of-type,
.FastsDropdown-Popover .Content .DefaultOption:first-of-type {
  padding-top: 24px;
  border-radius: 16px 16px 0 0;
}
.FastsDropdown-Popover .Content .Option:last-of-type,
.FastsDropdown-Popover .Content .DefaultOption:last-of-type,
.FastsDropdown-Popover .Content .Option:nth-last-of-type(2),
.FastsDropdown-Popover .Content .DefaultOption:nth-last-of-type(2) {
  padding-bottom: 16px;
}
.FastsDropdown-Popover .Content .Option.Active,
.FastsDropdown-Popover .Content .DefaultOption.Active {
  background-color: #eae9f2;
}
.FastsDropdown-Popover .Content .Option strong,
.FastsDropdown-Popover .Content .DefaultOption strong {
  width: 42px;
}
.FastsDropdown-Popover .Content .Divider {
  background-image: linear-gradient(to right, #1c1b37 25%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  height: 1px;
  margin: 0 24px;
}
.FastsDropdown-Popover .Content .DefaultOption {
  padding-top: 16px;
  border-radius: 0 0 16px 16px;
}
.FastsDropdown-Popover .Content .DefaultOption strong {
  width: auto;
  margin-right: 8px;
}
.FastsDropdown-Option,
.FastsDropdown .TokenSphereOption {
  display: flex;
  gap: 12px;
  color: #ffffff;
}
.TokenSphereOption {
  align-items: center;
  height: 24px;
  user-select: none;
}
.TokenSphereOption .ant-image {
  height: 20px;
}
.TokenSphereOption .ant-image img {
  vertical-align: baseline;
}
.TokenSphereOption p {
  color: #ffffff;
}
.InviteToPlatform-Modal > .ant-modal-content > .ant-modal-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
}
.InviteToPlatform-Modal > .ant-modal-content > .ant-modal-body h1 {
  text-transform: none;
}
.InviteToPlatform-Modal > .ant-modal-content > .ant-modal-body h1 span {
  color: #9d2701;
}
.TipModal-Button {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: color 0.15s cubic-bezier(0, 0, 0.2, 1);
  height: unset;
}
.TipModal-Button,
.TipModal-Button:focus {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.TipModal-Button[disabled],
.TipModal-Button:hover,
.TipModal-Button:active,
.TipModal-Button:focus-visible {
  border: none;
  background-color: transparent;
}
.TipModal-Button[disabled][disabled],
.TipModal-Button:hover[disabled],
.TipModal-Button:active[disabled],
.TipModal-Button:focus-visible[disabled] {
  background-color: transparent;
}
.TipModal-Button.Bold {
  font-weight: 700;
}
.TipModal-Button,
.TipModal-Button:focus {
  color: #0080a3;
}
.TipModal-Button:hover,
.TipModal-Button:focus-visible {
  color: #125a6e;
}
.TipModal-Button:active {
  color: #1c1b37;
}
.TipModal-Button[disabled] {
  color: #9e9eb7;
  cursor: auto;
}
.TipModal .ant-modal-content {
  background-color: #3b73b9;
  color: #ffffff;
}
.TipModal .ant-modal-close svg {
  fill: #ffffff;
}
.TipModal .ant-modal-body {
  padding: 56px 40px;
}
.TipModal .ant-modal-body .icon-info-circle:before {
  color: #ffffff;
}
.TipModal .Content p {
  text-align: left;
  font-style: italic;
}
.TipModal .Content ul {
  padding-inline-start: 12px;
}
.TipModal .Content a {
  text-decoration: underline;
  color: #dcf1f8;
}
.TipModal .Content a:focus-visible,
.TipModal .Content a:hover {
  color: #ffffff;
}
.TipModal .Content a:active {
  color: #9dcddd;
}
.TipModal h4,
.TipModal p {
  color: #ffffff;
}
.FastCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 262px;
  height: 246px;
  padding: 32px 24px;
  border-radius: 16px;
  background-color: #31304b;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.FastCard:hover,
.FastCard:focus-visible {
  filter: brightness(1.1);
  transform: translateY(-5px);
}
.FastCard h1,
.FastCard h4 {
  color: #ffffff;
}
.FastCard-Name {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.FastCard p {
  color: #ffffff80;
}
.FastCard p:last-of-type {
  margin-top: 16px;
}
.FastCard p:last-of-type > i {
  margin-left: 8px;
}
.ConnectWalletPage {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin: auto;
}
.ConnectWalletPage p,
.ConnectWalletPage h3,
.ConnectWalletPage label {
  color: #ffffff;
}
.ConnectWalletPage .Signin-Stepper {
  position: absolute;
  top: 16vh;
  width: 400px;
  opacity: 0;
  transform: translateY(30px);
  animation: 0.35s appearance 0.35s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}
.ConnectWalletPage .Signin-Stepper.ant-steps .ant-steps-item-container .ant-steps-item-content {
  min-height: 32px;
}
.ConnectWalletPage .Signin-Stepper.ant-steps .ant-steps-item-container .ant-steps-item-title {
  color: #ffffff;
}
.ConnectWalletPage .Signin-Stepper.ant-steps .ant-steps-item-active .ant-steps-icon-dot {
  background-color: #7cd4a3;
}
.ConnectWalletPage .Signin-Stepper.ant-steps .ant-steps-item-active .ant-steps-item-title {
  color: #7cd4a3;
}
.ConnectWalletPage .Signin-Stepper.ant-steps .ant-steps-item-wait .ant-steps-icon-dot {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
}
.ConnectWalletPage .Connectors,
.ConnectWalletPage .Login {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: absolute;
  top: 24vh;
  width: 360px;
  padding: 48px 32px 56px;
  border-radius: 32px;
  background-color: #ffffff33;
  transform: perspective(550px) rotateY(90deg);
  animation: 0.35s rotation 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}
.ConnectWalletPage .Connectors p {
  color: #9e9eb7;
}
.ConnectWalletPage .Connectors .Buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.ConnectWalletPage .Connectors .Buttons p {
  width: 100%;
  margin-bottom: 16px;
  text-align: center;
  color: #cdcdde;
}
.ConnectWalletPage .Connectors .Buttons button {
  width: 47%;
}
.ConnectWalletPage .Connectors label {
  color: #cdcdde;
  text-align: center;
}
.ConnectWalletPage .Connectors .wallet p {
  color: #cdcdde;
  margin-bottom: 16px;
  text-align: center;
}
.ConnectWalletPage .Connectors button {
  width: 100%;
}
.ConnectWalletPage .Connectors button .ant-image {
  display: flex;
  width: 18px;
}
.ConnectWalletPage .Connectors > button:last-child,
.ConnectWalletPage .Connectors .Buttons button:last-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 24px;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0, 0, 0.2, 1);
}
.ConnectWalletPage .Connectors > button:last-child,
.ConnectWalletPage .Connectors .Buttons button:last-child,
.ConnectWalletPage .Connectors > button:last-child:focus,
.ConnectWalletPage .Connectors .Buttons button:last-child:focus {
  border: 1px solid #23b8e1;
  background-color: #23b8e1;
  color: #1c1b37;
}
.ConnectWalletPage .Connectors > button:last-child:focus-visible,
.ConnectWalletPage .Connectors .Buttons button:last-child:focus-visible,
.ConnectWalletPage .Connectors > button:last-child:hover,
.ConnectWalletPage .Connectors .Buttons button:last-child:hover {
  border-color: #125a6e;
  background-color: #125a6e;
  color: #ffffff;
}
.ConnectWalletPage .Connectors > button:last-child:active,
.ConnectWalletPage .Connectors .Buttons button:last-child:active {
  border-color: #1c1b37;
  background-color: #1c1b37;
  color: #ffffff;
}
.ConnectWalletPage .Connectors > button:last-child[disabled],
.ConnectWalletPage .Connectors .Buttons button:last-child[disabled] {
  border: none;
  background-color: #cdcdde;
  color: #f0f0f6;
  cursor: not-allowed;
}
.ConnectWalletPage .Connectors > button:last-child[disabled]:hover,
.ConnectWalletPage .Connectors .Buttons button:last-child[disabled]:hover,
.ConnectWalletPage .Connectors > button:last-child[disabled]:active,
.ConnectWalletPage .Connectors .Buttons button:last-child[disabled]:active {
  border-color: #cdcdde;
  background-color: #cdcdde;
  color: #f0f0f6;
}
.ConnectWalletPage .Connectors > button:last-child span,
.ConnectWalletPage .Connectors .Buttons button:last-child span {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
.ConnectWalletPage .Connectors > button:last-child span.Bold,
.ConnectWalletPage .Connectors .Buttons button:last-child span.Bold {
  font-weight: 700;
}
.ConnectWalletPage .Connectors > button:last-child.DarkBackground,
.ConnectWalletPage .Connectors .Buttons button:last-child.DarkBackground,
.ConnectWalletPage .Connectors > button:last-child.DarkBackground:focus,
.ConnectWalletPage .Connectors .Buttons button:last-child.DarkBackground:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.ConnectWalletPage .Connectors > button:last-child.DarkBackground:focus-visible,
.ConnectWalletPage .Connectors .Buttons button:last-child.DarkBackground:focus-visible,
.ConnectWalletPage .Connectors > button:last-child.DarkBackground:hover,
.ConnectWalletPage .Connectors .Buttons button:last-child.DarkBackground:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.ConnectWalletPage .Connectors > button:last-child.DarkBackground:active,
.ConnectWalletPage .Connectors .Buttons button:last-child.DarkBackground:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.ConnectWalletPage .Connectors > button:last-child,
.ConnectWalletPage .Connectors .Buttons button:last-child,
.ConnectWalletPage .Connectors > button:last-child:focus,
.ConnectWalletPage .Connectors .Buttons button:last-child:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1c1b37;
}
.ConnectWalletPage .Connectors > button:last-child:focus-visible,
.ConnectWalletPage .Connectors .Buttons button:last-child:focus-visible,
.ConnectWalletPage .Connectors > button:last-child:hover,
.ConnectWalletPage .Connectors .Buttons button:last-child:hover {
  background-color: #125a6e;
  border-color: #125a6e;
  color: #ffffff;
}
.ConnectWalletPage .Connectors > button:last-child:active,
.ConnectWalletPage .Connectors .Buttons button:last-child:active {
  background-color: #1c1b37;
  border-color: #ffffff;
  color: #ffffff;
}
.ConnectWalletPage .Connectors .ant-form .ant-form-item {
  margin-bottom: 16px;
}
.ConnectWalletPage .Connectors .ant-form .ant-form-item .ant-form-item-explain-error {
  margin-bottom: 4px;
}
.ConnectWalletPage .Connectors .ant-form .ant-form-item input.ant-input {
  border-color: #31304b;
  background-color: #31304b;
  color: #ffffff;
}
.ConnectWalletPage .Connectors .ant-form .ant-form-item input.ant-input:focus,
.ConnectWalletPage .Connectors .ant-form .ant-form-item input.ant-input:hover {
  border-color: #23b8e1;
}
.ConnectWalletPage .SwitchNetwork {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-top: 32px;
  opacity: 0;
  animation: 0.35s appearance 1s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}
.Logo {
  position: absolute;
  top: 10vh;
  width: 214px;
}
.Login {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin: auto;
}
.Login .Signin-Stepper {
  position: absolute;
  top: 16vh;
  width: 400px;
  opacity: 0;
  transform: translateY(30px);
  animation: 0.35s appearance 0.35s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}
.Login .Signin-Stepper.ant-steps .ant-steps-item-container .ant-steps-item-content {
  min-height: 32px;
}
.Login .Signin-Stepper.ant-steps .ant-steps-item-container .ant-steps-item-title {
  color: #ffffff;
}
.Login .Signin-Stepper.ant-steps .ant-steps-item-active .ant-steps-icon-dot {
  background-color: #7cd4a3;
}
.Login .Signin-Stepper.ant-steps .ant-steps-item-active .ant-steps-item-title {
  color: #7cd4a3;
}
.Login .Signin-Stepper.ant-steps .ant-steps-item-wait .ant-steps-icon-dot {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
}
.Login .Signin-Stepper.ant-steps .ant-steps-item-finish .ant-steps-item-content {
  width: 200px;
}
.Login-Card {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: absolute;
  top: 24vh;
  width: 360px;
  padding: 48px 32px 56px;
  border-radius: 32px;
  background-color: #ffffff33;
  transform: perspective(550px) rotateY(90deg);
  animation: 0.35s rotation 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}
.StatusLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 8px 12px;
  border-radius: 24px;
  background-color: #f0f0f6;
  color: #3a825c;
}
.StatusLabel.Bold {
  font-weight: 700;
}
.StatusLabel.Closed {
  background-color: #cdcdde;
  color: #1c1b37;
}
