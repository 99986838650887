.TeamMember {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
  }
}
