.FastManagement {
  display: grid;
  width: 100%;

  section {
    .ColumnDirectionWithGap(8px);
  }

  .ant-form {
    margin-top: 8px;
  }

  &-FormItem {
    width: 100%;

    .ant-form-item {
      position: relative;
      margin-bottom: 0;

      .ant-form-item-explain {
        position: absolute;
      }
    }
  }
}
