.InvestmentDrawdowns {
  .ColumnDirectionWithGap(8px);

  table {
    .ant-table-cell:last-child .ant-btn {
      margin: 0 -16px 0 auto;
    }

    .icon-new-window::before {
      font-size: 14px;
      margin-left: 3%;
    }
  }

  &-Phase {
    position: relative;
    left: 24px;

    &:before {
      content: "";
      position: absolute;
      left: -24px;
      top: 3px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: @green50;
    }

    &-Requested {
      &:before {
        background-color: @yellow;
      }

      div {
        position: absolute;
        left: -24px;
        top: 18px;
        color: @orange50;
      }
    }

    &-Closed {
      &:before {
        background-color: @blue50;
      }
    }

    &-Aborted {
      &:before {
        background-color: @red80;
      }
    }
  }

  &-New {
    .ColumnDirectionWithGap(36px);
    min-height: 50vh;

    .ant-col-12 {
      position: relative;
    }

    && .ant-form-item {
      .AddressResolverWithLabel();
    }

    .SetCurrency {
      position: relative;

      label {
        display: block;
        margin-bottom: 8px;
      }

      &-Button {
        .Button-Transparent();
        .Link();
        position: absolute;
        bottom: 0;
        right: 18px;
      }

      &-Extra {
        position: absolute;
        bottom: -24px;
        left: 0;
        margin-bottom: 0;
        color: @dark80;
      }
    }

    button {
      align-self: start;
    }
  }

  &-View {
    .ColumnDirectionWithGap(48px);
    min-height: 50vh;

    .Content {
      display: flex;
      border: 1px solid @dark60;
      border-radius: 16px;
      overflow: hidden;
    }

    .Setup {
      .ColumnDirectionWithGap(16px);
    }

    .RequestedStep {
      display: flex;
      align-items: flex-end;
      gap: 136px;
      padding: 0 40px 32px;

      & > div:first-child {
        .ColumnDirectionWithGap(8px);

        div {
          display: flex;
        }
      }

      .ant-input-number {
        width: 240px;
      }
    }
  }

  &-Overview {
    .ColumnDirectionWithGap(8px);
    width: 45%;
    padding: 32px 40px;

    div {
      display: flex;
    }

    p {
      color: @blue50;
    }

    .ButtonPairWrapper {
      margin-top: 8px;
    }
  }

  &-Modal {
    .ModalWrapper();

    p {
      max-width: 352px;
      text-align: center;
      color: @dark80;
    }

    form {
      .ColumnDirectionWithGap(16px);
      width: 100%;

      p {
        max-width: fit-content;
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .ButtonPairWrapper {
        justify-content: center;
      }
    }
  }

  &-Step {
    .ColumnDirectionWithGap(8px);
    width: 55%;
    padding: 32px 72px;
    background-color: @dark80;

    h4,
    p,
    div,
    label {
      color: @white100;
    }

    h4 {
      .Bold();
    }

    .ant-spin-container {
      .ColumnDirectionWithGap(16px);
    }

    button {
      width: fit-content;
    }

    .ant-form {
      margin-top: 24px;

      input.ant-input {
        border-color: @dark90;
        background-color: @dark90;
        color: @white100;
      }

      .ant-form-item-extra {
        color: @dark60;
      }
    }

    .Reference {
      margin-top: 24px;

      & > div {
        display: flex;
        align-items: center;
        gap: 8px;
        color: @dark50;

        .icon-info-circle:before {
          color: @dark50;
        }
      }

      i {
        cursor: pointer;
      }
    }
  }

  .Processing {
    .ColumnDirectionWithGap(16px);
    align-items: center;

    h4,
    p {
      text-align: center;
    }
  }

  .ant-form-item {
    .ant-form-item-explain-error {
      margin-bottom: 8px;
    }
  }

  .ant-collapse {
    margin-bottom: 36px;
  }

  &-TotalInvestments {
    .TableWithTitle();
    padding-bottom: 40px;
  }
}

.Funding-Popover {
  max-width: 450px;

  p {
    .ColumnDirectionWithGap(8px);
  }

  a {
    display: flex;
    align-items: center;
    gap: 4px;
    color: @turquoise50;
  }
}
