.Wallet {
  section {
    .ColumnDirectionWithGap(8px);
  }

  p {
    span {
      display: inline-block;
      margin-left: 4px;
    }
  }

  & > .ant-row {
    flex-direction: column;
  }

  &-Form {
    margin: 8px 0 56px;

    && .ant-form-item {
      margin-bottom: 16px;
      .AddressResolverWithLabel();
    }

    .ant-form-item-explain-error {
      margin-bottom: 8px;
    }
  }
}
