.Card {
  .ColumnDirectionWithGap(16px);
  align-items: center;
  width: 320px;
  padding: 30px;
  text-align: center;
  border: 1px solid @dark80;
  border-radius: 24px;

  h4 {
    .Bold();
  }
}
