.AddressResolverInput {
  position: relative;

  label {
    display: block;
    margin-bottom: 16px;
  }

  && .ant-select-selector input.ant-select-selection-search-input {
    padding-left: 2px;
    box-shadow: none;
  }

  &-Name {
    position: absolute;
    bottom: 9px;
    right: 32px;
    padding-left: 10px;
    background-color: @turquoise20;
    pointer-events: none;
    cursor: default;
    animation: opacity @durationLong ease @durationShort backwards;
  }
}

.AddressResolverWithLabel {
  &:hover,
  &:focus-within {
    .ant-select .ant-select-selector {
      border-color: @turquoise50 !important; // "!important" is in the tinyColor, so it needs to be overridden.
    }
  }
}
