@font-face {
  font-family: "icon-font";
  src: url("/assets/fonts/icon-font.eot?jfv785");
  src: url("/assets/fonts/icon-font.eot?jfv785#iefix") format("embedded-opentype"),
    url("/assets/fonts/icon-font.ttf?jfv785") format("truetype"),
    url("/assets/fonts/icon-font.woff?jfv785") format("woff"),
    url("/assets/fonts/icon-font.svg?jfv785#icon-font") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-font" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: inherit;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down-tail:before {
  content: "\e900";
  font-size: 20px;
}
.icon-arrow-down:before {
  content: "\e901";
  font-size: 24px;
  color: @turquoise50;
  transition: color @durationAverage ease-in-out;
}
.icon-arrow-left-tail:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-right-tail:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-arrow-up-from-bracket:before {
  content: "\e906";
}
.icon-arrow-up-tail:before {
  content: "\e907";
}
.icon-arrow-up:before {
  content: "\e908";
}
.icon-arrows:before {
  content: "\e909";
}
.icon-attachment-doc:before {
  content: "\e90a";
}
.icon-coins:before {
  content: "\e90b";
}
.icon-comment-feedback:before {
  content: "\e90c";
}
.icon-cross:before {
  content: "\e90d";
}
.icon-eye:before {
  content: "\e90e";
}
.icon-facebook {
  .Transition(color);

  &:before {
    content: "\e90f";
    font-size: 24px;
  }

  &:hover,
  &:focus-visible,
  &:active {
    color: #0165e1;
  }
}
.icon-hand-ko:before {
  content: "\e910";
  color: @red50;
}
.icon-hand-ok:before {
  content: "\e911";
  color: @green80;
}
.icon-hand-pointing:before {
  content: "\e912";
}
.icon-hand:before {
  content: "\e913";
  color: @dark80;
}
.icon-info-circle:before {
  content: "\e914";
  font-size: 24px;
  color: @blue50;
}
.icon-instagram:before {
  content: "\e915";
  font-size: 24px;
}
.icon-link:before {
  content: "\e916";
  font-size: 24px;
}
.icon-linkedin {
  .Transition(color);

  &:before {
    content: "\e917";
    font-size: 24px;
  }

  &:hover,
  &:focus-visible,
  &:active {
    color: #0a66c2;
  }
}
.icon-lock:before {
  content: "\e918";
  color: @dark80;
}
.icon-mattermost:before {
  content: "\e919";
}
.icon-metamask .path1:before {
  content: "\e91a";
  color: rgb(226, 118, 27);
}
.icon-metamask .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(228, 118, 27);
}
.icon-metamask .path3:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(228, 118, 27);
}
.icon-metamask .path4:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(228, 118, 27);
}
.icon-metamask .path5:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(228, 118, 27);
}
.icon-metamask .path6:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(228, 118, 27);
}
.icon-metamask .path7:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(228, 118, 27);
}
.icon-metamask .path8:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(228, 118, 27);
}
.icon-metamask .path9:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(215, 193, 179);
}
.icon-metamask .path10:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(215, 193, 179);
}
.icon-metamask .path11:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(35, 52, 71);
}
.icon-metamask .path12:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(35, 52, 71);
}
.icon-metamask .path13:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(205, 97, 22);
}
.icon-metamask .path14:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(205, 97, 22);
}
.icon-metamask .path15:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(205, 97, 22);
}
.icon-metamask .path16:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(205, 97, 22);
}
.icon-metamask .path17:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(228, 117, 31);
}
.icon-metamask .path18:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(228, 117, 31);
}
.icon-metamask .path19:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(228, 117, 31);
}
.icon-metamask .path20:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(228, 117, 31);
}
.icon-metamask .path21:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(246, 133, 27);
}
.icon-metamask .path22:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(246, 133, 27);
}
.icon-metamask .path23:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(192, 173, 158);
}
.icon-metamask .path24:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(22, 22, 22);
}
.icon-metamask .path25:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(118, 61, 22);
}
.icon-metamask .path26:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(118, 61, 22);
}
.icon-metamask .path27:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(246, 133, 27);
}
.icon-metamask .path28:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(246, 133, 27);
}
.icon-metamask .path29:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(246, 133, 27);
}
.icon-minus:before {
  content: "\e937";
}
.icon-new-window:before {
  content: "\e938";
  font-size: 20px;
}
.icon-plus:before {
  content: "\e939";
}
.icon-points:before {
  content: "\e93a";
}
.icon-reply-left:before {
  content: "\e93b";
}
.icon-reply:before {
  content: "\e93c";
  font-size: 16px;
}
.icon-search:before {
  content: "\e93d";
}
.icon-settings-ok:before {
  content: "\e93e";
}
.icon-sign:before {
  content: "\e93f";
  font-size: 20px;
}
.icon-slack:before {
  content: "\e940";
  font-size: 18px;
}
.icon-star-filled:before {
  content: "\e941";
}
.icon-star:before {
  content: "\e942";
}
.icon-telegram {
  .Transition(color);

  &:before {
    content: "\e943";
    font-size: 24px;
  }

  &:hover,
  &:focus-visible,
  &:active {
    color: #27a7e7;
  }
}
.icon-tick:before {
  content: "\e944";
}
.icon-trash:before {
  content: "\e945";
  font-size: 24px;
}
.icon-twitter-x {
  .Transition(color);

  &:before {
    content: "\e946";
    font-size: 24px;
  }

  &:hover,
  &:focus-visible,
  &:active {
    color: #1d9bf0;
  }
}
.icon-twitter {
  .Transition(color);

  &:before {
    content: "\e947";
    font-size: 24px;
  }

  &:hover,
  &:focus-visible,
  &:active {
    color: #1d9bf0;
  }
}
.icon-user-role-expert .path1:before {
  content: "\e948";
  color: rgb(255, 255, 255);
}
.icon-user-role-expert .path2:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(59, 115, 185);
}
.icon-user-role-expert .path3:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(59, 115, 185);
}
.icon-user-role-expert .path4:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(59, 115, 185);
}
.icon-user .path1:before {
  content: "\e94c";
  color: rgb(255, 255, 255);
}
.icon-user .path2:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(59, 115, 185);
}
.icon-user .path3:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(59, 115, 185);
}
.icon-whatsapp:before {
  content: "\e94f";
}
.icon-copy:before {
  content: "\e950";
  font-size: 22px;
}
