.ant-layout {
  background: none;
}

.ant-btn {
  .Button();
  box-shadow: none;
}

a.ant-btn {
  display: inline-block;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  content: "*";
  display: inline-block;
  margin-right: 4px;
  color: @red50;
}

// Since we can't inject custom buttons to close antd modals, this style mimics the .IconButton style
button.ant-modal-close {
  &:hover,
  &:focus,
  &:active {
    border: none;
    background-color: transparent;
  }

  &:focus-visible .ant-modal-close-icon {
    outline: 1px solid @turquoise90;
  }

  &[ant-click-animating-without-extra-node]:after {
    all: unset;
  }
}

.ant-pagination {
  align-items: center;

  .ant-pagination-item,
  .ant-pagination-item-link,
  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    margin-right: 0px;

    a {
      color: @dark100;
    }

    svg {
      width: 19px;
      height: 19px;
    }

    &:hover .ant-pagination-item-link,
    &:focus-visible {
      color: @turquoise90;
    }

    &:active {
      color: @dark100;
    }
  }

  .ant-pagination-item-active a {
    color: @turquoise90;
  }

  .ant-pagination-disabled {
    .ant-pagination-item-link {
      color: @dark60;

      &:hover,
      &:focus-visible,
      &:active {
        border: none;
        background-color: transparent;
        color: @dark60;
      }
    }
  }

  .ant-pagination-options {
    display: flex;
    align-items: center;

    .ant-select-selector,
    .ant-pagination-options-quick-jumper input {
      align-items: center;
      height: 38px;
      border-radius: 24px;

      &:focus {
        box-shadow: none;
      }
    }

    .ant-pagination-options-quick-jumper {
      height: 38px;
    }
  }
}

.ant-modal-content {
  border-radius: 16px;
}

.ant-checkbox-wrapper {
  align-items: center;

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border-color: @dark100;

    &::after {
      top: 6px;
      width: 14px;
      height: 8px;
      border-radius: 1px;
      border-left: 4px solid @white100;
      border-bottom: 4px solid @white100;
      border-right: 0;
      transform: rotate(-45deg);
    }
  }

  .ant-checkbox {
    top: 0;
  }

  span {
    user-select: none;
    .Transition(all);
  }

  &:not(&-disabled) {
    &:focus-visible,
    &:hover {
      span {
        color: @turquoise50;
      }

      &:after {
        border-color: @turquoise50;
      }
    }
  }

  .ant-checkbox-checked {
    &:after {
      border-radius: 4px;
    }
    .ant-checkbox-inner {
      border: 1px solid @turquoise50;
      background-color: @turquoise50;
    }
  }
}

.ant-form-item {
  .FieldsetInput();

  .ant-form-item-explain-error {
    .ErrorMessage();
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
    box-shadow: none;
    border-color: @turquoise50;
  }

  && .ant-input-affix-wrapper,
  && .ant-form-item-control-input-content {
    padding: 0;
    border: none;
    border-radius: 24px;

    &:focus,
    &.ant-input-affix-wrapper-status-error,
    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
      z-index: 0;
    }

    input {
      padding: 7px 13px;
      border: 1px solid @turquoise20;

      &:focus,
      &:hover {
        &:not(:disabled) {
          border-color: @turquoise50;
        }
      }
    }

    &.ant-input-affix-wrapper-status-error input {
      border-color: @red50;
    }

    .ant-input-suffix {
      position: absolute;
      right: 16px;
      bottom: 8px;
    }

    .ant-input-suffix,
    .ant-input-show-count-suffix {
      color: @dark80;
      .Bold();
    }
  }

  .ant-form-item-extra {
    color: @dark80;
  }

  .ant-select {
    .ant-select-selector {
      .Transition(all);
    }
  }

  .ant-form-item-label {
    padding: 0;

    label {
      padding-bottom: 8px;
    }
  }
}

.ant-form-horizontal {
  .ant-form-item-row {
    align-items: center;

    label {
      height: auto;
      margin-bottom: 0;
    }
  }
}

.ant-switch {
  .Button-Secondary();
  .Transparent();
  width: 85px;
  height: 38px;
  padding: 0;
  overflow: hidden;
  .Transition(all);

  &,
  &:focus {
    border-color: @dark80;
    box-shadow: none;
  }

  .ant-switch-handle {
    color: @dark80;

    &:before {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: @dark80;
    }

    &:after {
      content: "Off";
      position: absolute;
      top: 5px;
      left: 48px;
    }
  }

  &.ant-switch-checked {
    border-color: @turquoise50;

    .ant-switch-handle {
      left: calc(100% - 32px - 2px);

      &:before {
        background-color: @turquoise50;
      }
      &:after {
        content: "On";
        left: -36px;
        color: @turquoise50;
      }
    }
  }

  &:focus-visible,
  &:hover {
    border-color: @dark90;
    .ant-switch-handle:before {
      background-color: @dark90;
    }
  }
}

.ant-input-number {
  min-width: 160px;
  border: none;

  &:focus,
  &.ant-input-number-status-error:not(.ant-input-number-disabled):not(
      .ant-input-number-borderless
    ).ant-input-number-focused,
  &.ant-input-number-focused {
    box-shadow: none;

    .ant-input-number-handler-down {
      border-color: @turquoise50;
    }
  }

  .ant-input-number-handler-wrap {
    top: 1px;
    right: 1px;
    width: 40px;
    height: 36px;
    border-radius: 0 24px 24px 0;
  }

  .ant-input-number-handler,
  .ant-input-number-handler-down {
    border-color: @turquoise50;
  }

  .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-inner {
    right: 15px;
  }
}

.ant-input-disabled[disabled] {
  padding: 7px 13px;
  border-radius: 24px;
  appearance: none;
  background-color: @dark40;
}

.ant-modal-close {
  top: 12px;

  svg {
    width: 24px;
    height: 24px;
    .Transition(fill);

    &,
    &:focus {
      fill: @turquoise50;
    }

    &:hover,
    &:focus-visible {
      fill: @turquoise100;
    }

    &:active {
      fill: @dark100;
    }
  }
}

.ant-modal-body {
  padding: 40px;
}

.ant-collapse {
  && > .ant-collapse-item > .ant-collapse-header {
    .Link();
    padding: 0;
  }
}

.ant-dropdown-menu {
  padding: 8px 0;
  border-radius: 8px;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 6px 16px;
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
    background-color: @turquoise20;
  }
}

.ant-breadcrumb {
  span {
    color: @dark80;
  }

  .icon-arrow-left-tail {
    .Bold();
    position: relative;
    top: 1px;
    margin-right: 4px;
    font-size: 16px;
    color: inherit;
  }

  .ant-breadcrumb-separator {
    color: @turquoise90;
  }

  && .ant-breadcrumb-link {
    a {
      .Link();
      font-weight: normal;
    }
  }
}

.ant-select.ant-select-auto-complete {
  width: 100%;

  && .ant-select-selector {
    min-height: 38px;
    padding: 3px 13px;
    border: 1px solid @turquoise20;
    border-radius: 24px;
    background-color: @turquoise20;

    &:focus,
    &:hover {
      &:not(:disabled) {
        border-color: @turquoise50;
      }
    }
    &:focus {
      box-shadow: none;
    }

    .ant-select-selection-search,
    input.ant-select-selection-search-input {
      min-height: 36px;
    }
  }

  .anticon-close-circle svg {
    background-color: @turquoise20;
    fill: @turquoise100;
  }

  &.ant-select-status-error {
    && .ant-select-selector {
      box-shadow: none;
    }
    .anticon-close-circle svg {
      background-color: @white100;
    }
  }
}

.ant-select-dropdown {
  padding: 8px 0;
  border-radius: 8px;
  z-index: 1;

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: @white100;
  }

  .ant-select-item-option.ant-select-item-option-active {
    background-color: @white100;
  }

  .ant-select-item-option:focus,
  .ant-select-item-option:hover {
    background-color: @turquoise20;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: 1px solid @turquoise50;
}

.ant-popover {
  .ant-popover-arrow {
    bottom: -7px;
    width: 14px;
    height: 14px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    background-color: @white100;
    transform: rotate(45deg);

    .ant-popover-arrow-content {
      display: none;
    }
  }

  .ant-popover-inner {
    padding: 6px 12px;
    border-radius: 0;
    border: 1px solid @dark100;
    word-wrap: anywhere;
  }

  .ant-popover-inner-content {
    padding: 0;
    color: @dark80;
  }

  &.ant-popover-placement-topLeft .ant-popover-arrow {
    left: 8px;
  }
}

// Removing antd clicking button animation.
button[ant-click-animating-without-extra-node]:after {
  border: 0 none;
  opacity: 0;
  animation: none 0 ease 0 1 normal;
}

.ant-radio-group {
  display: flex;

  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .ant-radio {
      top: 0;
      height: 24px;

      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: @turquoise50;

          &:after {
            top: 4px;
            left: 4px;
            transform: none;
            margin: 0;
          }
        }
      }
    }

    .ant-radio-inner {
      width: 24px;
      height: 24px;
      padding-left: 0;
      background-color: transparent;
      border-color: @dark100;

      &:before {
        display: none;
      }
    }
  }
}

.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  position: absolute;
  bottom: 8px;
  right: 12px;
  margin-bottom: 0;
}
