.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  &-Label {
    color: @white100;
  }

  &.WhiteLayout {
    position: relative;
    min-height: 40vh;
    border-radius: 16px;
    background-color: #fff;
    color: @dark100;
    z-index: 1;

    .Loader-Label {
      color: @dark100;
    }
  }
}
